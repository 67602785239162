export const internalTitleConfig = {
  id: 'internalTitle',
  type: 'TEXT',
  placeholder: 'Add internal title',
  label: 'Internal Title',
  prefix: '',
  postfix: '',
  value: '',
  validation: { required: true },
  errorMsg: 'Internal title is required',
}
export const newQuestionnaireConfig = [
  {
    id: 'name',
    type: 'TEXT_TRANSLATION',
    placeholder: 'Add title',
    label: 'Title',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Name is required',
  },

  // {
  //   id: 'internalTitle',
  //   type: 'TEXT',
  //   placeholder: 'Add internal title',
  //   label: 'Internal Title',
  //   prefix: '',
  //   postfix: '',
  //   value: '',
  //   validation: { required: true },
  //   errorMsg: 'Internal title is required',
  // },

  /*{

  // {
  //   id: 'name',
  //   type: 'TEXT',
  //   placeholder: 'Add title',
  //   label: 'Title',
  //   prefix: '',
  //   postfix: '',
  //   value: '',
  //   validation: { required: true },
  //   errorMsg: 'Name is required',
  // },
  {
>>>>>>> main
    id: 'type',
    type: 'DROPDOWN',
    placeholder: 'Select a type',
    label: 'State type',
    prefix: '',
    postfix: '',
    value: '',
    options: [
      { value: QUESTIONNAIRE_TYPE_ENUM.PHASE, label: 'Phase' },
      { value: QUESTIONNAIRE_TYPE_ENUM.VISIT, label: 'Visit' },
      { value: QUESTIONNAIRE_TYPE_ENUM.DRAFT, label: 'Draft' },
    ],
    validation: { required: true },
    errorMsg: 'A type must be selected',
    disabled: true,
  },*/
  {
    id: 'onboarding',
    type: 'SWITCH',
    placeholder: '',
    label: 'Is onboarding',
    prefix: '',
    postfix: '',
    value: false,
    validation: { required: false },
    errorMsg: 'Onboarding flag is required',
  },
] as const

export const questionnaireNotificationsConfig = [
  {
    id: 'notifications.reminders.start',
    type: 'TEXT_TRANSLATION',
    placeholder: 'Notification text',
    label: 'Reminder on activation',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Reminder text is required',
  },
  {
    id: 'notifications.reminders.end',
    type: 'TEXT_TRANSLATION',
    placeholder: 'Notification text',
    label: 'End reminder',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Reminder text is required',
  },
]
