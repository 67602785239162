import { PHASE_TYPE_ENUM } from '../../Types'
import { BODY_PARTS, BODY_PARTS_PHASE } from '../../constants'

export const getPhaseConfig = () => {
  return phaseConfig.concat([])
}

const phaseConfig = [
  {
    id: 'title',
    type: 'TEXT',
    placeholder: 'Add title',
    label: 'Title',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Title is required',
    disabled: true,
  },
  {
    id: 'duration',
    type: 'NUMBER',
    placeholder: 'Add duration',
    label: 'Duration in days',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'duration is required',
  },
  {
    id: 'type',
    type: 'DROPDOWN',
    placeholder: 'Select a type',
    label: 'type',
    prefix: '',
    postfix: '',
    value: '',
    options: [
      { value: PHASE_TYPE_ENUM.IN_SCREENING, label: 'In screening' },
      { value: PHASE_TYPE_ENUM.IN_STUDY, label: 'In study' },
    ],
    validation: { required: true },
    errorMsg: 'A type must be selected',
    disabled: true,
  },
  {
    id: 'bodyParts',
    type: 'SELECT_MULTIPLE',
    placeholder: 'Select body parts',
    label: 'Body parts',
    prefix: '',
    postfix: '',
    value: '',
    className: 'col-12',
    validation: { required: false },
    options: BODY_PARTS_PHASE.map((value): { label: string; value: string } => ({
      value: value,
      label: BODY_PARTS[value],
    })),
  },
  {
    id: 'shortCompliance',
    type: 'NUMBER',
    placeholder: 'Add short compliance trigger',
    label: 'Short compliance in days',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Compliance is required',
  },
  {
    id: 'overallCompliance',
    type: 'NUMBER',
    placeholder: 'Add overall compliance trigger',
    label: 'Overall compliance in %',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Compliance is required',
  },
] as const
