import { Button } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import Alert from 'antd/lib/alert'
import { CRUD_VIEW_TYPE, SiteType, VIEW_TYPE } from '../../Types'
import { ProjectDrawer } from '../epro-project/ProjectDrawer'
import { post } from '../../service/API'
import { SideBarAddSites } from './SideBarAddSites'
import { SiteBarSiteList } from './SideBarSiteList'

import { ENDPOINTS } from '../../service/ENDPOINTS'
import { JustificationInput } from '../../components/form/JustificationInput'
import { openNotification } from '../../components/notifications/openNotification'
import { useClinicianById } from '../../hooks/useClinicianById'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

export const ClinicianSiteCRUD = ({ onClose, closeDrawer, viewType }: Props) => {
  const { projectId, userId } = useParams() as { projectId: string; userId: string }

  const [isDirty, setIsDirty] = useState<boolean>(false)
  const { data, mutate } = useClinicianById(projectId, userId)

  const formHook = useForm()

  const { handleSubmit } = formHook
  const [errorState, setErrorState] = useState<any>()
  const [showAddSiteDrawer, setAddSiteDrawer] = useState<boolean>(false)

  const initSites = useMemo(() => {
    const defaultSiteArr = data?.sites || []
    return viewType === CRUD_VIEW_TYPE.CREATE ? [] : defaultSiteArr
  }, [viewType, data?.sites])

  const [selectedSites, setSelectedSites] = useState<SiteType[]>(initSites)

  useEffect(() => {
    const initSites = data?.sites || []
    const len = initSites.filter((site, index) => selectedSites[index]?._id === site._id).length

    // it is dirty if the length of the selected sites is different from the initial sites
    // if the length is the same, we need to check if the selected sites are the same as the initial sites
    const isDirty = len !== initSites.length || selectedSites.length !== initSites.length
    setIsDirty(isDirty)
  }, [selectedSites, data?.sites])

  const onSubmit = (formData, e) => {
    e.preventDefault()
    const method = viewType === CRUD_VIEW_TYPE.UPDATE ? 'PUT' : 'POST'
    const justification = formData.justification

    const data = {
      sites: selectedSites,
      projectId: projectId,
      staffId: userId,
      justification: justification,
    }

    return post(ENDPOINTS.CLINICIANS.ACTION.updateSites(projectId), data, method)
      .then(() => {
        const title = 'Updated access'
        const msg = 'Clinician site access has been successfully updated'
        openNotification(title, msg, 'happy')
        mutate()

        if (onClose) {
          onClose()
        }
        if (closeDrawer) {
          closeDrawer(false)
          setAddSiteDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const updateSelectedSites = (theSites) => {
    setSelectedSites(theSites)
  }

  const removeSelectedSite = (id) => {
    const updatedSites: SiteType[] = selectedSites.filter((item) => {
      return item._id !== id
    })

    setSelectedSites(updatedSites)
  }

  const open = () => {
    setAddSiteDrawer(true)
  }

  const closeAddSiteDrawer = () => {
    setAddSiteDrawer(false)
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  return (
    <>
      <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="col-12 mb-4">
          <p className="mb-1 opacity-75">Selected clinician:</p>
          <h5 className="fw-bold text-truncate">{data ? data.email : '-'}</h5>
          <p>
            Select your preferred sites to provide access to the chosen site or sites. You can ofcourse revoke access at
            anytime.
          </p>
        </div>

        <div className="col-12 ">
          <SiteBarSiteList
            open={open}
            selectedSites={selectedSites}
            removeSelectedSite={removeSelectedSite}
            viewType={CRUD_VIEW_TYPE.CREATE}
            headerText="Click the Add site button to select a site for the user."
          ></SiteBarSiteList>
        </div>
        {isDirty ? (
          <div className="col-12 mt-3 ">
            <JustificationInput formHook={formHook} />
          </div>
        ) : null}

        <div className="col-12 mt-3 mb-4">
          {errorState ? (
            <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
          ) : (
            <></>
          )}
        </div>

        <div className="col-12 d-flex justify-content-end">
          <Button type="primary" disabled={!isDirty} onClick={handleSubmit(onSubmit, onError)}>
            Save changes
          </Button>
        </div>
      </form>

      {showAddSiteDrawer ? (
        <ProjectDrawer setVisible={closeAddSiteDrawer} projectId={''} width={550} to="." title="Site list">
          <SideBarAddSites projectId={projectId} setSelectedSites={updateSelectedSites} selectedSites={selectedSites} />
        </ProjectDrawer>
      ) : (
        <></>
      )}
    </>
  )
}
