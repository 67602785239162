import { useSite } from '../../hooks/useSite'
import { Button } from 'antd'
import React, { Dispatch, useState } from 'react'
import { SiteType } from '../../Types'
import { SideBarSiteListItem } from './SideBarSiteListItem'

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 27/03/2023.
 */
type AddSitesProps = {
  projectId: string
  selectedSites: SiteType[]
  setSelectedSites: Dispatch<any>
  onClose?: () => void // passed inside DrawerContainer
}

export const SideBarAddSites = ({ projectId, selectedSites, setSelectedSites, onClose }: AddSitesProps) => {
  /**
   *
   * using a useMeSites - seems confusing - since who is the "me" - is it the clinician
   * or is it the CTM.
   *
   * should be clear that we are getting the availble sites for this projects
   *
   */
  const { data } = useSite(projectId)

  //
  // on init - convert the array of sites to array of id's
  //
  const [localSitesIds, setLocalSiteId] = useState<any>(selectedSites.map((site) => site._id))

  const selectSiteClick = (siteId: string) => {
    const exist = localSitesIds.indexOf(siteId)

    if (exist === -1) {
      setLocalSiteId(localSitesIds.concat([siteId]))
    } else {
      const filtred = localSitesIds.filter((item) => item !== siteId)
      setLocalSiteId(filtred)
    }
  }

  const saveSelected = () => {
    const selected = data.filter((site) => {
      return localSitesIds.indexOf(site._id) > -1
    })

    setSelectedSites(selected)

    if (onClose) {
      onClose()
    }
  }

  const cancelSelected = () => {
    if (onClose) {
      onClose()
    }
  }
  console.log('SideBarAddSites > data = ', data)
  return (
    <div className="container-fluid p-0">
      <div className="row rounded-2" style={{ background: '#f3f3f3' }}>
        <div className="col-12 py-4 mb-2">
          <p className="fw-bold mb-2">Clinician access </p>
          <p>Please select the site the user needs access to from the list below.</p>
        </div>
      </div>
      <div className="row mt-2">
        {data.map((site, index) => {
          const isChecked = localSitesIds.indexOf(site._id) > -1 ? true : false

          return (
            <SideBarSiteListItem
              key={site._id}
              site={site}
              selectSiteClick={selectSiteClick}
              isChecked={isChecked}
            ></SideBarSiteListItem>
          )
        })}
      </div>
      <div className="row" style={{}}>
        <div className="col-12 d-flex px-0  mt-3 justify-content-end">
          <Button type="text" className="me-2" onClick={cancelSelected}>
            Cancel
          </Button>
          <Button type="primary" disabled={data.length === 0} onClick={saveSelected}>
            Save selection
          </Button>
        </div>
      </div>
    </div>
  )
}
