/**
 * Created by @author Søren Tramm on 10/08/2023.
 */
import { Link, useParams } from 'react-router-dom'
import { QuestionListItem } from './QuestionListItem'
import { ENDPOINTS } from '../../../service/ENDPOINTS'
import { Alert, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { reorder, SaveDialog } from '../../../components/drag/DraggableQuestionUtil'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { useByEndpoint } from '../../../hooks/useByEndpoint'
import { useProject } from '../../../hooks/useProject'

type UpdateState = {
  updated: any[]
  current: any[]
}

type Props = {
  closeDrawer: (value) => void
}

export const QuestionManageDrawer = ({ closeDrawer }: Props) => {
  const { projectId, questionnaireId } = useParams() as { projectId: string; questionnaireId: string }
  const p = questionnaireId ? ENDPOINTS.QUESTIONS.byQuestionnaireId(projectId, questionnaireId) : ''
  const { data, mutate } = useByEndpoint(p)

  const { data: projectData } = useProject(projectId || '')
  const defaultLanguage = projectData.languages[0].isoCode

  useEffect(() => {
    setItems(data)
  }, [data])

  const [items, setItems] = useState<any>(data)
  const [saveUpdate, setSaveUpdate] = useState<UpdateState | null>(null)
  const [triggerSubmit, setTriggerSubmit] = useState<any>(false)
  const [saveDisabled, setSaveDisabled] = useState<any>(true)
  const [errorState, setErrorState] = useState<any>()

  const triggerUpdate = () => {
    setTriggerSubmit(true)
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const current = [].concat(items)
    const newItems: any = reorder(items, result.source.index, result.destination.index)

    setItems(newItems)
    setSaveUpdate({ updated: newItems, current: current })

    setSaveDisabled(false)
  }

  const onSave = (items) => {
    setItems(items)
    setSaveUpdate(null)
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mb-2">
          <p className="p-mini opacity-50">ID: {questionnaireId}</p>
        </div>

        <div className="col-12 px-2 mb-2">
          <Link to="./create">
            <Button type="default">Create Question</Button>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12 px-1 mb-2">
          <hr className=""></hr>
        </div>
      </div>

      <div className="row">
        <div className="col-12 px-2 mb-2 px-3">
          {saveUpdate?.current && saveUpdate.updated && triggerSubmit ? (
            <SaveDialog
              current={saveUpdate.current}
              updated={saveUpdate.updated}
              onSave={onSave}
              method={'PUT'}
              mutate={mutate}
              setTriggerSubmit={setTriggerSubmit}
              setSaveDisabled={setSaveDisabled}
              setErrorState={setErrorState}
            ></SaveDialog>
          ) : null}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={
                    {
                      /*background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey'*/
                    }
                  }
                >
                  {data &&
                    items.map((question, index) => {
                      return (
                        <Draggable key={question._id} draggableId={question._id} index={index}>
                          {(provided, snapshot) => {
                            if (snapshot.isDragging) {
                              const { offsetTop, offsetLeft } = provided.draggableProps!.style as {
                                offsetTop: string
                                offsetLeft: string
                              }
                              //@ts-ignore
                              provided.draggableProps.style!.left! = offsetLeft
                              //@ts-ignore
                              provided.draggableProps.style!.top! = offsetTop
                            }

                            return (
                              <div className="col-12 px-2 mb-3" ref={provided.innerRef} {...provided.draggableProps}>
                                <QuestionListItem
                                  key={index}
                                  item={question}
                                  provided={provided}
                                  mutate={mutate}
                                  defaultLanguage={defaultLanguage}
                                />
                              </div>
                            )
                          }}
                        </Draggable>
                      )
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="col-12 mt-1">
          <div className="row ">
            <div className="col-12 d-flex justify-content-center">
              <p className="p-small alpha-80">
                When saving the question order you also connect the next question as shown in the order
              </p>
            </div>
            <div className="col-12 d-flex justify-content-center mt-2">
              <Button type="primary" onClick={triggerUpdate} disabled={saveDisabled}>
                Save current question order
              </Button>
            </div>
          </div>
          <div className="col-12 mt-4">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
