/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/04/2022.
 */

import { InputNumber } from 'antd'
import { useEffect } from 'react'
import { FormLabel } from './FormLabel'
import { UseFormReturn } from 'react-hook-form'
import { NumberFormConfigObject } from '../../Types'

type Props = { formHook: UseFormReturn; data: NumberFormConfigObject }

export const FormNumber = ({ data, formHook }: Props) => {
  const {
    register,
    setValue,
    unregister,
    getValues,
    formState: { errors },
  } = formHook

  const errorState = errors[data.id]
  const errorMessage = errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)

  useEffect(() => {
    register(data.id, data.validation)
  }, [register, data.id, data.validation])

  useEffect(() => {
    return () => {
      unregister(data.id)
    }
  }, [unregister, data.id])

  useEffect(() => {
    if (data.value) {
      setValue(data.id, data.value)
    }
  }, [setValue, data.id, data.value])

  const onChange = (value: number | null) => {
    if (value !== null) {
      setValue(data.id, value)
    }
  }

  const currentValue = getValues(data.id) ? getValues(data.id) : data.value

  return (
    <div className="mt-1 ">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>
      <InputNumber
        className="w-100"
        placeholder={data.placeholder}
        min={data.min !== null ? (data.min as number) : 1}
        max={data.max !== null ? (data.max as number) : 10}
        defaultValue={currentValue}
        status={errorState ? 'error' : ''}
        onChange={onChange}
      />
      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
