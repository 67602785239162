import React, { Suspense } from 'react'
import { Navigate, NavLink, Route, Routes, useParams } from 'react-router-dom'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'

import { ProtocolArchivePage } from './ProtocolArchivePage'
import { ProtocolActivePage } from './ProtocolActivePage'
import { ProtocolDraftPage } from './ProtocolDraftPage'
import { useActiveDraftProtocols } from '../../hooks/useProjectProtocols'

export const ProtocolWrapper = () => {
  const { projectId } = useParams() as { projectId: string }
  const { data } = useActiveDraftProtocols(projectId)

  const active = data.active
  const draft = data.draft

  const draftRoutePath = `./${draft?._id}/draft`
  const activeRoutePath = `./${active?._id}/active`

  const tabs = [
    { title: 'Active', to: active?._id || null, postfix: '/active' },
    { title: 'Draft', to: draft?._id || null, postfix: '/draft' },
    { title: 'Archive', to: `./archive`, postfix: '' },
  ]

  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-between ">
        <div className="d-flex ">
          {tabs.map((item, index) => {
            const to = './' + item.to + item.postfix
            return item.to ? (
              <NavLink
                to={to}
                key={index}
                className="text-decoration-none px-3 me-4 py-3 mt-2"
                style={({ isActive }) => {
                  return { opacity: isActive ? 1 : 0.8, borderBottom: isActive ? '2px solid rgba(0,0,0,.5)' : 'none' }
                }}
              >
                <div className="position-relative">
                  {index === 1 ? (
                    <div
                      className="position-absolute rounded-circle bg-danger"
                      style={{ right: -6, width: 6, height: 6 }}
                    ></div>
                  ) : null}
                  <p>{item.title}</p>
                </div>
              </NavLink>
            ) : (
              <div
                key={index}
                className="text-decoration-none px-3 me-4 py-3 mt-2 opacity-50 text-decoration-line-through"
              >
                <p>{item.title}</p>
              </div>
            )
          })}
        </div>
        <div className="d-flex justify-content-end"></div>
      </div>

      <div
        className="col-12 position-relative "
        style={{ background: '#d3d3d3', height: 1, marginTop: -1, zIndex: -1 }}
      ></div>

      <Routes>
        <Route
          path={':protocolId/active/*'}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              {active ? <ProtocolActivePage draftExist={draft?._id ? true : false} /> : null}
            </Suspense>
          }
        />
        <Route
          path={':protocolId/draft/*'}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProtocolDraftPage />
            </Suspense>
          }
        />
        <Route
          path={'archive/*'}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProtocolArchivePage />
            </Suspense>
          }
        />
        {/*
          CATCH ALL - TO HANDLE IF SOMEONE NAVIGATES TO THIS PAGE withou having a protocolid
          This removes the need for a useEffect
        */}
        <Route path={'/'} element={<Navigate to={active ? activeRoutePath : draftRoutePath}></Navigate>} />
      </Routes>
    </div>
  )
}
