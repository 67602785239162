/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 27/03/2023.
 */
import React, { Suspense } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { Button } from 'antd'
import { ProjectDrawer } from '../epro-project/ProjectDrawer'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'

import { QuestionnaireCRUD } from './QuestionnaireCRUD'
import { CRUD_VIEW_TYPE } from '../../Types'
import { QuestionCRUD } from './question/QuestionCRUD'
import { QuestionManageDrawer } from './question/QuestionManageDrawer'

export const QuestionnairePage = () => {
  const { projectId } = useParams() as { projectId: string }

  const handleCreateClick = () => {}

  const closeDrawer = (value) => {
    if (!value) {
      //mutate()
    }
  }

  const deleteHeader = (
    <Link to="retire">
      <Button type="default">Change access</Button>
    </Link>
  )

  return (
    <>
      <Routes>
        <Route
          path="/create"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer projectId={projectId} setVisible={closeDrawer} to="../" title="Create Questionnaire">
                <QuestionnaireCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.CREATE}></QuestionnaireCRUD>
              </ProjectDrawer>
            </Suspense>
          }
        />

        <Route
          path="/:questionnaireId/manage-questions/*"
          element={
            <ProjectDrawer projectId={projectId} setVisible={closeDrawer} to="../" title="Manage Questions">
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <QuestionManageDrawer closeDrawer={closeDrawer}></QuestionManageDrawer>
                <Routes>
                  <Route
                    path="/update/:questionId"
                    element={
                      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                        <ProjectDrawer projectId={projectId} width="30%" to="../" title="Edit question">
                          <QuestionCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.UPDATE} />
                        </ProjectDrawer>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/create"
                    element={
                      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                        <ProjectDrawer projectId={projectId} width="30%" to="../" title="Create question">
                          <QuestionCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.CREATE} />
                        </ProjectDrawer>
                      </Suspense>
                    }
                  />
                </Routes>
              </Suspense>
            </ProjectDrawer>
          }
        />

        <Route
          path="/:questionnaireId/*"
          element={
            <ProjectDrawer
              projectId={projectId}
              setVisible={closeDrawer}
              header={deleteHeader}
              to="../"
              title="
              View Questionnaire"
            >
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <QuestionnaireCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.VIEW}></QuestionnaireCRUD>
                <Routes>
                  <Route
                    path="/edit"
                    element={
                      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                        <ProjectDrawer projectId={projectId} width="30%" to="../" title="Edit questionnaire">
                          <QuestionnaireCRUD
                            closeDrawer={closeDrawer}
                            viewType={CRUD_VIEW_TYPE.UPDATE}
                          ></QuestionnaireCRUD>
                        </ProjectDrawer>
                      </Suspense>
                    }
                  />
                </Routes>
              </Suspense>
            </ProjectDrawer>
          }
        />
      </Routes>

      <div className="row" style={{}}>
        <div className="col-12 d-flex justify-content-between mt-4">
          <p className="fw-bold mb-0">Questionnaire</p>
          <Link to={'./create'}>
            <Button type="primary" onClick={handleCreateClick}>
              Create questionnaire
            </Button>
          </Link>
        </div>

        <div className="col-12 ">
          <ListLabel label="Draft"></ListLabel>
        </div>
      </div>
    </>
  )
}

type ListLabelProps = { label: string }
const ListLabel = ({ label = 'test' }: ListLabelProps) => {
  return (
    <div className="col-12 mt-4 mb-3">
      <p className="fw-bold p-small">{label.toUpperCase()}</p>
    </div>
  )
}
