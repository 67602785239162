/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/03/2023.
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

type Props = { item: any; to?: string }

export const SiteListItem = ({ item, to = './' }: Props) => {
  const handleCreateClick = () => {}

  return (
    <>
      <div style={{ width: '30%' }}>
        <p className="p-mini">Name</p>
        <div className="d-flex">
          <p className="me-2">{item.title}</p>
        </div>
      </div>
      <div className="d-flex justify-content-between" style={{ width: '30%' }}>
        <div className="w-50">
          <p className="p-mini">Site number</p>
          {item?.siteNumber}
        </div>

        <div className="w-50">
          <p className="p-mini ">Status</p>
          <p className="">{item?.active === true ? 'Active' : 'Inactive'}</p>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <div>
          <Link to={`./${item._id}/audit-trail`}>
            <Button className="me-2">Audit trail</Button>
          </Link>
          <Link to={to}>
            <Button onClick={handleCreateClick}>View</Button>
          </Link>
        </div>
      </div>
    </>
  )
}
