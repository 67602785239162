import { Button } from 'antd'
import { useParams } from 'react-router-dom'
import { getReq } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { openNotification } from '../../components/notifications/openNotification'

type DeleteQuestionnaireProps = {
  onClose?: null | ((bool: boolean) => void)
  closeDrawer?: null | ((bool: boolean) => void)
}

export const DeleteQuestionnaire = ({ closeDrawer, onClose = null }: DeleteQuestionnaireProps) => {
  const { projectId, questionnaireId } = useParams() as { projectId: string; questionnaireId: string }

  const deleteClick = () => {
    getReq(ENDPOINTS.QUESTIONNAIRES.ACTIONS.delete(projectId, questionnaireId))
      .then((result) => {
        if (onClose) {
          onClose(false)
        }
        if (closeDrawer) {
          closeDrawer(false)
        }

        openNotification('Deletion was a success ', '', 'happy')

        return result
      })
      .catch((error) => {
        console.log('Delete questionnaire > error = ', error)
        openNotification('Deletion failed: ', error.message, 'sad')
        // return Promise.reject(error)
      })
  }

  return (
    <div className="container-fluid">
      <div className="row" style={{}}>
        <div className="col-12 mb-2">
          <h5>You are about to delete a questionnaire.</h5>
        </div>

        <Button type="primary" onClick={deleteClick} danger>
          Delete the questionnaire
        </Button>
      </div>
    </div>
  )
}
