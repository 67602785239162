/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 24/05/2023.
 */
import { SiteType, VIEW_TYPE } from '../../Types'
import { Empty, Popconfirm } from 'antd'
import { Button } from 'antd'
import { CloseCircleFilled } from '@ant-design/icons'

type SideBarSiteListProps = {
  selectedSites: SiteType[]
  open?: () => void
  removeSelectedSite?: (id: string) => void
  viewType?: VIEW_TYPE
  headerText?: string | React.ReactNode
}
export const SiteBarSiteList = ({
  selectedSites,
  open,
  removeSelectedSite,
  viewType = 'VIEW',
  headerText = '',
}: SideBarSiteListProps) => {
  return (
    <div className="border rounded-3 ">
      {selectedSites.length === 0 ? (
        <div onClick={open} className="py-5 pointer">
          <Empty description=""></Empty>
          <div className="d-flex justify-content-center mt-3">
            {open ? (
              <Button onClick={open} type="primary" className="me-2">
                Add site access
              </Button>
            ) : null}
          </div>
        </div>
      ) : (
        <>
          <div className="border-bottom py-3 d-flex justify-content-between align-items-center">
            <div className="ms-3">
              {/*  <p className="">
                Has access to sites on project <span className="fw-bold">{headerText}</span>
              </p>*/}
              {headerText ? headerText : 'Has access to sites on project'}
            </div>
            {open ? (
              <Button onClick={open} type="primary" className="me-2">
                Add site
              </Button>
            ) : null}
          </div>

          <div className="">
            {selectedSites.map((item, index) => {
              return <SiteItem key={index} site={item} remove={removeSelectedSite}></SiteItem>
            })}
          </div>
        </>
      )}
    </div>
  )
}

type SiteItemProps = { site: SiteType; remove?: (id: string) => void }
const SiteItem = ({ site, remove }: SiteItemProps) => {
  const cancel = () => {}
  const confirm = () => {
    if (remove) {
      remove(site._id)
    }
  }

  return (
    <div className="d-flex justify-content-between py-3 border-bottom">
      <div className="ps-3">
        <h6 className="mb-0">
          {site.title} - {site.siteNumber}
        </h6>
      </div>

      <div className="me-4">
        <Popconfirm
          placement="left"
          title="Remove access"
          description="Are you sure to remove access to this site?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          {remove ? <CloseCircleFilled style={{ fontSize: '1.5rem' }} /> : null}
        </Popconfirm>
      </div>
    </div>
  )
}
