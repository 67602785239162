import { useParams } from 'react-router-dom'
import React from 'react'
import { useAuditById } from '../../hooks/useAuditById'
import { Button } from 'antd'
import { genericDataTimeformat } from '../../util/datetime'
import { AUDIT_TYPES } from '../../Types'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
}

export const AuditViewDetails = ({ onClose, closeDrawer }: Props) => {
  const { projectId, auditId } = useParams() as { projectId: string; auditId: string; authorId: string }
  const { data: auditData } = useAuditById(projectId, auditId)

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      <div className="row">
        {/*Date*/}
        <div className="col-12 mt-2">
          <p className="mb-0 p-mini alpha-80 fw-bold">Date</p>
          <p className="me-2 p-small">{genericDataTimeformat(auditData.createdAt)}</p>
        </div>

        {/*Type*/}
        <div className="col-12 mt-2">
          <p className="mb-0 p-mini alpha-80 fw-bold">Action type</p>
          <p className="me-2 p-small">{auditData.auditType}</p>
        </div>

        {/*Target*/}
        <div className="col-12 mt-2">
          <p className="mb-0 p-mini alpha-80 fw-bold">Target</p>
          <p className="me-2 p-small">{auditData.targetUniqueId ? auditData.targetUniqueId : auditData.target}</p>
        </div>

        <div className="col-12">
          <hr />
        </div>
      </div>

      <div className="row">
        <AuditComponent auditData={auditData} />
        <AuditLabel label="Justification" now={auditData.reason?.description} />

        <div className="col-12 d-flex justify-content-end">
          <Button type="primary" onClick={navigateBack}>
            Close
          </Button>
        </div>
      </div>
    </>
  )
}

const AuditComponent = ({ auditData }) => {
  // console.log('auditData.auditType >>> ', auditData.auditType)
  // console.log('auditData >>> ', auditData)

  switch (auditData.auditType) {
    case AUDIT_TYPES.CREATE_SUBJECT:
      return <></>
    case AUDIT_TYPES.UPDATE_SUBJECT:
      return <AuditLabel label="Update" now={auditData?.after?.update} />
    case AUDIT_TYPES.REQUEST_SUBJECT_PII:
      return <></>
    case AUDIT_TYPES.UPDATE_CLINICIAN_SITE_ACCESS:
      return (
        <AuditFromToLabel
          label="Site access changed"
          before={auditData.before?.siteNumbers}
          now={auditData.after?.siteNumbers}
        />
      )
    case AUDIT_TYPES.UPDATE_CLINICIAN_PROJECT_ACCESS:
      return (
        <>
          <AuditFromToLabel
            label="Project acccess changed from"
            before={auditData?.before?.oldProjectAccess}
            now={auditData?.after?.newProjectAccess}
          />
        </>
      )
    case AUDIT_TYPES.ADMIN_LOGOUT:
      return (
        <AuditFromToLabel label="Status changed" before={auditData?.before?.status} now={auditData?.after?.status} />
      )
    case AUDIT_TYPES.ACTIVATE_PROTOCOL:
      return (
        <AuditFromToLabel label="Status changed" before={auditData?.before?.status} now={auditData?.after?.status} />
      )
    case AUDIT_TYPES.RETIRE_PROTOCOL:
      return (
        <AuditFromToLabel label="Status changed" before={auditData?.before?.status} now={auditData?.after?.status} />
      )
    case AUDIT_TYPES.CLONE_PROTOCOL:
      return (
        <AuditFromToLabel label="Status changed" before={auditData?.before?.status} now={auditData?.after?.status} />
      )
    case AUDIT_TYPES.CREATE_TRIALSTAFF:
      return (
        <>
          <AuditLabel label="Name" now={auditData.after?.name} />
          <AuditLabel label="Staff type" now={auditData.after?.type} />
          <AuditLabel label="Email" now={auditData.after?.email} />
          <AuditLabel label="jobTitle" now={auditData.after?.jobTitle} />
          <AuditLabel label="Sites" now={auditData.after?.siteNumbers} />
        </>
      )
    case AUDIT_TYPES.UPDATE_TRIALSTAFF:
      return (
        <>
          <AuditFromToLabel
            label="Job title changed"
            before={auditData.before?.jobTitle}
            now={auditData.after?.jobTitle}
          />
          <AuditFromToLabel
            label="Trial staff type changed"
            before={auditData.before?.type}
            now={auditData.after?.type}
          />
          <AuditFromToLabel label="Status changed" before={auditData.before?.status} now={auditData.after?.status} />
          <AuditFromToLabel
            label="Site access changed"
            before={auditData.before?.siteNumbers}
            now={auditData.after?.siteNumbers}
          />
        </>
      )
    case AUDIT_TYPES.UPDATE_TRIALSTAFF_PII:
      return (
        <>
          <AuditFromToLabel label="Name changed" before={auditData.before?.name} now={auditData.after?.name} />
          <AuditFromToLabel
            label="Phone number changed"
            before={auditData.before?.phone}
            now={auditData.after?.phone}
          />
          <AuditFromToLabel label="Email changed" before={auditData.before?.email} now={auditData.after?.email} />
        </>
      )
    case AUDIT_TYPES.RETIRE_TRIALSTAFF:
      return <AuditFromToLabel label="Status changed" before={auditData.before?.status} now={auditData.after?.status} />
    case AUDIT_TYPES.REACTIVATE_TRIALSTAFF:
      return <AuditFromToLabel label="Status changed" before={auditData.before?.status} now={auditData.after?.status} />
    case AUDIT_TYPES.INVITE:
      return (
        <>
          <AuditDateLabel label="Date of invite" date={auditData.after?.date} />
          <AuditLabel label="Invite sent to" now={auditData.after?.email} />
          <AuditLabel label="Personnel type" now={auditData.targetModel || '-'} />
        </>
      )
    case AUDIT_TYPES.REINVITE:
      return (
        <>
          <AuditDateLabel label="Date of previous invite" date={auditData.before?.date} />
          <AuditDateLabel label="Date of invite" date={auditData.after?.date} />
          <AuditLabel label="Invite sent to" now={auditData.after?.email} />
          <AuditLabel label="Personnel type" now={auditData.targetModel || '-'} />
        </>
      )
    case AUDIT_TYPES.CREATE_CLINICIAN:
      return (
        <>
          <AuditLabel label="Name" now={auditData.after?.name} />
          <AuditLabel label="Staff type" now={auditData.after?.type} />
          <AuditLabel label="Email" now={auditData.after?.email} />
          <AuditLabel label="jobTitle" now={auditData.after?.jobTitle} />
          <AuditLabel label="Sites" now={auditData.after?.siteNumbers} />
        </>
      )
    case AUDIT_TYPES.UPDATE_CLINICIAN:
      return (
        <>
          <AuditFromToLabel label="Name changed" before={auditData.before?.name} now={auditData.after?.name} />
          <AuditFromToLabel label="Status changed" before={auditData.before?.status} now={auditData.after?.status} />
          <AuditFromToLabel
            label="Phone number changed"
            before={auditData.before?.phone}
            now={auditData.after?.phone}
          />
          <AuditFromToLabel label="Email changed" before={auditData.before?.status} now={auditData.after?.status} />
          <AuditFromToLabel label="Job title changed" before={auditData.before?.status} now={auditData.after?.status} />
        </>
      )
    case AUDIT_TYPES.UPDATE_CLINICIAN_PII:
      return (
        <>
          <AuditFromToLabel label="Name has changed" before={auditData.before?.name} now={auditData.after?.name} />
          <AuditFromToLabel label="Email has changed" before={auditData.before?.email} now={auditData.after?.email} />
          <AuditFromToLabel
            label="Phone number changed"
            before={auditData.before?.phone}
            now={auditData.after?.phone}
          />
          <AuditFromToLabel
            label="Jobtitle has changed"
            before={auditData.before?.jobTitle}
            now={auditData.after?.jobTitle}
          />
          <AuditFromToLabel label="Status changed" before={auditData.before?.status} now={auditData.after?.status} />
        </>
      )
    case AUDIT_TYPES.RETIRE_CLINICIAN:
      return <AuditFromToLabel label="Status changed" before={auditData.before?.status} now={auditData.after?.status} />
    case AUDIT_TYPES.REACTIVATE_CLINICIAN:
      return <AuditFromToLabel label="Status changed" before={auditData.before?.status} now={auditData.after?.status} />
    case AUDIT_TYPES.ACTIVATE_PROJECT:
      return <AuditFromToLabel label="Status changed" before={auditData.before?.status} now={auditData.after?.status} />
    case AUDIT_TYPES.RETIRE_PROJECT:
      return <AuditFromToLabel label="Status changed" before={auditData.before?.status} now={auditData.after?.status} />
    case AUDIT_TYPES.ARCHIVE_PROJECT:
      return <AuditFromToLabel label="Status changed" before={auditData.before?.status} now={auditData.after?.status} />
    case AUDIT_TYPES.UPDATE_SITE:
      return (
        <>
          <AuditFromToLabel label="Title" before={auditData.before?.title} now={auditData.after?.title} />
          <AuditFromToLabel
            label="Site number changed"
            before={auditData.before?.siteNumber}
            now={auditData.after?.siteNumber}
          />
          <AuditFromToLabel label="Address" before={auditData.before?.address} now={auditData.after?.address} />
          <AuditFromToLabel
            label="Contact person changed"
            before={auditData.before?.contactPersonName}
            now={auditData.after?.contactPersonName}
          />
          <AuditFromToLabel
            label="Contact person's email changed"
            before={auditData.before?.contactPersonEmail}
            now={auditData.after?.contactPersonEmail}
          />
          <AuditFromToLabel
            label="Contact person's job title changed"
            before={auditData.before?.contactPersonJobTitle}
            now={auditData.after?.contactPersonJobTitle}
          />
          <AuditFromToLabel
            label="Time zone changed"
            before={auditData.before?.timeZone}
            now={auditData.after?.timeZone}
          />
          {auditData.before?.active !== undefined && auditData.after?.active !== undefined && (
            <AuditFromToLabel
              label="Site state changed"
              before={`${auditData.before?.active ? 'Active' : 'Inactive'}`}
              now={`${auditData.after?.active ? 'Active' : 'Inactive'}`}
            />
          )}
          <AuditFromToLabel
            label="Site acitve languages changed"
            before={auditData.before?.languages}
            now={auditData.after?.languages}
          />
        </>
      )
    case AUDIT_TYPES.CREATE_SITE:
      return (
        <>
          <AuditLabel label="Title" now={auditData.after?.title} />
          <AuditLabel label="Site number" now={auditData.after?.siteNumber} />
          <AuditLabel label="Address" now={auditData.after?.address} />
          <AuditLabel label="Contact person" now={auditData.after?.contactPersonName} />
          <AuditLabel label="Contact person's job title" now={auditData.after?.contactPersonJobTitle} />
          <AuditLabel label="Time zone" now={auditData.after?.timeZone} />
          {auditData.before?.active !== undefined && (
            <AuditLabel label="Site state" now={`${auditData.after?.active ? 'Active' : 'Inactive'}`} />
          )}
          <AuditLabel label="Active languages" now={auditData.after?.languages} />
        </>
      )
    case AUDIT_TYPES.CREATE_PROJECT:
      return (
        <>
          <AuditLabel label="Title" now={auditData.after?.title} />
          <AuditLabel label="Study ID" now={auditData.after?.studyId} />
          <AuditLabel label="Display name" now={auditData.after?.displayName} />
          <AuditLabel label="Sponsor" now={auditData.after?.sponsor} />
          <AuditLabel label="CRO" now={auditData.after?.cro} />
          <AuditLabel label="Countries" now={auditData.after?.country} />
          <AuditLabel label="Active languages" now={auditData.after?.activeLanguages} />
          <AuditLabel label="Condition" now={auditData.after?.condition} />
          <AuditLabel label="Training version" now={auditData.after?.training} />
        </>
      )
    case AUDIT_TYPES.UPDATE_PROJECT:
      return (
        <>
          <AuditFromToLabel label="Title changed" before={auditData.before?.title} now={auditData.after?.title} />
          <AuditFromToLabel
            label="Study ID changed"
            before={auditData.before?.studyId}
            now={auditData.after?.studyId}
          />
          <AuditFromToLabel
            label="Display name changed"
            before={auditData.before?.displayName}
            now={auditData.after?.displayName}
          />
          <AuditFromToLabel label="Sponsor chnaged" before={auditData.before?.sponsor} now={auditData.after?.sponsor} />
          <AuditFromToLabel label="CRO chnaged" before={auditData.before?.cro} now={auditData.after?.cro} />
          <AuditFromToLabel
            label="Countries changed"
            before={auditData.before?.country}
            now={auditData.after?.country}
          />
          <AuditFromToLabel
            label="Active languages changed"
            before={auditData.before?.activeLanguages}
            now={auditData.after?.activeLanguages}
          />
          <AuditFromToLabel
            label="Condition changed"
            before={auditData.before?.condition}
            now={auditData.after?.condition}
          />
          <AuditFromToLabel
            label="Training version changed"
            before={auditData.before?.training}
            now={auditData.after?.training}
          />
        </>
      )
    default:
      return <></>
  }
}

const AuditLabel = ({ label, now }: { label: string; before?: string | undefined; now: string | undefined }) => {
  return now ? (
    <div className="col-12 mt-2">
      <p className="mb-0 p-mini alpha-80 fw-bold">{label}</p>
      <p className="me-2 p-small">{now}</p>
    </div>
  ) : null
}

const AuditFromToLabel = ({
  label,
  before,
  now,
}: {
  label: string
  before?: string | undefined
  now: string | undefined
}) => {
  const beforePrefix = 'From:'
  const nowPrefix = 'To:'

  return now ? (
    <div className="col-12 mt-2">
      <p className="mb-0 p-mini alpha-80 fw-bold">{label}</p>
      <p className="me-2 p-small">{before && beforePrefix + ' ' + before}</p>
      <p className="me-2 p-small">{now && nowPrefix + ' ' + now}</p>
    </div>
  ) : null
}

const AuditDateLabel = ({ label, date }: { label: string; date: string | undefined }) => {
  return date ? (
    <div className="col-12 mt-2">
      <p className="mb-0 p-mini alpha-80 fw-bold">{label}</p>
      <p className="me-2 p-small">{genericDataTimeformat(date)}</p>
    </div>
  ) : null
}
