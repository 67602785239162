/**
 * Created by @author Søren Tramm on 21/08/2023.
 */
import { BODY_PARTS, BODY_PARTS_QUESTIONNAIRE } from '../../../../constants'

const baseConfigurationClassification = {
  id: 'options.classification',
  type: 'TEXT_TRANSLATION',
  placeholder: 'Classification',
  label: '',
  prefix: '',
  postfix: '',
  value: { en: '' },
  validation: { required: true },
  errorMsg: 'Value is required',
}
const baseConfigurationIntensity = {
  id: 'options.intensity',
  type: 'TEXT_TRANSLATION',
  placeholder: 'Intensity',
  label: '',
  prefix: '',
  postfix: '',
  value: { en: '' },
  validation: { required: true },
  errorMsg: 'Value is required',
}
export const nprs11Point5LabelQuestionConfig = [
  {
    id: 'correlations.bodypart',
    type: 'SELECT_MULTIPLE',
    placeholder: 'Select a body part',
    label: 'What part of the body does this question relate to?',
    prefix: '',
    postfix: '',
    value: '',
    options: BODY_PARTS_QUESTIONNAIRE.map((value): { label: string; value: string } => ({
      value: value,
      label: BODY_PARTS[value],
    })),
    validation: { required: false },
    errorMsg: '',
    disabled: true,
  },
  {
    ...baseConfigurationClassification,
    label: 'Classification labels',
    id: baseConfigurationClassification.id + '.0',
    value: { en: 'None' },
  },
  {
    ...baseConfigurationClassification,
    id: baseConfigurationClassification.id + '.1',
    value: { en: 'Mild' },
  },
  {
    ...baseConfigurationClassification,
    id: baseConfigurationClassification.id + '.2',
    value: { en: 'Moderate' },
  },
  {
    ...baseConfigurationClassification,
    id: baseConfigurationClassification.id + '.3',
    value: { en: 'Severe' },
  },
  {
    ...baseConfigurationClassification,
    id: baseConfigurationClassification.id + '.4',
    value: { en: 'Worst pain imaginable' },
  },
  {
    ...baseConfigurationIntensity,
    label: 'Intensity values',
    id: baseConfigurationIntensity.id + '.0',
    value: { en: '0' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.1',
    value: { en: '1' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.2',
    value: { en: '2' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.3',
    value: { en: '3' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.4',
    value: { en: '4' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.5',
    value: { en: '5' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.6',
    value: { en: '6' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.7',
    value: { en: '7' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.8',
    value: { en: '8' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.9',
    value: { en: '9' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.10',
    value: { en: '10' },
  },
] as const

// export const nprs11QuestionConfig: Nprs11Question = {
//   _id: '',
//   title: { en: '' },
//   body: { en: '' },
//   questionnaireId: '',
//   continuation: { en: 'Next' },
//   next: [null],
//   required: true,
//   type: QuestionsTypes.nprs11,
//   label: 'Classification labels and Intensity options',
//   options: [
//     {
//       classification: { en: 'None' },
//       intensity: [{ en: '0' }],
//     },
//     {
//       classification: { en: 'Mild' },
//       intensity: [{ en: '1' }, { en: '2' }, { en: '3' }],
//     },
//     {
//       classification: { en: 'Moderate' },
//       intensity: [{ en: '4' }, { en: '5' }, { en: '6' }],
//     },
//     {
//       classification: { en: 'Severe' },
//       intensity: [{ en: '7' }, { en: '8' }, { en: '9' }],
//     },
//     {
//       classification: { en: 'Worst Pain Imaginable' },
//       intensity: [{ en: '10' }],
//     },
//   ],
//   // validation: { required: true },
//   // errorMsg: 'Options cannot be omitted',
// }
