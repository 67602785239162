export const getClinicianPiiConfig = () => {
  return clinicianPiiConfig
}

const clinicianPiiConfig = [
  {
    id: 'name',
    type: 'TEXT',
    placeholder: 'Add clinician name',
    label: 'Name',
    prefix: '',
    postfix: '*',
    value: '',
    validation: { required: true },
    errorMsg: 'Name is required',
  },
  {
    id: 'phone',
    type: 'PHONE',
    placeholder: 'Add clinician phone number',
    label: 'Phone number',
    prefix: '',
    postfix: '*',
    value: '',
    validation: {
      validate: (value) => {
        if (!value.tel && !value.countryCode) {
          return 'This is required.'
        }

        if (!value.tel) {
          return 'Phone number is required.'
        }
        if (!value.countryCode) {
          return 'Country code is required.'
        }

        return value || 'This is required.'
      },
    },
    errorMsg: 'Phone number required',
    disabled: false,
  },
  {
    id: 'email',
    type: 'TEXT',
    placeholder: 'Add clinician email',
    label: 'Email',
    prefix: '',
    postfix: '*',
    value: '',
    validation: { required: true },
    errorMsg: 'Email required',
    disabled: false,
  },
  {
    id: 'jobTitle',
    type: 'TEXT',
    placeholder: 'Add job title (optional)',
    label: 'Job title',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: false },
  },
]
