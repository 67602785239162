import { PERSONNEL_STATUS_ENUM } from '../Types'

export const displayStaffStatus = (data) => {
  if (data && data.inviteSent) {
    return data.status
  } else if (data && !data.inviteSent) {
    return PERSONNEL_STATUS_ENUM.CREATED
  } else {
    return '-'
  }
}
