import React, { useState } from 'react'
import { Avatar, Badge, Button, Popconfirm, Steps, Tag, Divider } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { usePhase } from '../../hooks/usePhase'
import { useVisit } from '../../hooks/useVisit'
import { useTrialStaff } from '../../hooks/useTrialStaff'
import { useSite } from '../../hooks/useSite'
import { UserOutlined, CheckCircleTwoTone } from '@ant-design/icons'

import hrt from 'human-readable-time'
import { useProject } from '../../hooks/useProject'
import { a, useTransition } from '@react-spring/web'
import { PhaseContentLabel } from '../phase/PhaseContentLabel'

export const ProjectActivation: React.FC = () => {
  //const { token } = theme.useToken()

  const { projectId } = useParams() as { projectId: string }

  const { data: projectData } = useProject(projectId)
  const { data: phaseData } = usePhase(projectId)
  const { data: visitData } = useVisit(projectId)
  const { data: trialStaffData } = useTrialStaff(projectId)
  const { data: siteData } = useSite(projectId)
  //const { data: questionnaireData } = useQuestionnaireEpro(project

  const [current, setCurrent] = useState(0)

  const steps = [
    {
      title: 'Phases',
      content: <PhaseOverview data={phaseData} />,
    },
    {
      title: 'Visits',
      content: <VisitsOverview data={visitData} />,
    },

    {
      title: 'Trial staffs',
      content: <TrialStaffOverview data={trialStaffData} />,
    },
    {
      title: 'Sites',
      content: <SitesOverview data={siteData} />,
    },
    {
      title: 'Questionnaires',
      content: 'Last-content',
    },
    {
      title: 'Project',
      content: 'Last-content',
    },
  ]

  const transitions = useTransition(current, {
    from: { transform: 'translate3d(20%,0,0)', opacity: 0 },
    enter: { transform: 'translate3d(0%,0,0)', opacity: 1 },
    leave: { transform: 'translate3d(-25%,0,0)', opacity: 0 },
    //config: { duration: 5000 },
  })

  const items = steps.map((item) => ({ key: item.title, title: item.title }))

  return (
    <div className="row">
      <div className="col-12">
        <div className="mt-5 position-relative">
          <Steps progressDot current={current} items={items} />
          {transitions((style, item) => (
            <a.div className="position-absolute w-100" style={style}>
              {steps[item].content}
              <FooterAndSubmit
                projectId={projectId}
                projectData={projectData}
                steps={steps}
                current={current}
                setCurrent={setCurrent}
              ></FooterAndSubmit>
            </a.div>
          ))}
        </div>
      </div>
    </div>
  )
}

const FooterAndSubmit = ({ projectId, projectData, steps, current, setCurrent }) => {
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const cancel = () => {
    setOpen(false)
  }

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const showPopconfirm = () => {
    setOpen(true)
  }

  const confirm = () => {
    setConfirmLoading(true)
  }

  return (
    <>
      <div className="col-12">
        <Divider />
      </div>
      <div className="col-12">
        <div className="d-flex align-items-center justify-content-center mb-5">
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Popconfirm
              title="Finalise project activation"
              description="Are you sure you want to activate this project?"
              open={open}
              okButtonProps={{ loading: confirmLoading }}
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" onClick={showPopconfirm}>
                Update project status
              </Button>
            </Popconfirm>
          )}
        </div>
      </div>
    </>
  )
}

const PhaseOverview = ({ data }: any) => {
  return (
    <div className="container mt-4">
      {data.map((phase, index) => {
        return (
          <div className="container-fluid mb-4" key={index}>
            <div className="position-relative mt-4" style={{ left: '3.4%', top: 0 }}>
              <div
                className="row mb-3"
                style={{ boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)', background: '#FAFAFA', maxWidth: '95%' }}
              >
                <div
                  className="col-12 bg-white  d-flex align-items-center "
                  style={{ boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)' }}
                >
                  <div className="ps-2 my-3 " style={{ width: '30%' }}>
                    <p className="p-mini opacity-50 ">{phase._id}</p>
                    <p className="mb-1 p-small fw-bold text-black">{phase.title}</p>
                  </div>

                  <div className="ps-2 my-3 " style={{ width: '20%' }}>
                    <p className="p-mini opacity-50 ">duration</p>
                    <p className="mb-1 p-small fw-bold text-black">{phase.duration} days</p>
                  </div>
                  <div className="ps-2 my-3 " style={{ width: '20%' }}>
                    <p className="p-mini opacity-50 ">type</p>
                    <p className="mb-1 p-small fw-bold text-black">{phase.type}</p>
                  </div>

                  <div className="d-flex justify-content-end" style={{ width: '30%' }}>
                    <Link to={'../overview'}>
                      <Button type="text" style={{ width: 110 }}>
                        View Phase
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const VisitsOverview = ({ data }) => {
  return (
    <div className="container mt-4">
      {data.map((visit, index) => {
        return (
          <div className="container-fluid mb-4" key={index}>
            <div className="position-relative mt-4" style={{ left: '3.4%', top: 0 }}>
              <div
                className="row mb-3"
                style={{ boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)', background: '#FAFAFA', maxWidth: '95%' }}
              >
                <div
                  className="col-12 bg-white  d-flex align-items-center "
                  style={{ boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)' }}
                >
                  <div className="ps-2 my-3 " style={{ width: '20%' }}>
                    <p className="p-mini opacity-50 ">Visit title</p>
                    <p className="mb-1 fw-bold text-black">{visit.title}</p>
                  </div>

                  <div className="ps-2 my-3 " style={{ width: '40%' }}>
                    <p className="p-mini opacity-50 ">Visit id</p>
                    <p className="mb-1 p-small fw-bold text-black">{visit._id}</p>
                  </div>
                  <div className="ps-2 my-3 " style={{ width: '20%' }}>
                    <p className="p-mini opacity-50 ">Placeholder</p>
                    <p className="mb-1 fw-bold text-black">-</p>
                  </div>
                  <div className="ps-2 my-3 " style={{ width: '20%' }}>
                    <p className="p-mini opacity-50 ">Placeholder</p>
                    <p className="mb-1 fw-bold text-black">-</p>
                  </div>
                  <div className="ps-2 my-3 " style={{ width: '20%' }}>
                    <p className="p-mini opacity-50 ">Placeholder</p>
                    <p className="mb-1 fw-bold text-black">-</p>
                  </div>
                  <div className="d-flex justify-content-end" style={{ width: '30%' }}>
                    <Link to={'../overview'}>
                      <Button type="text" style={{ width: 110 }}>
                        View visit
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const filterTrialStaff = (data, type?) => {
  const result = data.filter((obj) => {
    return type.indexOf(obj.type) > -1 ? true : false
  })
  return result
}

const setupPhone = (data) => {
  const countryCode = data.phone ? (data.phone.countryCode ? data.phone.countryCode : '-') : '-'
  const tel = data.phone ? (data.phone.tel ? data.phone.tel : '-') : '-'
  const phone = countryCode + ' ' + tel
  return phone
}

const TrialStaffOverview = ({ data }) => {
  const renderTrialStaff = (trialStaff, index, phone) => {
    return (
      <div key={index} className="col-4 mb-2" style={{ minWidth: 250 }}>
        <Badge.Ribbon
          text={trialStaff.inviteSent ? 'Invited' : 'No invite sent'}
          color={trialStaff.inviteSent ? 'green' : 'red'}
        >
          <div
            className="d-flex p-3 bg-white border rounded"
            style={{
              boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
            }}
          >
            <div className="rounded-circle me-3 py-1" style={{ width: 25, height: 25 }}>
              <Avatar size="large" icon={<UserOutlined />} />
            </div>
            <div className="ms-3">
              <p className="p-mini">Name</p>
              <p className="p-small fw-bold mb-2">{trialStaff.name}</p>

              <p className="p-mini">Email</p>
              <p className="p-small fw-bold mb-2">{trialStaff.email}</p>

              <p className="p-mini">Phone number</p>
              <p className="p-small fw-bold mb-2">{phone ? phone : '-'}</p>

              <div className="col-12 row">
                <div className="col-6">
                  <p className="p-mini">Type</p>
                  <p className="p-small fw-bold mb-1">{trialStaff.type}</p>
                </div>
                <div className="col-6">
                  <p className="p-mini">Status</p>
                  <Tag className="p-small mb-2" color={trialStaff.status === 'ACTIVE' ? 'blue' : 'magenta'}>
                    {trialStaff.status}
                  </Tag>
                </div>
              </div>
              <p className="p-mini">Invite has been sent at </p>
              <p className="p-small fw-bold">
                {trialStaff.inviteSent
                  ? hrt(new Date(trialStaff.inviteSent), '%hh%:%mm%:%ss% on %D% %month% %YYYY%')
                  : '-'}
              </p>
            </div>
          </div>
        </Badge.Ribbon>
      </div>
    )
  }

  return (
    <div className="container mt-4 mb-4">
      <div className="row">
        <PhaseContentLabel label="CTM"></PhaseContentLabel>
        {filterTrialStaff(data, 'CTM').map((staff, index) => {
          const phone = setupPhone(staff)
          return renderTrialStaff(staff, index, phone)
        })}
        <PhaseContentLabel label="CRA / DM"></PhaseContentLabel>
        {filterTrialStaff(data, 'CRA DM').map((staff, index) => {
          const phone = setupPhone(staff)
          return renderTrialStaff(staff, index, phone)
        })}
      </div>
    </div>
  )
}

const SitesOverview = ({ data }) => {
  return (
    <div className="container mt-4">
      {data.map((site, index) => {
        const phone = setupPhone(site)

        return (
          <div className="container-fluid mb-4" key={index}>
            <div className="position-relative mt-4" style={{ left: '3.4%', top: 0 }}>
              <div
                className="row mb-3"
                style={{ boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)', background: '#FAFAFA', maxWidth: '95%' }}
              >
                <div
                  className="col-12 bg-white  d-flex align-items-center "
                  style={{ boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)' }}
                >
                  <div className="ps-2 my-3 " style={{ width: '20%' }}>
                    <p className="p-mini opacity-50 ">Site number</p>
                    <p className="mb-1 fw-bold text-black">{site.siteNumber}</p>
                  </div>

                  <div className="ps-2 my-3 " style={{ width: '20%' }}>
                    <p className="p-mini opacity-50 ">Site title</p>
                    <p className="mb-1 p-small fw-bold text-black">{site.title}</p>
                  </div>
                  <div className="ps-2 my-3 " style={{ width: '25%' }}>
                    <p className="p-mini opacity-50 ">Site contact person</p>
                    <p className="mb-1 p-small fw-bold text-black">{site.contactPersonName}</p>
                  </div>
                  <div className="ps-2 my-3 " style={{ width: '20%' }}>
                    <p className="p-mini opacity-50 ">Site phone</p>
                    <p className="mb-1 p-small fw-bold text-black">{phone ? phone : '-'}</p>
                  </div>
                  <div className="ps-2 my-3 " style={{ width: '25%' }}>
                    <p className="p-mini opacity-50 ">Site time zone</p>
                    <p className="mb-1 p-small fw-bold text-black">{site.timeZone}</p>
                  </div>
                  <div className="ps-2 my-3 " style={{ width: '15%' }}>
                    <p className="p-mini opacity-50 ">Site active status</p>
                    <p className="mb-1 ms-4 px-2">
                      {site.active === true ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <Badge color="red" />}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
