import { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { authStore } from '../store/auth/authStore'

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
export const useIdleTimeout = ({ onIdle, idleTime = 5 }) => {
  const idleTimeout = idleTime * 1000
  const [isIdle, setIdle] = useState(false)
  const logout = authStore((state) => state.logout)

  const handleIdle = () => {
    setIdle(true)
    logout()
  }

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: idleTimeout / 6,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
  })

  return {
    isIdle,
    setIdle,
    idleTimer,
  }
}
