/**
 * Created by @author Søren Tramm
 */
import { UseFormReturn } from 'react-hook-form'
import React, { useCallback, useEffect, useMemo } from 'react'
import { QUESTIONNAIRE_RECURRENCE_ENUM, QUESTIONNAIRE_TYPE_ENUM } from '../../Types'
import { FormLabel } from '../../components/form/FormLabel'
import { Select, TimePicker } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import { date2SavedDataFormat, savedDataFormat2Date } from '../../util/datetime'
import { z } from 'zod'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)

const storageWindowTypeZodSchema = z.object({
  type: z.string(),
  startAt: z.object({ m: z.number(), h: z.number() }),
  endAt: z.object({ m: z.number(), h: z.number() }),
  offset: z.number(),
  cron: z.string(),
  window: z.number(),
})
enum FieldType {
  type = 'type',
  offset = 'offset',
  startAt = 'startAt',
  endAt = 'endAt',
  // window = 'window',
}
// type RecurrenceDataProps = {
//   [K in FieldType]: K extends 'type' ? string : K extends 'offset' ? number : Time
// }

interface RecurrenceError {
  type: any
  offset: any
  startAt: any
  endAt: any
  window: any
  message: any
}

export const RecurrenceWidget = ({
  questionnaireType,
  data,
  formHook,
  id = 'recurrence',
}: {
  questionnaireType: QUESTIONNAIRE_TYPE_ENUM
  data: any
  formHook: UseFormReturn
  id?: string
}) => {
  const {
    register,
    setValue,
    unregister,
    formState: { errors },
    setError,
    clearErrors,
  } = formHook

  const defaultData = useMemo(() => {
    return {
      type: QUESTIONNAIRE_RECURRENCE_ENUM.DAILY,
      offset: 0,
    }
  }, [])

  const reccurenceData = useMemo(() => {
    if (!!data) {
      const value = data.recurrence
      const validationResult = storageWindowTypeZodSchema.safeParse(value)

      if (validationResult.success) {
        return value
      } else {
        console.warn('Validation of date format failed', validationResult)
        return null
      }
    } else {
      return defaultData
    }
  }, [data, defaultData])

  const { watch } = formHook

  const isOnboarding = watch(`onboarding`)

  const errorState = errors[id] as RecurrenceError

  const handleUnregister = useCallback(
    (fields) => {
      unregister(fields)
    },
    [unregister]
  )

  /* Handles
   * 1. Registering of fields
   * 2. Create a state containing recurrence data
   * 3. Populate data in registered fields
   * 4. Unregistering fields
   */
  useEffect(() => {
    // List of field ids
    const fields = [
      `${id}.${FieldType.type}`,
      `${id}.${FieldType.offset}`,
      `${id}.${FieldType.startAt}`,
      `${id}.${FieldType.endAt}`,
    ]

    // Register fields
    fields.forEach((field) => {
      register(field, { required: true })
    })

    // Populate data to fields
    if (!isOnboarding) {
      if (reccurenceData) {
        // Populate data in registered fields
        Object.values(FieldType).forEach((field) => {
          setValue(`${id}.${field}`, reccurenceData[field])
        })
      }
    } else {
      console.log('setting onboard default data')
      setValue(`${id}.${FieldType.type}`, QUESTIONNAIRE_RECURRENCE_ENUM.NONE)
      setValue(`${id}.${FieldType.offset}`, 0)
      setValue(`${id}.${FieldType.startAt}`, { m: 0, h: 0 })
      setValue(`${id}.${FieldType.endAt}`, { m: 0, h: 0 })
    }

    // Unregister fields when the component unmounts
    return () => handleUnregister(fields)
  }, [isOnboarding, data, register, unregister, handleUnregister, setValue, questionnaireType, id, reccurenceData])

  const changeRecurrenceHandler = (value: string | number, fieldName: string) => {
    setValue(`${id}.${fieldName}`, value)
  }

  const changeTimeRangeHandler = (value: any, fieldName: string) => {
    const [startAt, endAt] = value

    const startAtField = `${id}.${FieldType.startAt}`
    const endAtField = `${id}.${FieldType.endAt}`

    if (!!value) {
      setValue(startAtField, startAt)
      setValue(endAtField, endAt)
      if (startAt.isSame(endAt, 'minute')) {
        setError(startAtField, {
          type: 'manual',
          message: 'Cannot have the same start time as the end time',
        })
      } else {
        setValue(startAtField, date2SavedDataFormat(startAt))
        setValue(endAtField, date2SavedDataFormat(endAt))
        clearErrors(startAtField)
        // clearErrors(endAtField)
      }
    } else {
      setValue(startAtField, undefined)
      setValue(endAtField, undefined)
    }
  }

  return (
    <>
      {!isOnboarding ? (
        <div className="col-12 w-100 py-2">
          <div className="row">
            <div className="col-6">
              <FormLabel label="Recurrence period" />
              <Select
                defaultValue={reccurenceData && reccurenceData.type}
                style={{ width: '100%' }}
                onChange={(value) => changeRecurrenceHandler(value, FieldType.type)}
                options={[
                  { value: QUESTIONNAIRE_RECURRENCE_ENUM.NONE, label: 'None' },
                  { value: QUESTIONNAIRE_RECURRENCE_ENUM.DAILY, label: 'Daily' },
                  { value: QUESTIONNAIRE_RECURRENCE_ENUM.WEEKLY, label: 'Weekly' },
                  { value: QUESTIONNAIRE_RECURRENCE_ENUM.MONTHLY, label: 'Monthly' },
                ]}
                disabled={true}
              />
              {errorState?.type && <div className="p-small text-danger">Recurrence period missing</div>}
            </div>

            <div className="col-6">
              <FormLabel label="Starts after" />
              <Select
                defaultValue={reccurenceData && reccurenceData.offset}
                style={{ width: '100%' }}
                onChange={(value) => changeRecurrenceHandler(value, FieldType.offset)}
                options={Array.from({ length: 31 }, (_, index) => {
                  const value = index
                  const label = `${value} day${value !== 1 ? 's' : ''}`
                  return { value, label }
                })}
                disabled={true}
                // disabled={recurrenceType === QUESTIONNAIRE_RECURRENCE_ENUM.NONE}
              />
              {errorState?.offset && <div className="p-small text-danger">Offset missing</div>}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <FormLabel label="Window" />
              <TimePicker.RangePicker
                defaultValue={
                  reccurenceData && [
                    reccurenceData.startAt && savedDataFormat2Date(reccurenceData.startAt),
                    reccurenceData.endAt && savedDataFormat2Date(reccurenceData.endAt),
                  ]
                }
                status={errorState?.startAt || errorState?.endAt ? 'error' : undefined}
                className="w-100"
                format="HH:mm"
                minuteStep={1}
                changeOnBlur={true}
                onChange={(value) => changeTimeRangeHandler(value, FieldType.startAt)}
              />
              {errorState?.startAt && (
                <div className="p-small text-danger">
                  {errorState?.startAt.message ? errorState?.startAt.message : 'Start and end time missing'}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
