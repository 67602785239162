/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/04/2022.
 * Edited and reshaped into being translatable by Søren Tramm on 31/08/2023.
 */
import { Input } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormLabel } from './FormLabel'
import { UseFormReturn } from 'react-hook-form'
import { LanguageISOCode, TextFormConfigObjectTranslation } from '../../Types'
import { LANGUAGE_LABELS } from '../../constants'

type Props = {
  formHook: UseFormReturn
  data: TextFormConfigObjectTranslation
  languages: any[] | undefined
  currentLanguage?: LanguageISOCode | undefined
}

interface IdPath {
  path: string[]
  index: number | null
}

export const FormTextFieldTranslation = ({ data, formHook, languages, currentLanguage }: Props) => {
  const {
    register,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = formHook

  const [dataId, setDataId] = useState<string | IdPath>(data.id)

  const fields = useMemo(() => {
    return languages?.map((lang: any) => `${data.id}.${lang.isoCode}`) || []
  }, [data.id, languages])

  // Define the unregister function outside the effect
  const handleUnregister = useCallback(
    (fields: any) => {
      unregister(fields)
    },
    [unregister]
  )

  useEffect(() => {
    /* We need to consider 3 things
    1. data.id includes a "." (dot) - if so the data.id is a nested id
    2. if data.is nested and last part is numeric character/s then the field is structured in an array and we need the number of the numeric characters as an index
    3. data.id does NOT include a "." (dot) it is a common id

    dataId is a state var that can be either a string or an object of type IdPath:{path: string[], index: number | null}
     */
    if (data.id.includes('.')) {
      // Is the last item in the list a numeric char
      const path = data.id.split('.') // We need this array for traversing later when getting the error
      const lastItem = path[path.length - 1] // We do not pop() yet. We might need the hole array
      const index = !isNaN(Number(lastItem)) ? Number(path.pop()) : null // If last item is a numeric char, pop() it for the index. If not we have the hole path array to pass on
      setDataId({ path, index })
    } else {
      setDataId(data.id)
    }

    // Register fields
    fields.forEach((field) => {
      register(field, { ...data.validation })
    })

    // Unregister fields when the component unmounts
    return () => handleUnregister(fields)
  }, [languages, data.id, register, unregister, data.validation, handleUnregister, fields])

  useEffect(() => {
    if (data.value) {
      setValue(data.id, data.value)
    }
  }, [data.value, data.id, setValue])

  /*
  Error handling
  */
  let errorState: any = undefined
  // data.id is a simple string. No need for any fancy digging
  if (typeof dataId === 'string') {
    errorState = errors[dataId]

    // data.id is a string defining a nested id.
  } else if (typeof dataId === 'object') {
    if (errors !== undefined) {
      // Make a copy of the path. We'll pop() later and since this is running on each render we would pop() the original short of values
      const path = [...dataId.path]
      const index = dataId.index

      // We know at this point that we at least have one key in the path. Get error state that correspond to that key
      const [firstPathElement, ...restPath] = path
      const fieldError = errors?.[firstPathElement]

      // If there are no errors related to the first key of the path
      if (fieldError === undefined) {
        errorState = undefined
        // Traverse the rest of the path
      } else {
        // Explanation:
        // If `restPath` is `['a', 'b', 'c']` and `fieldError` is an object like `{a: {b: {c: 'Some Error'}}}`,
        // the reduce operation would evaluate to `fieldError.a.b.c` and `result` would be equal to `'Some Error'`.
        const result = restPath.reduce((acc, key) => acc?.[key], fieldError)

        // Returns result or leaves error state as is
        errorState = result ? (index !== undefined && index !== null ? result[index] : result) : errorState
      }
    }
  }

  const onChange = (e: any, lang: LanguageISOCode) => {
    const id = `${data.id}.${lang}`
    setValue(id, e.target.value)
  }

  const compileErrorMessage = (errorState, dataErrMsg) => {
    const errorMessage = errorState && errorState.message ? (errorState.message as string) : (dataErrMsg as string)
    const langList = Object.keys(errorState)
      .map((key) => LANGUAGE_LABELS[key])
      .join(', ')
    return `${errorMessage}: ${langList}`
  }

  const currentValue = getValues(data.id) ? getValues(data.id) : data.value

  return (
    <div className="col-12 w-100 py-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>

      {languages ? (
        languages.map((lang: any, index) => {
          return (
            <Input
              key={lang.isoCode}
              placeholder={lang.isoCode.toUpperCase() + ' - ' + data.placeholder}
              onChange={(e) => onChange(e, lang.isoCode)}
              defaultValue={typeof currentValue === 'object' ? currentValue[lang.isoCode] || '' : ''}
              status={errorState ? 'error' : ''}
              // suffix={<span style={{ color: 'rgba(0,0,0,.33)' }}>{lang}</span>}
              className={`${lang.isoCode !== currentLanguage && 'd-none'}`}
            />
          )
        })
      ) : (
        <div>Languages are missing</div>
      )}

      {errorState ? <div className="p-small text-danger">{compileErrorMessage(errorState, data.errorMsg)}</div> : null}
    </div>
  )
}
