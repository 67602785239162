/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 21/03/2023.
 */
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { PROJECT_PAGE } from '../../constants'
import { useNavigate } from 'react-router-dom'
import { getProject } from './projectConfig'
import { getFormType } from '../../components/form/formFactory'
import { Alert, Button } from 'antd'
import Spinner from '../../components/spinner/Spinner'

import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { useProjectConfig } from '../../hooks/useProjectConfig'

type ProjectFormType = {
  title: string
  condition: string
  active: boolean
}

export const EproCreateProject = () => {
  const { data } = useProjectConfig()

  const [errorState, setErrorState] = useState<any>()
  const formHook = useForm<ProjectFormType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { handleSubmit } = formHook
  const navigate = useNavigate()
  const isEdit = false

  const config = useMemo(() => {
    return getProject(data.countries, data.languages)
  }, [data.countries, data.languages])

  const onSubmit = (data, e) => {
    if (e) {
      e.preventDefault()
    }

    if (isLoading) {
      return
    }

    setIsLoading(true)
    const p = ENDPOINTS.PROJECTS.create

    console.log('EproCreateProject > data = ', data)

    post(p, data)
      .then(() => {
        //setErrorState(res)
        setIsLoading(false)
        navigate(PROJECT_PAGE.to, { state: true })
      })
      .catch((error) => {
        setIsLoading(false)
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    console.log(' EproProjectPage > err = ', err)
    e.preventDefault()
  }

  return (
    <div className="container-fluid">
      <div className="container mt-4">
        <div className="row" style={{}}>
          <div className="col-6 mx-auto text-center my-3">
            <h5 className="fw-bold">Create new project</h5>
          </div>
        </div>
        <div className="row">
          <form className="col-6  mx-auto" onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="row group-item p-4">
              {config.map((item, index) => {
                const comp = getFormType(item, index, formHook)
                return comp
              })}

              {/* <StudyDuration></StudyDuration> */}

              <div className="col-12 d-flex mt-3 mb-3" style={{ justifyContent: 'center' }}>
                <Button
                  onClick={handleSubmit(onSubmit, onError)}
                  className="d-flex"
                  type="primary"
                  disabled={isLoading && isEdit}
                >
                  {!isLoading ? (
                    isEdit ? (
                      'Update Project details'
                    ) : (
                      'Create Project'
                    )
                  ) : (
                    <div className="d-flex justify-content-center " style={{ width: 144 }}>
                      <Spinner size={3} style={{ color: 'white' }}></Spinner>
                    </div>
                  )}
                </Button>
              </div>
            </div>
            <div className="row" style={{}}>
              <div className="col-12 mt-2 ">
                {errorState ? (
                  <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
