/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/06/2023.
 */
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Modal } from 'antd'
import { post } from '../../service/API'
import { openNotification } from '../../components/notifications/openNotification'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { visitWindowZodSchema } from '../../constants'

type Props = {
  visitArr: any
}

type UpdateState = {
  updated: any[]
  current: any[]
}

type VisitWindow = {
  offset: number
  duration: number
}

export const PhaseVisitList = ({ visitArr }: Props) => {
  // todo: @Søren please clean up this. There should be no need to have this in a useEffect
  useEffect(() => {
    // Sort by date, validate with zod
    visitArr.sort((visitA, visitB) => {
      if (visitA.window === undefined || visitB.window === undefined) return visitB // Return the unsorted item if there's no window

      const windowA: VisitWindow = visitA.window
      const windowB: VisitWindow = visitB.window

      // Validate window format
      const validationResultA = visitWindowZodSchema.safeParse(windowA)
      const validationResultB = visitWindowZodSchema.safeParse(windowB)
      console.log('visitA: ', windowA, validationResultA)
      console.log('visitB: ', windowB, validationResultB)
      if (validationResultA.success && validationResultB.success) {
        // const dateA = convert2Date(visitA.window.startAt)!
        // const dateB = convert2Date(visitB.window.startAt)!
        // return dateA.diff(dateB)
        console.log('visitA: ', visitA.window, 'visitB:')
        return windowA.offset - windowB.offset
      }
      return visitB // Return the unsorted item if there's an invalid window format
    })

    setItems(visitArr)
  }, [visitArr])

  const [items, setItems] = useState<any>(visitArr)
  const [saveUpdate, setSaveUpdate] = useState<UpdateState | null>(null)

  const compileWindowLabel = useCallback((visit) => {
    if (!!visit && !!visit.window) {
      const validationResult = visitWindowZodSchema.safeParse(visit.window)
      if (validationResult.success) {
        return `Starts after ${visit.window.offset} day${visit.window.offset !== 1 ? 's' : ''} - Open for ${
          visit.window.duration
        } day${visit.window.duration !== 1 ? 's' : ''}`
      }
    }
    return ''
  }, [])

  // const compileWindowLabel = (visit) => {
  //   if (!!visit && !!visit.window) {
  //     const validationResult = windowZodSchema.safeParse(visit.window)
  //     if (validationResult.success) {
  //       return `Offset: ${visit.window.offset} day${visit.window.offset !== 1 ? 's' : ''} Open for: ${
  //         visit.window.duration
  //       }`
  //     }
  //   }
  //   return ''
  // }

  //
  // If everything goes as expected the updated arr is also returned otherwise the old is returned
  //
  const onSave = (items) => {
    setItems(items)
    setSaveUpdate(null)
  }
  return (
    <>
      {saveUpdate?.current && saveUpdate.updated ? (
        <SaveDialog current={saveUpdate.current} updated={saveUpdate.updated} onSave={onSave}></SaveDialog>
      ) : null}
      <div>
        {items.map((visit, index) => {
          const visitQuestionnaire = visit.questionnaires || []
          return (
            <div key={visit._id}>
              <div className="col-12 px-4 mb-3">
                <div
                  className="row border pb-2 bg-white"
                  style={{
                    boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.00)',
                  }}
                >
                  <div className="col-12">
                    <div className="w-100 pt-3 ps-1 pb-2 d-flex justify-content-between align-items-center">
                      <div className="" style={{ width: '30%' }}>
                        <p className="me-2 ">{visit.title} </p>
                        <p className="p-mini opacity-50">{visit._id}</p>
                      </div>

                      <div className="" style={{ width: '45%' }}>
                        <p className="mb-0 p-mini alpha-80">Window</p>
                        <p className="mb-0" style={{ fontSize: '0.9rem' }}>
                          {compileWindowLabel(visit)}
                        </p>
                      </div>

                      <div className="d-flex justify-content-end align-items-center me-2 " style={{ width: '25%' }}>
                        <Link to={'assign-to-visit/' + visit._id}>
                          <Button type="text">
                            <p className="">Add a questionnaire</p>
                          </Button>
                        </Link>
                      </div>

                      <div className="d-flex justify-content-end align-items-center">
                        <Link to={'visits/' + visit._id}>
                          <Button>View visit</Button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {visitQuestionnaire.length === 0 ? (
                    <div className="col-12 px-4 mt-2">
                      <div className="row" style={{}}>
                        <div className="col-12 border d-flex justify-content-center py-4">
                          <p className="p-small opacity-50">Currently, there are no questionnaires on this visit</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12 mt-2 px-4">
                      <div className="row" style={{}}>
                        {/*{visitQuestionnaire.map((quesItem, index) => {
                          return (
                            <QuestionnaireListItem
                              key={index}
                              item={quesItem}
                              to={`questionnaire/${quesItem._id}`}
                            ></QuestionnaireListItem>
                          )
                        })}*/}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

const SaveDialog = ({ current, updated, onSave }) => {
  const { projectId } = useParams() as { projectId: string }

  const [open, setOpen] = useState(true)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const handleOk = () => {
    setConfirmLoading(true)

    post(ENDPOINTS.VISITS.reoder(projectId), { updated: updated })
      .then((result) => {
        console.log('PhaseVisitList > result = ', result)
        setOpen(false)
        setConfirmLoading(false)
        onSave(updated)
        return result
      })
      .catch((error) => {
        console.log('PhaseVisitList > error = ', error)
        setOpen(false)
        setConfirmLoading(false)
        onSave(current)
        return Promise.reject(error)
      })
  }

  const handleCancel = () => {
    console.log('Clicked cancel button')
    openNotification(
      'Your changes was discarded',
      'You can reorder the visits again if you want to save the changes',
      'sad'
    )
    onSave(current)
    setOpen(false)
  }

  return (
    <>
      <Modal title="Saving change" open={open} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleCancel}>
        <p>To accept the changes you have made to the ordering af visits click OK.</p>
      </Modal>
    </>
  )
}
