import { useParams } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import React, { useState } from 'react'

import { Button } from 'antd'

import Alert from 'antd/lib/alert'

import { post } from '../../service/API'

import { populateConfig } from '../../util/populateConfig'
import { getFormType } from '../../components/form/formFactory'
import { getProjectViewConfig } from './projectConfig'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { Checkbox, List } from 'antd'
import { FormLabel } from '../../components/form/FormLabel'
import { useByEndpoint } from '../../hooks/useByEndpoint'
import { openNotification } from '../../components/notifications/openNotification'
import { CRUD_VIEW_TYPE } from '../../Types'
import { JustificationInput } from '../../components/form/JustificationInput'

type Props = {
  closeDrawer: (value) => void
  onClose?: () => void
  viewType?: 'UPDATE' | 'VIEW'
}

export const ProjectCRUD = ({ onClose, closeDrawer, viewType = 'UPDATE' }: Props) => {
  const { projectId } = useParams() as { projectId: string }

  const p = ENDPOINTS.PROJECTS.byProjectId(projectId)

  const { data, mutate } = useByEndpoint(p)
  console.log('projectCRUD data >> ===', data)

  const configObj = populateConfig(getProjectViewConfig(), data, viewType)

  const formHook = useForm()

  const { handleSubmit } = formHook
  const [errorState, setErrorState] = useState<any>()

  const onSubmit = (formData, e) => {
    e.preventDefault()

    const updatedData = { ...formData, languages: data.languages }

    const method = viewType === 'UPDATE' ? 'PUT' : 'POST'

    return post(ENDPOINTS.PROJECTS.update(projectId), updatedData, method)
      .then(() => {
        mutate()

        openNotification('Project updated', '', 'happy')

        if (onClose) {
          onClose()
        }
        if (closeDrawer) {
          closeDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        openNotification('Project update failed', '', 'sad')
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  const onChange = (isoCode: string, checked: boolean) => {
    // Find the language with the specified isoCode in the data array
    const language = data.languages.find((lang) => lang.isoCode === isoCode)

    if (language) {
      // Update the active flag of the found language
      language.active = checked
    }
  }

  return (
    <>
      <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="col-12">
          {configObj.map((item, index) => {
            const comp = getFormType(item, index, formHook)
            return comp
          })}
          <div className="mt-2">
            <FormLabel label={'Project countries'} />
            <List
              className="mt-2"
              size="small"
              bordered
              dataSource={data.country}
              renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
            />
          </div>

          <div className="mt-2">
            <FormLabel label={'Project active languages'} />
            <List
              className="mt-2"
              size="small"
              bordered
              dataSource={data.languages}
              renderItem={(language: any) => (
                <List.Item>
                  <div className="col-12 d-flex">
                    <div className="col-5 me-2">{language.name}</div>

                    <div className="">
                      <Checkbox
                        value={{ name: language.name, isoCode: language.isoCode }}
                        defaultChecked={language.active === true}
                        onChange={(e) => onChange(language.isoCode, e.target.checked)}
                      ></Checkbox>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </div>

        <div className="col-12 mt-3">
          {/*Justification*/}
          {viewType === CRUD_VIEW_TYPE.UPDATE ? <JustificationInput formHook={formHook} /> : null}
        </div>

        <div className="col-12 mt-3">
          {errorState ? (
            <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
          ) : (
            <></>
          )}
        </div>

        <div className="col-12 d-flex justify-content-end mt-3">
          <Button className="me-2" onClick={navigateBack}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
            Save changes
          </Button>
        </div>
      </form>
    </>
  )
}
