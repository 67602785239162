import React, { useState } from 'react'
import { Modal } from 'antd'
import { post } from '../../service/API'
import { openNotification } from '../notifications/openNotification'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { useParams } from 'react-router'

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const SaveDialog = ({
  current,
  updated,
  onSave,
  method,
  mutate,
  setTriggerSubmit,
  setSaveDisabled,
  setErrorState,
}) => {
  const { projectId } = useParams() as { projectId: string }

  const [open, setOpen] = useState(true)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const handleOk = () => {
    setConfirmLoading(true)

    post(ENDPOINTS.QUESTIONS.order(projectId), updated, method)
      .then((result) => {
        setOpen(false)
        setConfirmLoading(false)
        setTriggerSubmit(false)
        setSaveDisabled(true)
        onSave(updated)
        mutate()
        return result
      })
      .catch((error) => {
        console.log('PhaseVisitList > error = ', error)
        setErrorState(error)
        setOpen(false)
        setConfirmLoading(false)
        onSave(current)
      })
  }

  const handleCancel = () => {
    console.log('Clicked cancel button')
    openNotification(
      'Your changes was discarded',
      'You can reorder the visits again if you want to save the changes',
      'sad'
    )
    onSave(current)
    setOpen(false)
    setTriggerSubmit(false)
  }

  return (
    <>
      <Modal title="Saving change" open={open} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleCancel}>
        <p>To accept the changes you have made to the ordering af visits click OK.</p>
      </Modal>
    </>
  )
}
