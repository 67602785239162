/**
 * Created by @author Søren Tramm
 */
import React from 'react'
import { Button, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'
import { LanguageISOCode, QUESTIONNAIRE_STATUS } from '../../../Types'

type Props = { item: any; to?: string; mayClone?: boolean; language: LanguageISOCode }

export const ProtocolQuestionnaireListItem = ({ item, to = './', mayClone = false, language }: Props) => {
  //const title = '---' //item.title[language] || item.title

  const languages = Object.keys(item?.name || {})
  console.log('ProtocolQuestionnaireListItem > languages = ', languages)

  //
  // THis should be cleanup
  // is cause by only updating the bacekend
  //
  // const title = typeof item.name === 'string' ? item.name : item?.name[language]

  return (
    <div className="col-12 px-2 mb-2">
      <div
        className="row  pb-2 bg-white"
        style={{ border: '1px solid ', borderColor: '#CFD3D7 ', boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.08)' }}
      >
        <div className="col-12">
          <div className="w-100 pt-3 pb-2 px-2 d-flex ">
            <div className=" " style={{ width: '30%' }}>
              <p className="mb-0 p-mini alpha-80 fw-bold">Internal Title</p>
              <p className="me-2">{item?.internalTitle}</p>
            </div>
            <div className="" style={{ width: '25%' }}>
              <p className="mb-0 p-mini alpha-80">Languages</p>
              <p className="mb-0 fw-bold text-primary text-capitalize">{languages.join(',')}</p>
            </div>
            <div className="" style={{ width: '25%' }}>
              <div className="">
                <p className="mb-0 p-mini alpha-80">Recurrence</p>
                <p className="mb-0 fw-bold text-primary ">{item?.recurrence?.type || 'Not set'}</p>
              </div>
            </div>
            <div className=" " style={{ width: '27%' }}>
              <p className="mb-0 p-mini alpha-80 fw-bold">Status</p>
              <p className="mb-0 fw-bold text-primary ">{item.status}</p>
            </div>
            {item.status === QUESTIONNAIRE_STATUS.ACTIVE ? (
              <Popconfirm
                title="Update the questionniare"
                description={
                  <p className="pr-6">
                    You will need to "clone phase" <br />
                    to edit the questionnaire{' '}
                  </p>
                }
                showCancel={false}
                okText="Ok"
              >
                <Button className="me-2">Update Questions</Button>
              </Popconfirm>
            ) : null}

            {item.status === QUESTIONNAIRE_STATUS.DRAFT ? (
              <div className="me-2">
                <Link to={`${to}/questionnaire/${item?._id}/manage-questions`}>
                  <Button>Manage questions</Button>
                </Link>
              </div>
            ) : null}

            <div className="">
              <Link to={`${to}/questionnaire/${item?._id}`}>
                <Button>View</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
