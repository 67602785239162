/**
 * Created by @author Søren Tramm on 21/08/2023.
 */
import { BODY_PARTS, BODY_PARTS_QUESTIONNAIRE } from '../../../../constants'

const baseConfigurationClassification = {
  id: 'options.classification',
  type: 'TEXT_TRANSLATION',
  placeholder: 'Classification',
  label: '',
  prefix: '',
  postfix: '',
  value: { en: '' },
  validation: { required: true },
  errorMsg: 'Value is required',
}
const baseConfigurationIntensity = {
  id: 'options.intensity',
  type: 'TEXT_TRANSLATION',
  placeholder: 'Intensity',
  label: '',
  prefix: '',
  postfix: '',
  value: { en: '' },
  validation: { required: true },
  errorMsg: 'Value is required',
}
export const nprs11Point2LabelQuestionConfig = [
  {
    id: 'correlations.bodypart',
    type: 'SELECT_MULTIPLE',
    placeholder: 'Select a body part',
    label: 'What part of the body does this question relate to?',
    prefix: '',
    postfix: '',
    value: '',
    options: BODY_PARTS_QUESTIONNAIRE.map((value): { label: string; value: string } => ({
      value: value,
      label: BODY_PARTS[value],
    })),
    validation: { required: false },
    errorMsg: '',
    disabled: true,
  },
  {
    ...baseConfigurationClassification,
    label: 'Classification labels',
    id: baseConfigurationClassification.id + '.0',
    value: { en: 'None' },
  },
  {
    ...baseConfigurationClassification,
    id: baseConfigurationClassification.id + '.1',
    value: { en: 'Worst pain imaginable' },
  },

  {
    ...baseConfigurationIntensity,
    label: 'Intensity values',
    id: baseConfigurationIntensity.id + '.0',
    value: { en: '0' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.1',
    value: { en: '1' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.2',
    value: { en: '2' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.3',
    value: { en: '3' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.4',
    value: { en: '4' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.5',
    value: { en: '5' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.6',
    value: { en: '6' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.7',
    value: { en: '7' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.8',
    value: { en: '8' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.9',
    value: { en: '9' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.10',
    value: { en: '10' },
  },
] as const
