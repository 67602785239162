import { Route, Routes, useParams } from 'react-router-dom'
import { VIEW_TYPE } from '../../Types'
import React, { Suspense } from 'react'
import { AuditListItem } from './AuditListItem'
import { Button } from 'antd'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { ProjectDrawer } from '../epro-project/ProjectDrawer'
import { AuditViewDetails } from './AuditViewDetails'
import { useAuditByTarget } from '../../hooks/useAuditByTarget'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

export const AuditViewListTargetProtocol = ({ onClose, closeDrawer, viewType }: Props) => {
  const { projectId, protocolId } = useParams() as { projectId: string; protocolId: string }
  const { data } = useAuditByTarget(projectId, protocolId)

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      <Routes>
        <Route
          path="/:auditId"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer projectId={projectId} width="30%" setVisible={closeDrawer} to="../" title="Audit details">
                <AuditViewDetails closeDrawer={closeDrawer} />
              </ProjectDrawer>
            </Suspense>
          }
        />
      </Routes>

      <div className="">
        {data && data.length > 0 ? (
          data.map((item, index) => {
            return <AuditListItem item={item} to="./" key={index} />
          })
        ) : (
          <p>No audit data exists for this protocol</p>
        )}
      </div>
      <div className="col-12">
        <hr />
      </div>

      <div className="col-12 d-flex justify-content-end">
        <Button type="primary" onClick={navigateBack}>
          Close
        </Button>
      </div>
    </>
  )
}
