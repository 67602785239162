/**
 * Created by @author Søren Tramm, 2023.09.28
 */
import React, { useEffect, useState } from 'react'
import { CRUD_VIEW_TYPE, HTTPMethod, RESOURCE_STATUS, VIEW_TYPE } from '../../Types'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { post } from '../../service/API'
import { FormLabel } from '../../components/form/FormLabel'
import { Alert, Button, notification } from 'antd'
import { populateConfig } from '../../util/populateConfig'
import { newProtocolConfig } from './protocolConfig'
import { SmileOutlined } from '@ant-design/icons'
import { getFormType } from '../../components/form/formFactory'
import { useSWRConfig } from 'swr'
import { useProtocolById } from '../../hooks/useProtocolById'
import { genericDataTimeformat } from '../../util/datetime'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

export const ProtocolCRUD = ({ closeDrawer, onClose, viewType = CRUD_VIEW_TYPE.CREATE }: Props) => {
  const { mutate: mutateGlobal } = useSWRConfig()

  const { projectId, protocolId } = useParams() as { projectId: string; protocolId: string }
  const { data, mutate } = useProtocolById(projectId, protocolId)

  const formHook = useForm()
  const { setValue, register, unregister } = formHook

  // const [activeState, setActiveState] = useState<boolean>(false)

  const configObj = data ? populateConfig(newProtocolConfig, data, viewType) : newProtocolConfig.concat([])

  const { handleSubmit } = formHook
  const [errorState, setErrorState] = useState<any>()

  console.log('ProtocolCRUD > data > ', data)

  useEffect(() => {
    register('active', { required: false })
    return () => {
      unregister('active')
    }
  }, [register, unregister])

  useEffect(() => {
    setValue('active', !!configObj?.value)
  }, [configObj.value, setValue])

  const onSubmit = (formData, e) => {
    e.preventDefault()

    const updatedData = { ...data, ...formData }

    const method = viewType === 'UPDATE' ? HTTPMethod.PUT : HTTPMethod.POST
    const sendData = updatedData
    const endpoint = ENDPOINTS.PROTOCOLS.ACTIONS.UPDATE(projectId)

    console.log(' ProtocolCRUD > sendData = ', sendData, method)

    return post(endpoint, sendData, method)
      .then((result) => {
        openNotification('Protocol updated ', '', 'happy')
        mutate()

        // Mutate for revalidate in list of questions in the drawer
        const key = ENDPOINTS.PROTOCOLS.GET_ALL + projectId
        mutateGlobal(key)

        if (onClose) {
          onClose()
        }

        //if (closeDrawer && viewType === 'UPDATE') {
        if (closeDrawer && viewType !== CRUD_VIEW_TYPE.VIEW) {
          closeDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    console.log('onError = ', err)
    setErrorState(err)
    e.preventDefault()
  }

  const openNotification = (
    msg: string = 'Created',
    description: string = 'lorem ipsum...',
    smiley: 'happy' | 'sad' | '' = ''
  ) => {
    notification.open({
      duration: 2.5,
      message: msg,
      description: description,
      icon: smiley === 'happy' ? <SmileOutlined style={{ color: '#108ee9' }} /> : null,
    })
  }
  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      {viewType === CRUD_VIEW_TYPE.CREATE || viewType === CRUD_VIEW_TYPE.UPDATE ? (
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="row">
            {configObj.map((item, index) => {
              const comp = getFormType(item, index, formHook)
              return comp
            })}
          </div>

          <div className="col-12 mt-3">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>

          <div className="col-12 d-flex justify-content-end mt-3">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
              {viewType === 'CREATE' ? 'Create Protocol' : 'Save changes'}
            </Button>
          </div>
        </form>
      ) : (
        <div className="">
          <div className="col-12 mb-3">
            <FormLabel label="Protocol ID"></FormLabel>
            <h6 className="fw-bold">{data?.title ? data.title : '-'}</h6>
          </div>

          <div className="col-12 mb-3">
            <FormLabel label="Status"></FormLabel>
            <h6 className="fw-bold">{data?.status}</h6>
          </div>

          <div className="col-12 mb-3">
            <FormLabel label="Activated at"></FormLabel>
            <h6 className="fw-bold">{data?.activatedAt ? genericDataTimeformat(data?.activatedAt) : '-'}</h6>
          </div>

          {/*The "updatedAt" value IS the retired date when the status is RETIRED*/}
          {/*FIXME Can we rely on that in the future? */}
          {data?.status === RESOURCE_STATUS.RETIRED ? (
            <div className="col-12 mb-3">
              <FormLabel label="Retired at"></FormLabel>
              <h6 className="fw-bold">{genericDataTimeformat(data?.updatedAt)}</h6>
            </div>
          ) : null}

          <div className="col-12">
            <hr />
          </div>

          <div className="col-12 d-flex justify-content-end">
            <div>
              <Link to="edit">
                <Button type="primary" disabled={data?.status !== RESOURCE_STATUS.DRAFT}>
                  Edit protocol
                </Button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
