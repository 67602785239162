/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 24/05/2023.
 */

import { HTTPMethod, PERSONNEL_TYPE_ENUM } from '../../Types'
import hrt from 'human-readable-time'
import { Alert, Button } from 'antd'
import { getReq, post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { useParams } from 'react-router'
import React, { useState } from 'react'
import { openNotification } from '../../components/notifications/openNotification'
import { JustificationInput } from '../../components/form/JustificationInput'
import { useForm } from 'react-hook-form'

type Props = {
  data: any
  mutate: () => void
  userId: string
  userType: PERSONNEL_TYPE_ENUM
  onClose?: () => void
}
export const InviteTrialstaff = ({ data, mutate, userType, onClose }: Props) => {
  const { projectId, userId } = useParams() as { projectId: string; userId: string }
  const [errorState, setErrorState] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const formHook = useForm()
  const { handleSubmit } = formHook

  const sendInviteClick = () => {
    setIsLoading(true)
    const p =
      userType === PERSONNEL_TYPE_ENUM.CLINICIAN
        ? ENDPOINTS.CLINICIANS.invite(projectId, userId)
        : ENDPOINTS.TRIALSTAFF.invite(projectId, userId)

    getReq(p)
      .then((result) => {
        mutate()

        openNotification(
          'First Invite sent',
          'We sent the invite to the user. They will receive an email with a link to set their password.',
          'happy'
        )
        setIsLoading(false)

        if (onClose) {
          onClose()
        }

        return result
      })
      .catch((error) => {
        console.log('TrialStaffCRUD > error = ', error)

        setIsLoading(false)
        setErrorState(error)
      })
  }

  const reInviteClick = (formData, e) => {
    e.preventDefault()

    const p =
      userType === PERSONNEL_TYPE_ENUM.CLINICIAN
        ? ENDPOINTS.CLINICIANS.resend(projectId)
        : ENDPOINTS.TRIALSTAFF.resend(projectId)

    setIsLoading(true)

    const sendData = { _id: userId, projectId, justification: formHook.getValues('justification') }
    post(p, sendData, HTTPMethod.PUT)
      .then((result) => {
        mutate()
        setIsLoading(false)
        openNotification(
          'A re-invite was sent to the user',
          'They will receive an email with a link to set their password.',
          'happy'
        )
        if (onClose) {
          onClose()
        }
        return result
      })
      .catch((error) => {
        console.log('PhaseVisitList > error = ', error)
        setErrorState(error)
      })
  }
  const onError = (err, e) => {
    e.preventDefault()
  }

  const inviteSent = data?.inviteSent
    ? 'Invite sent: ' + hrt(new Date(data.inviteSent), 'At %hh%:%mm%:%ss% on %D% %month% %YYYY%')
    : 'Invite not sent'

  return (
    <>
      <form className="row">
        <div className="row mt-3" style={{}}>
          <div className="col-12 d-flex justify-content-between align-items-center my-2">
            <h6 className="mb-0">Invite user</h6>
            <p className="p-small alpha-60 ">{inviteSent}</p>
          </div>

          <div className="col-12 mt-2 mb-3 ">
            <p className="">
              Reset password and resend invite' button to send a new password to allow the user to re-access the portal.
            </p>
          </div>

          {data?.inviteSent ? (
            <div className="col-12 mt-2 mb-3 ">
              <JustificationInput
                formHook={formHook}
                label="Please provide a reason for resetting the password."
                id="justification"
              />
            </div>
          ) : null}

          <div className="col-12 d-flex justify-content-end">
            {data?.inviteSent ? (
              <Button
                type="primary"
                onClick={handleSubmit(reInviteClick, onError)}
                loading={isLoading}
                className="mt-3"
              >
                Reset password and resend invite
              </Button>
            ) : (
              <Button type="primary" className="me-2" onClick={sendInviteClick} loading={isLoading}>
                Send invite
              </Button>
            )}
          </div>

          <div className="col-12">
            <hr />
          </div>
          <div className="mt-3">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>
        </div>
      </form>
    </>
  )
}
