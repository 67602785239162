import { Button, Select } from 'antd'
import { GroupHeader } from '../../components/display/GroupHeader'
import { useParams } from 'react-router'
import { useState } from 'react'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { mutate as mutateGlobal } from 'swr'

const stateOptions = [
  { value: 'DRAFT', label: 'DRAFT' },
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'RETIRED', label: 'RETIRED' },
  { value: 'ARCHIVED', label: 'ARCHIVED' },
]

export const DebugProjectState = () => {
  const { projectId } = useParams() as { projectId: string }

  const [debugValue, setDebugValue] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)

  const submitDebugValue = () => {
    setLoading(true)
    const key = ENDPOINTS.PROJECTS.byProjectId(projectId)

    return post(ENDPOINTS.DEBUG.updateProjectState(projectId), { state: debugValue }, 'PUT')
      .then(() => {
        setTimeout(() => {
          mutateGlobal(key)
          setLoading(false)
        }, 1000)
      })
      .catch((error) => {
        alert(error.message)
        setLoading(false)
      })
  }

  const handleChange = (value: string) => {
    setDebugValue(value)
  }

  return (
    <div className="row mt-5 group-item" style={{}}>
      <GroupHeader
        label="Update project state"
        byline="Updating the project is allowed without following the linear flow"
      >
        <div className="col-12 py-3 d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between" style={{ width: '100%' }}>
            <p className=" text-truncate opacity-75 py-1">
              {'Please select which state you would like to update the project to'}
            </p>
            <div className=" d-flex justify-content-end">
              <div className="px-2">
                <Select
                  variant="filled"
                  placeholder="Select state"
                  onChange={handleChange}
                  style={{ width: 150 }}
                  options={stateOptions}
                />
              </div>
              <div className="d-flex justify-content-end">
                <Button type="primary" loading={loading} disabled={!debugValue} onClick={submitDebugValue}>
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </GroupHeader>
    </div>
  )
}
