import { useParams } from 'react-router-dom'
import { VIEW_TYPE } from '../../Types'
import React from 'react'
import { AuditListItem } from './AuditListItem'
import { Button } from 'antd'
import { useAudits } from '../../hooks/useAudits'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

export const AuditViewListTargetPersonnel = ({ onClose, closeDrawer, viewType }: Props) => {
  const { projectId, authorSub } = useParams() as { projectId: string; authorSub: string }
  console.log('AuditViewListTargetPersonnel > ', projectId, authorSub)
  const { data } = useAudits(projectId, authorSub)

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      <div className="">
        {data && data.length > 0 ? (
          data.map((item, index) => {
            return <AuditListItem item={item} to="./" key={index} />
          })
        ) : (
          <p>No audit data exists for this user</p>
        )}
      </div>
      <div className="col-12">
        <hr />
      </div>

      <div className="col-12 d-flex justify-content-end">
        <Button type="primary" onClick={navigateBack}>
          Close
        </Button>
      </div>
    </>
  )
}
