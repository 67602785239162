import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'

//import './styles/ant-compiled.css'
import './styles/styles.scss'

import Amplify from 'aws-amplify'

const awsconfig = {
  aws_project_region: process.env.REACT_APP_BASE_URL_aws_region,
  aws_cognito_region: process.env.REACT_APP_BASE_URL_aws_region,
  aws_user_pools_id: process.env.REACT_APP_BASE_URL_aws_user_pools_id,
  aws_user_pools_web_client_id: process.env.REACT_APP_BASE_URL_aws_user_pools_web_client_id,
}

//@ts-ignore
if (process.env.NODE_ENV !== 'production') {
  //@ts-ignore
  import(`./styles/size-debug.scss`).then(() => {})
}

Amplify.configure(awsconfig)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
