import React from 'react'

import LoginForm from './LoginForm'

import { Route, Routes } from 'react-router-dom'
import {
  LOGIN_FORGOT_PASSWORD,
  LOGIN_FORGOT_PASSWORD_SUBMIT,
  LOGIN_MFA_CODE,
  LOGIN_NEW_PASSWORD,
} from '../../constants'
import { ForgotPasswordSubmit } from './ForgotPasswordSubmit'
import { LoginMFA } from './LoginMFA'
import { ForgotPassword } from './ForgotPassword'
import { CompleteNewPassword } from './CompleteNewPassword'

import { ReactComponent as Logo } from '../../assets/sandme-logo.svg'
import { ReactComponent as Dots } from '../../assets/dots.svg'

export const LoginPage = () => {
  return (
    <div className="container-fluid h-100 " style={{}}>
      <div className="row h-100" style={{}}>
        <div
          className="col-7 d-none d-md-flex d-flex justify-content-center align-items-center"
          style={{ background: '#eeeded' }}
        >
          <div className="" style={{ maxWidth: 600 }}>
            <div className="mb-4">
              <Dots style={{ width: '2rem' }}></Dots>
            </div>

            <h2 className="fw-bolder me-2" style={{ letterSpacing: -0.3 }}>
              Annea Clinical
            </h2>

            <p className="opacity-75">
              Save yourself frustration, hassle, and time. <br />
              Annea gives you full control to collect any patient reported outcome.
            </p>
            <div className="bg-black w-100 mt-4 mb-3 " style={{ opacity: 0.15, height: 1 }}></div>
            <div className="">
              <Logo style={{ width: '6.5rem', marginBottom: -5 }}></Logo>
              {/*<ByLogo style={{ width: '9rem', marginLeft: -6 }}></ByLogo>*/}
              <p className="p-small opacity-75">A StudiesAndMe product</p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-5 d-flex flex-column justify-content-center align-items-center">
          <Routes>
            <Route path="/" element={<LoginForm></LoginForm>}></Route>

            {/*            <Route path={LOGIN_NEW_PASSWORD.to}>
              <ForgotPasswordSubmit></ForgotPasswordSubmit>
            </Route>*/}

            <Route path={LOGIN_MFA_CODE.path} element={<LoginMFA />} />
            <Route path={LOGIN_FORGOT_PASSWORD.path} element={<ForgotPassword />} />
            <Route path={LOGIN_FORGOT_PASSWORD_SUBMIT.path} element={<ForgotPasswordSubmit />} />
            <Route path={LOGIN_NEW_PASSWORD.path} element={<CompleteNewPassword />} />
          </Routes>
          {/*<TestBtn></TestBtn>*/}
        </div>
      </div>
    </div>
  )
}
