/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/04/2022.
 */

import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { FormLabel } from './FormLabel'
import { UseFormReturn } from 'react-hook-form'
import { DropdownFormConfigObject } from '../../Types'

//const { Option } = Select

type Props = { formHook: UseFormReturn; data: DropdownFormConfigObject }

export const FormDropDown = ({ data, formHook }: Props) => {
  const {
    setValue,
    register,
    formState: { errors },
    getValues,
  } = formHook

  const errorState = errors[data.id]

  const formValue = getValues(data.id)
  const currentValue = !formValue ? data.value : formValue

  const [state, setState] = useState<string | null>(currentValue)

  useEffect(() => {
    register(data.id, data.validation)

    setValue(data.id, data.value)
  }, [register, data.id, data.validation, data.value, setValue])

  const handleChange = (value) => {
    setValue(data.id, value)
    setState(value)
  }

  return (
    <div className={` ${data.className} py-1`}>
      <FormLabel label={data.label} />
      <Select
        defaultValue={state}
        value={state}
        style={{ width: '100%' }}
        onChange={handleChange}
        placeholder={data.placeholder}
        options={data.options}
        status={errorState ? 'error' : ''}
      >
        {/*{items}*/}
      </Select>

      {errorState ? <div className="p-small text-danger">{data.errorMsg}</div> : null}
    </div>
  )
}
