/**
 * Created by @author Søren Tramm on 15/08/2023.
 */
export const numberQuestionConfig = [
  {
    id: 'min',
    type: 'NUMBER',
    placeholder: 'Minimum number',
    label: 'Minimum',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: false },
    errorMsg: '',
  },
  {
    id: 'max',
    type: 'NUMBER',
    placeholder: 'Maximum number',
    label: 'Maximum',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: false },
    errorMsg: '',
  },
] as const
