import { DEBUG } from '../constants'
import { Auth } from 'aws-amplify'

export const getReq = async (p: any): Promise<any> => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken()

  return fetch(p, {
    method: 'GET',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then((res) => {
      const contentType = res.headers.get('Content-Type')
      const isJson = contentType && contentType.indexOf('application/json') > -1

      if (DEBUG) {
        console.log(' API > DEBUG res.status = ', res.status, res.statusText)
      }

      if (res.status === 404) {
        console.log(' API > res = ', res)
        const url = res.url || ''
        return Promise.reject(getErrorResponse(res, 404, 'Could not find endpoint ' + url))
      }

      if (res.status === 401) {
        console.log(' API GET > 401 401 401 401401 = ')
        console.log(' API > 401 res = ', res)
        console.log('API > isJson = ', isJson)
        if (!isJson) {
          return res.text().then((result) => {
            console.log('API > result = ', result)
            const msg = 'Unauthorized: ' + result || 'Unauthorized'
            return Promise.reject(getErrorResponse(result, 401, msg))
          })
        }
      }

      if (!isJson) {
        return res.text()
      }

      return res.json()
    })
    .then((res) => {
      if (res.error || res.result === false) {
        return Promise.reject(res)
      }

      return res
    })

    .catch((err) => {
      console.log(' API > err = ', err)

      return Promise.reject(err)
    })
}

export const post = async (p: string, data: any, method: string = 'POST') => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken()

  return fetch(p, {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (DEBUG) {
        console.log(' API > res.status = ', res.status, p)
      }

      if (res.status === 401) {
        console.log(' API POST > 401 401 401 401401 = ', res)
        return Promise.reject(res)
        //return updateAccessToken(p, 'POST', data)
      }

      return res.json()
    })
    .then((res) => {
      if (res.result === false) {
        return Promise.reject(res)
      }
      return res
    })

    .catch((err) => {
      return Promise.reject(err)
    })
}

export const postFileUpload = async (p: string, data: any, method: string = 'POST') => {
  //const token = getToken()

  const token = (await Auth.currentSession()).getIdToken().getJwtToken()

  return fetch(p, {
    method: method,
    headers: {
      Authorization: 'Bearer ' + token,
      //'Content-Type': 'multipart/form-data',
    },
    body: data,
  })
    .then((res) => {
      if (DEBUG) {
        console.log(' API > res.status = ', res.status, p)
      }
      if (res.status === 401) {
        console.log(' API POST > 401 401 401 401401 = ', res)
        return Promise.reject(res)
        //return updateAccessToken(p, 'POST', data)
      }
      return res.json()
    })
    .then((res) => {
      if (res.result === false) {
        return Promise.reject(res)
      }
      return res
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

export const put = async (p: string, data: any) => {
  return post(p, data, 'PUT')
}

export const destroy = async (p: string, data?: any) => {
  return post(p, data, 'DELETE')
}

export type ErrorType = {
  result: false
  status: number
  err: any
  message: string
}

function getErrorResponse(err, status: number, msg): ErrorType {
  return {
    result: false,
    status: status,
    err: err,
    message: msg,
  }
}
