/**
 * Created by @author Søren Tramm
 */
import React, { useState } from 'react'
import { CRUD_VIEW_TYPE, HTTPMethod, VIEW_TYPE } from '../../Types'
import { useParams } from 'react-router-dom'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { post } from '../../service/API'
import { Alert, Button } from 'antd'
import { useSWRConfig } from 'swr'
import { openNotification } from '../../components/notifications/openNotification'
import { JustificationInput } from '../../components/form/JustificationInput'
import { useForm } from 'react-hook-form'
import { useProtocolById } from '../../hooks/useProtocolById'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

export const ProtocolActivate = ({ closeDrawer, onClose, viewType }: Props) => {
  const { mutate: mutateGlobal } = useSWRConfig()
  const { projectId, protocolId } = useParams() as { projectId: string; protocolId: string }
  const { data } = useProtocolById(projectId, protocolId)
  const [errorState, setErrorState] = useState<any>()

  const justificationId = 'justification'
  const formHook = useForm()

  const onSubmit = async () => {
    // Validate justification. Must be present when activating protocol
    const justification = formHook.getValues(justificationId)
    if (justification === undefined || justification === '') {
      setErrorState({ message: 'Please provide a reason for the activation' })
      return
    }

    console.log('justification > ', justification)

    const endpoint = ENDPOINTS.PROTOCOLS.ACTIONS.ACTIVATE(projectId)
    const sendData = {
      projectId,
      protocolId,
      justification,
    }

    try {
      console.log('sendData >> ', sendData)

      await post(endpoint, sendData, HTTPMethod.POST)
      // const newProtocolId = res.data._id

      openNotification('Protocol activated ', '', 'happy')
      // Mutate for revalidate in list of questions in the drawer
      const key = ENDPOINTS.PROTOCOLS.GET_ALL + projectId
      mutateGlobal(key)
      mutateGlobal(ENDPOINTS.PROTOCOLS.GET_ACTIVE_DRAFT(projectId))

      if (onClose) {
        onClose()
      }

      //if (closeDrawer && viewType === 'UPDATE') {
      if (closeDrawer && viewType !== CRUD_VIEW_TYPE.VIEW) {
        closeDrawer(false)
      }
    } catch (error: any) {
      console.log('error = ', error)
      openNotification('Activation failed: ', error.message, 'sad')
    }
  }

  return (
    <>
      <div className="">
        <div className="col-12 mb-3">
          <h5 className="mb-1 text-primary font-weight-bold d-flex">You are about to activate:</h5>
          <h6 className="fw-bold">{data?.title}</h6>
        </div>

        <div className="col-12">
          <hr />
        </div>

        <div className="col-12 mt-4">
          <h5 className="mb-1 text-primary font-weight-bold d-flex">Activation reason</h5>
          <p className="text-black mb-3">Please provide information about why this protocol is being activated. </p>
        </div>

        {/*Justification*/}
        <div className="row mt-3">
          <JustificationInput formHook={formHook} id={justificationId} />
          <div className="col-12 mt-6">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="col-12 d-flex justify-content-end mt-3">
          <div>
            <Button type="primary" onClick={() => onSubmit()}>
              Activate
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
