import { Button, Modal, Popconfirm, Steps } from 'antd'
import { useState } from 'react'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { useParams } from 'react-router'
import { mutate as mutateGlobal } from 'swr'
import { useProjectStatus } from '../../hooks/useProjectStatus'

enum PROJECT_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  RETIRED = 'RETIRED',
  ARCHIVED = 'ARCHIVED',
}

const statusToStep = {
  DRAFT: 0,
  ACTIVE: 1,
  RETIRED: 2,
  ARCHIVED: 3,
}

export const EproProjectModal = ({ isModalOpen, handleOk, handleCancel, setOpen }) => {
  const { projectId } = useParams() as { projectId: string }

  const { data: projectStatus, mutate: projectStateMutate } = useProjectStatus(projectId)

  const currentStep = statusToStep[projectStatus.value] || 0

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [openPop, setOpenPop] = useState(false)

  const hideModal = () => {
    setOpen(false)
  }

  const showPopconfirm = () => {
    setOpenPop(true)
  }

  const confirm = () => {
    setConfirmLoading(true)
    return post(
      ENDPOINTS.PROJECTS.STATUS.update(projectId),
      { projectId: projectId, status: projectStatus.next },
      'PUT'
    )
      .then(() => {
        setTimeout(() => {
          const key = ENDPOINTS.PROJECTS.byProjectId(projectId)
          projectStateMutate()
          mutateGlobal(key)
          setOpen(false)
          setConfirmLoading(false)
          setOpenPop(false)
        }, 2000)
      })
      .catch((error) => {
        alert(error.message)
        setOpen(false)
        setConfirmLoading(false)
        setOpenPop(false)
      })
  }

  const cancel = () => {
    setOpen(false)
    setOpenPop(false)
  }

  // TODO: projectStatus.value !== 'ARCHIVED' should use an ENUM or constant

  return (
    <>
      <Modal
        title={'Current project status'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={600}
        footer={[
          projectStatus.value !== 'ARCHIVED' ? (
            <div key="1" className="mt-4">
              <Button className="me-2" onClick={hideModal}>
                Cancel
              </Button>
              <Popconfirm
                title={'Are you sure you would like to update the project status to ' + projectStatus.next + ' ?'}
                onConfirm={confirm}
                open={openPop}
                onCancel={cancel}
                okButtonProps={{ loading: confirmLoading }}
                okText="Update"
                cancelText="Cancel"
              >
                <Button type="primary" onClick={showPopconfirm}>
                  Update project to the {projectStatus.next} state
                </Button>
              </Popconfirm>
            </div>
          ) : (
            <></>
          ),
        ]}
      >
        <div className="col-12 mt-5 mb-2">
          <div>
            <Steps
              progressDot
              current={currentStep}
              items={[
                {
                  title: PROJECT_STATUS.DRAFT,
                  description: <p className="p-mini">New data can be added and edited within the project</p>,
                },
                {
                  title: PROJECT_STATUS.ACTIVE,
                  description: <p className="p-mini">No new data can be added, some data can only be editted</p>,
                },
                {
                  title: PROJECT_STATUS.RETIRED,
                  description: <p className="p-mini">The project can be viewed but not modified</p>,
                },
                {
                  title: PROJECT_STATUS.ARCHIVED,
                  description: <p className="p-mini">The project is archived</p>,
                },
              ]}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
