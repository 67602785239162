/**
 * Created by @author Søren Tramm
 */
import React from 'react'
import { CRUD_VIEW_TYPE, HTTPMethod, VIEW_TYPE } from '../../Types'
import { useParams } from 'react-router-dom'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { post } from '../../service/API'
import { FormLabel } from '../../components/form/FormLabel'
import { Button, notification } from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import { useSWRConfig } from 'swr'
import { useByEndpoint } from '../../hooks/useByEndpoint'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

export const ProtocolQuestionnaireClone = ({ closeDrawer, onClose, viewType }: Props) => {
  const { mutate: mutateGlobal } = useSWRConfig()
  const { projectId, protocolId, questionnaireId } = useParams() as {
    projectId: string
    protocolId: string
    questionnaireId: string
  }

  const p = questionnaireId ? ENDPOINTS.QUESTIONNAIRES.byQuestionnaireId(projectId, questionnaireId) : ''
  const { data } = useByEndpoint(p)

  const onSubmit = async () => {
    const endpoint = ENDPOINTS.PROTOCOLS.ACTIONS.CLONE_QUESTIONNAIRE(projectId)
    // const endpoint = ENDPOINTS.QUESTIONNAIRES.clone(projectId)
    const sendData = { id: questionnaireId }

    try {
      await post(endpoint, sendData, HTTPMethod.POST)
      openNotification('Questionnaire cloned ', '', 'happy')
      mutateGlobal(ENDPOINTS.PROTOCOLS.GET_ONE(projectId, protocolId))

      if (closeDrawer && viewType !== CRUD_VIEW_TYPE.VIEW) {
        closeDrawer(false)
      }
    } catch (error: any) {
      console.log('error = ', error)
      openNotification('Cloning failed: ', error.message, 'sad')
    }
  }

  console.log('ProtocolClone > data > ', data)

  const openNotification = (
    msg: string = 'Created',
    description: string = 'lorem ipsum...',
    smiley: 'happy' | 'sad' | '' = ''
  ) => {
    notification.open({
      duration: 2.5,
      message: msg,
      description: description,
      icon: smiley === 'happy' ? <SmileOutlined style={{ color: '#108ee9' }} /> : null,
    })
  }

  // const navigateBack = () => {
  //   console.log(' ProtocolCRUD > close = ')
  //   if (onClose) {
  //     onClose()
  //   }
  //   //closeDrawer(true)
  // }

  return (
    <>
      <div className="">
        <div className="col-12 mb-3">
          <FormLabel label="We will clone this questionnaire"></FormLabel>
          <h6 className="fw-bold">{data?.name}</h6>
        </div>

        <div className="col-12">
          <hr />
        </div>

        <div className="col-12 d-flex justify-content-end">
          <div>
            <Button type="primary" onClick={() => onSubmit()}>
              Save clone
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
