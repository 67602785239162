/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09/12/2022.
 */
import { GroupHeader } from '../../components/display/GroupHeader'
import { Button } from 'antd'
import React, { Suspense } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { ProjectDrawer } from '../epro-project/ProjectDrawer'

import { formatDistance } from 'date-fns'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { useClinician } from '../../hooks/useClinician'
import { ClinicianCRUD } from './ClinicianCRUD'
import { CRUD_VIEW_TYPE } from '../../Types'
import { AuditViewListTargetPersonnel } from '../audit/AuditViewListTargetPersonnel'
import { ClinicianSiteCRUD } from './ClinicianSiteCRUD'
import { AuditViewDetails } from '../audit/AuditViewDetails'
import { LoginViewListClinician } from '../audit/LoginViewListClinician'
import { displayStaffStatus } from '../../util/staffUtils'

export const Clinicians = () => {
  const { projectId } = useParams() as { projectId: string }
  const { data, mutate } = useClinician(projectId)

  const closeDrawer = (value) => {
    if (!value) {
      mutate()
    }
  }

  const now = new Date()

  const deleteHeader = (
    <Link to="retire">
      <Button type="default">Revoke/Reactivate access</Button>
    </Link>
  )

  return (
    <>
      <Routes>
        <Route
          path="/create"
          element={
            <ProjectDrawer projectId={projectId} setVisible={closeDrawer} to="../" title="Create a new clinician">
              <ClinicianCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.CREATE}></ClinicianCRUD>
            </ProjectDrawer>
          }
        />
        <Route
          path="/:userId/view/*"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer
                projectId={projectId}
                setVisible={closeDrawer}
                header={deleteHeader}
                to="../"
                title="View clinician information"
              >
                <ClinicianCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.VIEW}></ClinicianCRUD>
              </ProjectDrawer>
            </Suspense>
          }
        />
        <Route
          path="/:userId/edit-sites"
          element={
            <ProjectDrawer projectId={projectId} width="40%" to="../" title="Site access">
              <ClinicianSiteCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.UPDATE}></ClinicianSiteCRUD>
            </ProjectDrawer>
          }
        />
        <Route
          path="/:userId/:authorSub/audit-trail/*"
          element={
            <>
              <ProjectDrawer
                projectId={projectId}
                width="40%"
                to="../"
                title="View audit trail"
                header={
                  <div className="">
                    <Link to={'logins'} className="">
                      <Button type="default" className="me-2">
                        Logins
                      </Button>
                    </Link>
                  </div>
                }
              >
                <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                  <AuditViewListTargetPersonnel closeDrawer={closeDrawer} />
                  <Routes>
                    <Route
                      path="logins"
                      element={
                        <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                          <ProjectDrawer
                            width="50%"
                            projectId={projectId}
                            setVisible={closeDrawer}
                            to="../"
                            title="Login list"
                          >
                            <LoginViewListClinician closeDrawer={closeDrawer} />
                          </ProjectDrawer>
                        </Suspense>
                      }
                    />
                    <Route
                      path="/:auditId"
                      element={
                        <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                          <ProjectDrawer
                            projectId={projectId}
                            width="30%"
                            setVisible={closeDrawer}
                            to="../"
                            title="Audit details"
                          >
                            <AuditViewDetails closeDrawer={closeDrawer} />
                          </ProjectDrawer>
                        </Suspense>
                      }
                    />
                  </Routes>
                </Suspense>
              </ProjectDrawer>
            </>
          }
        />
      </Routes>
      <div className="row mt-3 group-item" style={{}}>
        <GroupHeader
          label="Clinicians"
          header={
            <Link to={'./create'}>
              <Button type="primary">Create a new clinician</Button>
            </Link>
          }
          byline="Clinicians are users who have access to the project and can be assigned to sites. They can be assigned to multiple sites."
        >
          {data.map((item, index) => {
            return (
              <div key={index} className="col-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                <div className="" style={{ width: '20%' }}>
                  <p className="p-mini">Name</p>
                  <div className="d-flex">
                    <p className="me-2 text-truncate">{item.name}</p>
                  </div>
                </div>
                <div className="" style={{ width: '25%' }}>
                  <p className="p-mini">Email</p>
                  <div className="d-flex">
                    <p className="text-truncate opacity-75">{item.email}</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between" style={{ width: '15%' }}>
                  <div className="">
                    <p className="p-mini ">Type</p>
                    {item?.type}
                  </div>
                  <div className="" style={{ width: '30%' }}>
                    <p className="p-mini ">Status</p>
                    <p className="">{displayStaffStatus(item)}</p>
                  </div>
                </div>

                <div className="d-flex justify-content-start" style={{ width: '10%' }}>
                  <div className="" style={{}}>
                    <p className="p-mini ">Invite sent</p>
                    {item.inviteSent ? (
                      <p className="text-primary">{formatDistance(now, new Date(item.inviteSent), {})}</p>
                    ) : (
                      'NOT SENT'
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <Link to={`./${item._id}/${item.sub}/audit-trail`}>
                    <Button className="me-2">Audit trail</Button>
                  </Link>

                  <Link to={'./' + item._id + '/edit-sites'}>
                    <Button type="default" className="me-2">
                      Edit sites
                    </Button>
                  </Link>
                  <Link to={'./' + item._id + '/view'}>
                    <Button type="primary">View</Button>
                  </Link>
                </div>
              </div>
            )
          })}
        </GroupHeader>
      </div>
    </>
  )
}
