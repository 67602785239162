import { DebugProjectState } from './DebugProjectState'

export const DebugPage = () => {
  return (
    <>
      <div className="row mt-5">
        <h4>Debug view</h4>
        <p className="p-small">
          In the debug view the admin is allowed to perform actions that are forbidden in a production envrionment
        </p>
      </div>
      <DebugProjectState></DebugProjectState>
    </>
  )
}
