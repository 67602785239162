/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/03/2023.
 */
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { internalTitleConfig, newQuestionnaireConfig, questionnaireNotificationsConfig } from './questionnaireConfig'
import { useForm } from 'react-hook-form'
import { post } from '../../service/API'

import { SmileOutlined } from '@ant-design/icons'
import { getFormType } from '../../components/form/formFactory'
import { Alert, Button, notification } from 'antd'

import { FormLabel } from '../../components/form/FormLabel'
import { populateConfig } from '../../util/populateConfig'
import { CRUD_VIEW_TYPE, LanguageISOCode, QuestionsTypes, VIEW_TYPE } from '../../Types'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { RecurrenceWidget } from './RecurrenceWidget'
import { useByEndpoint } from '../../hooks/useByEndpoint'

import { useSWRConfig } from 'swr'

import { useProject } from '../../hooks/useProject'
import { LanguageDropdown } from './question/LanguageDropdown'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons/lib/icons'
import { BODY_PARTS } from '../../constants'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
  allowEdit?: boolean
}

export const QuestionnaireCRUD = ({
  closeDrawer,
  onClose,
  viewType = CRUD_VIEW_TYPE.CREATE,
  allowEdit = true,
}: Props) => {
  const { projectId, questionnaireId, protocolId, phaseId } = useParams() as {
    projectId: string
    questionnaireId: string
    protocolId: string
    phaseId: string
  }

  const { data: projectData } = useProject(projectId || '')
  const supportedLanguages = projectData.languages
  const defaultLanguage = supportedLanguages[0]
  const [currentLanguage, setCurrentLanguage] = useState<LanguageISOCode>(defaultLanguage.isoCode)

  const p = questionnaireId ? ENDPOINTS.QUESTIONNAIRES.byQuestionnaireId(projectId, questionnaireId) : ''
  const { mutate: globalMutate } = useSWRConfig()
  const { data, mutate } = useByEndpoint(p)

  const formHook = useForm()
  const { setValue } = formHook

  const { handleSubmit, watch } = formHook
  const questionnaireType = watch(`type`)
  const isOnboarding = watch(`onboarding`)

  //
  // build config object - to populate form
  //
  const questionnaireConfig = isOnboarding
    ? newQuestionnaireConfig
    : [...newQuestionnaireConfig, ...questionnaireNotificationsConfig]
  const configObj = data ? populateConfig(questionnaireConfig, data, viewType) : questionnaireConfig.concat([])

  // Add Internal Title separate from the config
  const internalTitleObj = data ? populateConfig([internalTitleConfig], data, viewType)[0] : internalTitleConfig

  const [errorState, setErrorState] = useState<any>()

  useEffect(() => {
    // If onboarding questionnaire, set default values for recurrence
    if (isOnboarding) {
      setValue('notifications.reminders', null)
    }
  }, [setValue, isOnboarding])

  const onSubmit = (formData, e) => {
    e.preventDefault()
    // const defaultValues = isOnboarding ? { notifications: { reminders: undefined } } : {}

    const createData = { ...formData, projectId: projectId, protocolId: protocolId, phaseId: phaseId }
    const updatedData = { ...formData, _id: questionnaireId, protocolId: protocolId }

    const method = viewType === 'UPDATE' ? 'PUT' : 'POST'
    const sendData = viewType === 'UPDATE' ? updatedData : createData

    console.log(' QuestionnaireCRUD > sendData = ', sendData)
    return post(ENDPOINTS.QUESTIONNAIRES.ACTION(projectId), sendData, method)
      .then((result) => {
        const msg = viewType === 'UPDATE' ? 'Questionnaire updated' : 'Questionnaire created'
        openNotification(msg, '', 'happy')
        mutate()
        globalMutate(ENDPOINTS.PROTOCOLS.GET_ONE(projectId, protocolId))

        if (onClose) {
          onClose()
        }

        //if (closeDrawer && viewType === 'UPDATE') {
        if (closeDrawer && viewType !== CRUD_VIEW_TYPE.VIEW) {
          closeDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    console.log('onError = ', err)
    // setErrorState(err)
    e.preventDefault()
  }

  const openNotification = (
    msg: string = 'Created',
    description: string = 'lorem ipsum...',
    smiley: 'happy' | 'sad' | '' = ''
  ) => {
    notification.open({
      duration: 2.5,
      message: msg,
      description: description,
      icon: smiley === 'happy' ? <SmileOutlined style={{ color: '#108ee9' }} /> : null,
    })
  }

  const navigateBack = () => {
    console.log(' ManagerCRUD > close = ')
    if (onClose) {
      onClose()
    }
    //closeDrawer(true)
  }
  const formatTime = (time: { h: number; m: number }) => {
    return `${time.h < 10 ? `0${time.h}` : time.h}:${time.m < 10 ? `0${time.m}` : time.m}`
  }

  return (
    <>
      {viewType === CRUD_VIEW_TYPE.CREATE || viewType === CRUD_VIEW_TYPE.UPDATE ? (
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="row">
            {/*Internal title*/}
            {getFormType(internalTitleObj, 0, formHook)}

            {/*Language dropdown*/}
            <LanguageDropdown
              supportedLanguages={supportedLanguages}
              defaultLanguage={defaultLanguage}
              onChange={setCurrentLanguage}
            />

            {/*Rest of the form*/}
            {configObj.map((item, index) => {
              return getFormType(
                item,
                index,
                formHook,
                projectData.languages,
                currentLanguage ? currentLanguage : defaultLanguage
              )
            })}
            {/*Recurrence*/}
            <RecurrenceWidget id="recurrence" data={data} formHook={formHook} questionnaireType={questionnaireType} />
          </div>

          <div className="col-12 mt-3">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>

          <div className="col-12 d-flex justify-content-end mt-3">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
              {viewType === 'CREATE' ? 'Create Questionnaire' : 'Save changes'}
            </Button>
          </div>
        </form>
      ) : (
        <div className="">
          <div className="col-12 mb-3">
            <p className="mb-2 p-mini opacity-50">ID: {data._id}</p>
            <LanguageDropdown
              supportedLanguages={supportedLanguages}
              defaultLanguage={defaultLanguage}
              onChange={setCurrentLanguage}
            />
          </div>
          <div className="col-12 mb-3">
            <FormLabel label="Type"></FormLabel>

            <h6 className="fw-bold">{data.type ? data.type : '-'}</h6>
          </div>
          <div className="col-12 mb-3">
            <FormLabel label="Internal Title"></FormLabel>
            <h6 className="me-2">{data.internalTitle}</h6>
          </div>
          <div className="col-12 mb-3">
            <FormLabel label="Name"></FormLabel>
            <h6 className="fw-bold">{data.name[currentLanguage]}</h6>
          </div>
          <div className="col-12 mb-3">
            <FormLabel label="Status"></FormLabel>
            <h6 className="fw-bold">{data.status}</h6>
          </div>
          <div className="col-12 mb-3">
            <FormLabel label="Onboarding"></FormLabel>
            <h6 className="fw-bold">{data ? (data.onboarding === true ? 'Yes' : 'No') : '-'}</h6>
          </div>

          {!isOnboarding && data.notifications?.reminders && (
            <>
              <div className="col-12 mb-3">
                <FormLabel label="Reminder on activation"></FormLabel>
                <h6 className="fw-bold">{data.notifications.reminders.start[currentLanguage]}</h6>
              </div>
              <div className="col-12 mb-3">
                <FormLabel label="Ending reminder"></FormLabel>
                <h6 className="fw-bold">{data.notifications.reminders.end[currentLanguage]}</h6>
              </div>
            </>
          )}

          {!data.onboarding && (
            <>
              <div className="col-12 mb-3">
                <FormLabel label="Recurrence type"></FormLabel>
                <h6 className="fw-bold">{!!data.recurrence && data.recurrence.type ? data.recurrence.type : '-'}</h6>
              </div>
              <div className="col-12 mb-3">
                <FormLabel label="Window"></FormLabel>
                <h6 className="fw-bold">
                  {!!data.recurrence && data.recurrence.startAt ? `${formatTime(data.recurrence.startAt)} - ` : '-'}
                  {!!data.recurrence && data.recurrence.endAt ? formatTime(data.recurrence.endAt) : '-'}
                </h6>
              </div>
              <div className="col-12 mb-3">
                <FormLabel label="Start offset in days"></FormLabel>
                <h6 className="fw-bold">
                  {!!data.recurrence && data.recurrence.offset !== undefined ? data.recurrence.offset : '-'}
                </h6>
              </div>
            </>
          )}

          <div className="col-12">
            <FormLabel label="Questions"></FormLabel>
          </div>

          <div className="col-12 px-0 mt-2 mb-4 px-3">
            {data.questions &&
              data.questions.map((question: any, i: number) => (
                <QuestionItem item={question} language={currentLanguage!} key={i} />
              ))}
          </div>

          <div className="col-12">
            <hr />
          </div>

          <div className="col-12 d-flex justify-content-end">
            {allowEdit ? (
              <Link to="edit">
                <Button type="primary">Edit Questionnaire</Button>
              </Link>
            ) : (
              <Button type="primary" onClick={navigateBack}>
                Close
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const QuestionItem = ({ item, language, to = './' }) => {
  const isNprsQuestion = useMemo(() => {
    return (
      item?.type === QuestionsTypes.nprs11pt2l ||
      item?.type === QuestionsTypes.nprs11pt5l ||
      item?.type === QuestionsTypes.nprs5
    )
  }, [item])

  return (
    <div
      className="row pb-2 bg-white mt-2"
      style={{
        border: '1px solid',
        borderColor: '#CFD3D7 ',
        boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.08)',
      }}
    >
      <div className="col-12 pt-3">
        <div className="row">
          <div className="col-12 pb-2 px-2 d-flex align-content-end">
            <p className="p-mini opacity-50">{item._id}</p>
          </div>
          <div className="col-12 pb-2 px-2">
            <p className="mb-0 p-mini alpha-80">Title</p>
            <p className="me-2 fw-bold text-truncate">
              {typeof item.title === 'object' ? item.title[language] : item.title}
            </p>
          </div>
          <div className="col-12 pb-2 px-2">
            <p className="mb-0 p-mini alpha-80">Body</p>
            <p className="mb-1 p-small">{item.body[language]}</p>
          </div>

          {!isNprsQuestion && item?.options?.length > 0 && (
            <div className="col-12 px-2">
              <p className="mb-0 p-mini alpha-80">Options</p>
              <ul className="p-small" style={{ listStyleType: 'none', paddingLeft: '5px' }}>
                {item.options.map((option, index) => (
                  <li className="" key={`option-${index}`}>
                    {'- ' + option[language]}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {isNprsQuestion && item?.options?.classification?.length && (
            <div className="col-12 px-2">
              <p className="mb-0 p-mini alpha-80">Option labels</p>
              <ul className="p-small" style={{ listStyleType: 'none', paddingLeft: '5px' }}>
                {item.options.classification.map((option, index) => (
                  <li className="" key={`option-${index}`}>
                    {`- ` + option[language]}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {item?.correlations?.bodypart?.length > 0 && (
            <div className="col-12 px-2">
              <p className="mb-0 p-mini alpha-80">Body parts</p>
              <ul className="p-small" style={{ listStyleType: 'none', paddingLeft: '5px' }}>
                {item?.correlations?.bodypart?.map((part: any, i: number) => (
                  <li className="" key={`bodypart-${i}`}>
                    {'- ' + BODY_PARTS[part]}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-8">
            <div className="d-flex justify-content-between">
              <div className="">
                <p className="mb-0 p-mini alpha-80 text-start">Type</p>
                <p className="mb-0 fw-bold p-small text-primary text-capitalize">
                  {item.type ? item.type.toLowerCase() : '-'}
                </p>
              </div>
              <div className="ps-2">
                <p className="mb-0 p-mini alpha-80 text-center">Has next</p>
                <p className="mb-0 fw-bold text-primary text-capitalize text-start text-xl-center">
                  {item.next && item?.next.length > 0 ? (
                    <CheckCircleOutlined style={{ color: '#198754' }} />
                  ) : (
                    <CloseCircleOutlined style={{ color: 'red' }} />
                  )}
                </p>
              </div>
              <div className="ps-2">
                <p className="mb-0 p-mini alpha-80 text-center">Required</p>
                <p className="mb-0 fw-bold text-primary text-capitalize text-start text-xl-center">
                  {item?.required === true ? (
                    <CheckCircleOutlined style={{ color: '#198754' }} />
                  ) : (
                    <CloseCircleOutlined style={{ color: 'red' }} />
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
