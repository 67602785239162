/**
 * Created by @author Søren Tramm 2023.09.28
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { genericDataTimeformat } from '../../util/datetime'

type Props = { item: any; to?: string }

export const ProtocolListItem = ({ item, to = './' }: Props) => {
  return (
    <>
      <div className="col-12 px-2 mb-2">
        <div
          className="row  pb-2 bg-white"
          style={{ border: '1px solid ', borderColor: '#CFD3D7 ', boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.08)' }}
        >
          <div className="col-12">
            <div className="w-100 pt-3 pb-2 px-2 d-flex ">
              <div className=" " style={{ width: '13%' }}>
                <p className="mb-0 p-mini alpha-80 fw-bold">Status</p>
                <p className="me-2">{item.status}</p>
              </div>

              <div className=" " style={{ width: '60%' }}>
                <p className="mb-0 p-mini alpha-80 fw-bold">Title</p>
                <p className="me-2">{item.title}</p>
              </div>

              <div className=" " style={{ width: '60%' }}>
                <p className="mb-0 p-mini alpha-80 fw-bold">Activated at</p>
                <p className="me-2">{genericDataTimeformat(item.activatedAt)}</p>
              </div>

              <div className=" " style={{ width: '60%' }}>
                <p className="mb-0 p-mini alpha-80 fw-bold">Retired at</p>
                <p className="me-2">{genericDataTimeformat(item.retiredAt)}</p>
              </div>

              <div className="d-flex justify-content-end align-items-center " style={{ width: '20%' }}>
                <div className="">
                  <Link to={`./${item._id}/audit-trail`}>
                    <Button>Audit trail</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
