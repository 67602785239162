import Slider from 'antd/lib/slider'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { FormLabel } from './FormLabel'
import { SliderRangeFormConfigObject } from '../../Types'

type Props = { formHook: UseFormReturn; data: SliderRangeFormConfigObject }

export const FormSliderRange = ({ data, formHook }: Props) => {
  const { control } = formHook
  const defaultMin = data.options[0]
  const defaultMax = data.options[1]

  return (
    <Controller
      name={data.id}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div className="mt-3">
          <div className="d-flex">
            <FormLabel label={data.label}></FormLabel>
            <span className="alpha-80 ms-1 p-small">
              {value ? value[0] : defaultMin}-{value ? value[1] : defaultMax}
            </span>
          </div>
          <Slider range defaultValue={[defaultMin, defaultMax]} onChange={onChange} value={value} />
        </div>
      )}
    />
  )
}
