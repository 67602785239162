/**
 * Created by @author @Søren Tramm on 08/08/2023.
 * @Description Create a list of options. Includes functionality for adding/removing options
 */

import { Input } from 'antd'
import { useEffect, useState } from 'react'
import { FormLabel } from './FormLabel'
import { UseFormReturn } from 'react-hook-form'
import { DynamicOptionsFormConfigObject } from '../../Types'
import Button from 'antd/lib/button'

type Props = { formHook: UseFormReturn; data: DynamicOptionsFormConfigObject }

export const FormDynamicOptions = ({ data, formHook }: Props) => {
  const {
    register,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = formHook

  const errorState = errors[data.id]
  const errorMessage = errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)

  const [inputs, setInputs] = useState<string[]>([])

  const currentValue = getValues(data.id) ? getValues(data.id) : data.value

  useEffect(() => {
    if (!!currentValue) {
      setInputs(currentValue)
    }
  }, [currentValue])

  useEffect(() => {
    register(data.id, data.validation)

    return () => {
      unregister(data.id)
    }
  }, [register, unregister, data.id, data.validation])

  useEffect(() => {
    if (data.value) {
      setValue(data.id, data.value)
    }
  }, [data.value, data.id, data.validation, register, setValue])

  const onChange = (index: number, value: string) => {
    const updatedOptions = [...inputs]
    updatedOptions[index] = value
    setInputs(updatedOptions)
    setValue(data.id, updatedOptions)
  }

  const addOption = () => {
    const newOption: string = ''
    const updatedOptions = [...inputs, newOption]
    setInputs(updatedOptions)
    setValue(data.id, updatedOptions)
  }

  const removeOption = (index: number) => {
    const updatedOptions = inputs.filter((_, i) => i !== index)
    setInputs(updatedOptions)
    setValue(data.id, updatedOptions)
  }

  return (
    <div className="col-12 w-100 py-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>

      {inputs.map((option, index) => (
        <div className="mt-3 row" key={index}>
          <div className="col-11">
            <Input
              placeholder={data.placeholder}
              value={option}
              onChange={(e) => onChange(index, e.target.value)}
              status={errorState ? 'error' : ''}
            />
          </div>
          <div className="col-1">
            <Button key={index} shape="circle" onClick={() => removeOption(index)}>
              -
            </Button>
          </div>
        </div>
      ))}
      <div className="mt-3 row">
        <div className="col-12">
          <Button shape="circle" onClick={addOption}>
            +
          </Button>
        </div>
      </div>
      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
