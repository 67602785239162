/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 10/12/2021.
 */
import React, { useState } from 'react'
import { authStore } from '../../store/auth/authStore'
import { Auth } from 'aws-amplify'
import { Link, useNavigate } from 'react-router-dom'
import { LOGIN_FORGOT_PASSWORD, LOGIN_MFA_CODE } from '../../constants'

export const CompleteNewPassword = () => {
  const user = authStore((state) => state.user)
  const [passwordOne, setPasswordOne] = useState('')
  const [passwordTwo, setPasswordTwo] = useState('')
  const init: any = authStore((state) => state.init)
  const navigate = useNavigate()
  const [error, setError] = useState('')

  const handleFormSubmit = (event) => {
    event.preventDefault()

    Auth.completeNewPassword(
      user, // the Cognito User Object
      passwordOne // the new password
    )
      .then((user) => {
        // at this time the user is logged in if no MFA required
        if (!user.challengeName) {
          init().then((result) => {
            navigate('/', { replace: true })
          })
        }
        if (user.challengeName === 'SMS_MFA') {
          navigate(LOGIN_MFA_CODE.to, { replace: true })
          return
        }

        console.log(user)
      })
      .catch((e) => {
        setError(e.message)
        console.log(e)
      })
  }

  return (
    <div>
      <h3 className="font-weight-bold text-center">Enter a new password </h3>
      <form className="login-page" onSubmit={handleFormSubmit} style={{ width: 360 }}>
        <div className="w-100 ">
          <label htmlFor="username">Password</label>
          <input
            id="new-password"
            type="password"
            placeholder="New password"
            autoComplete="new-password"
            value={passwordOne}
            name="passwordOne"
            onChange={(e) => setPasswordOne(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />
        </div>
        <div>
          <label htmlFor="password">Repeat Password</label>
          <input
            id="new-password"
            type="password"
            autoComplete="new-password"
            placeholder="Repeat password"
            value={passwordTwo}
            name="passwordTwo"
            onChange={(e) => setPasswordTwo(e.target.value)}
            style={{ borderRadius: 4, height: 48 }}
          />
          <div className=" ">
            <input
              style={{ background: 'black', cursor: 'pointer', color: 'white', borderRadius: 4, height: 48 }}
              type="submit"
              value="Login"
            />
            <p className="mt-1 text-center text-danger">{error}</p>
          </div>
          <div className="d-flex justify-content-center ">
            <Link to={LOGIN_FORGOT_PASSWORD.to}>Request new code</Link>
          </div>
        </div>
      </form>
    </div>
  )
}
