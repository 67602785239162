import { getReq } from '../service/API'
import useSWR from 'swr'

export const useByEndpoint = (p) => {
  const { data, error, mutate } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}

/*
export const useProjectAndPopulate = (projectId: string) => {
  const endpoint = projectId ? getProjectSettings_endpoint(projectId) : null
  const { data, error } = useSWR(endpoint, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const useProjectGetFiles = (projectId: string) => {
  const endpoint = projectId ? getFiles_endpoint(projectId) : null
  const { data, error } = useSWR(endpoint, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
*/
