import React, { Suspense } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { useSite } from '../../hooks/useSite'
import { Button } from 'antd'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { ProjectDrawer } from '../epro-project/ProjectDrawer'
import { SiteCRUD } from './SiteCRUD'
import { GroupHeader } from '../../components/display/GroupHeader'
import { SiteListItem } from './SiteLisItem'
import { AuditViewListTargetSite } from '../audit/AuditViewListTargetSite'

export const SitePage = () => {
  const { projectId } = useParams() as { projectId: string }

  const { data, mutate } = useSite(projectId)

  const handleCreateClick = () => {}

  const closeDrawer = (value) => {
    console.log(' PhasePage > CLOSES = ')
    if (!value) {
      mutate()
    }
  }

  return (
    <>
      <Routes>
        <Route
          path="/create"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer projectId={projectId} setVisible={closeDrawer} to="../" title="Create a new Site">
                <SiteCRUD closeDrawer={closeDrawer} viewType="CREATE"></SiteCRUD>
              </ProjectDrawer>
            </Suspense>
          }
        />

        <Route
          path="/:siteId/*"
          element={
            <ProjectDrawer projectId={projectId} setVisible={closeDrawer} to="../" title="View Site">
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <SiteCRUD closeDrawer={closeDrawer} viewType="VIEW"></SiteCRUD>
                <Routes>
                  <Route
                    path="/edit"
                    element={
                      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                        <ProjectDrawer projectId={projectId} width="30%" to="../" title="Edit site">
                          <SiteCRUD closeDrawer={closeDrawer} viewType="UPDATE"></SiteCRUD>
                        </ProjectDrawer>
                      </Suspense>
                    }
                  />
                </Routes>
              </Suspense>
            </ProjectDrawer>
          }
        />
        <Route
          path="/:siteId/audit-trail/*"
          element={
            <ProjectDrawer projectId={projectId} width="40%" to="../" title="View audit trail">
              <AuditViewListTargetSite closeDrawer={closeDrawer} />
            </ProjectDrawer>
          }
        />
      </Routes>

      <div className="mt-4">
        <h4>Clinical trial sites for your project.</h4>
        <p className="p-small">You can click 'View/Edit' sites to access and work with individual site information.</p>
      </div>
      <div className="row mt-4 group-item" style={{}}>
        <GroupHeader
          label="Site List"
          header={
            <Link to={'./create'}>
              <Button type="primary" onClick={handleCreateClick}>
                Create new site
              </Button>
            </Link>
          }
        >
          <>
            {data.map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-12 py-3 border-bottom d-flex justify-content-between align-items-center"
                >
                  <SiteListItem key={index} item={item} to={'./' + item._id}></SiteListItem>
                </div>
              )
            })}
          </>
        </GroupHeader>
      </div>
    </>
  )
}
