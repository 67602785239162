import { ADMIN_API_URL, BASE_URL } from '../constants'

/**
 * @description Define endpoints for the API
 */
export const ENDPOINTS = {
  //
  // ------------------------------------------------------
  // Audits
  // ------------------------------------------------------
  //
  AUDIT: {
    byAuthorSub: (projectId: string, authorSub: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/audits/author/${authorSub}`
    },
    byAuditId: (projectId: string, auditId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/audits/${auditId}`
    },
    adminLoginsById: (projectId: string, adminId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/audits/logins/admin/${adminId}`
    },
    trialStaffLoginsById: (projectId: string, userId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/audits/logins/trialstaff/${userId}`
    },
    clinicianLoginsById: (projectId: string, userId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/audits/logins/clinician/${userId}`
    },
    byTarget: (projectId: string, targetId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/audits/target/${targetId}`
    },
    protocolByProject: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/audits/protocol`
    },
  },
  //
  // ------------------------------------------------------
  // Admin users
  // ------------------------------------------------------
  //
  ADMINISTRATORS: {
    GET_USER: ADMIN_API_URL + '/adminUser/',
    GET_BY_ID: ADMIN_API_URL + '/adminUser/byId/',
    CREATE: ADMIN_API_URL + '/adminUser/create',
    GET_ALL: ADMIN_API_URL + '/adminUser/all',
    LOG_OUT: ADMIN_API_URL + '/adminUser/logout',
  },
  //
  // ------------------------------------------------------
  // Clinicians
  // ------------------------------------------------------
  //
  CLINICIANS: {
    byProjectId: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/clinicians`
    },
    byClinicianId: (projectId: string, clinicianId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/clinicians/` + clinicianId
    },
    ACTION: {
      clinician: (projectId: string) => {
        return ADMIN_API_URL + `/projects/${projectId}/clinicians/action`
      },
      updatePii: (projectId: string) => {
        return ADMIN_API_URL + `/projects/${projectId}/clinicians/action/pii`
      },
      updateSites: (projectId: string) => {
        return ADMIN_API_URL + `/projects/${projectId}/clinicians/action/sites`
      },
    },
    reactivate: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/clinicians/action/reactivate`
    },
    retire: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/clinicians/action/retire`
    },
    // retire: (projectId: string, clinicianId: string) => {
    //   return ADMIN_API_URL + `/projects/${projectId}/clinicians/${clinicianId}/retire`
    // },
    invite: (projectId: string, clinicianId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/clinicians/${clinicianId}/send`
    },
    resend: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/clinicians/action/resend`
    },
  },
  //
  // ------------------------------------------------------
  // Trial Staff
  // ------------------------------------------------------
  //
  TRIALSTAFF: {
    ACTION: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/trialStaff/action`
    },
    byProjectId: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/trialStaff`
    },
    byStaffId: (projectId: string, triallStaffId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/trialStaff/` + triallStaffId
    },
    reactivate: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/trialStaff/action/reactivate`
    },
    retire: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/trialStaff/action/retire`
    },
    invite: (projectId: string, trialStaffId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/trialStaff/${trialStaffId}/send`
    },
    resend: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/trialStaff/action/resend`
    },
    updatePii: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/trialStaff/action/pii`
    },
  },
  //
  // ------------------------------------------------------
  // Phases
  // ------------------------------------------------------
  //
  PHASES: {
    CONFIG: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/phases/config`
    },
    ACTION: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/phases/action`
    },
    byProjectId: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/phases`
    },
    byPhaseId: (projectId: string, phaseId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/phases/` + phaseId
    },
    delete: (projectId: string, phaseId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/phases/${phaseId}/delete`
    },
  },
  //
  // ------------------------------------------------------
  // Projects
  // ------------------------------------------------------
  //
  PROJECTS: {
    CONFIG: () => {
      return ADMIN_API_URL + `/projects/config`
    },
    STATUS: {
      get: (projectId: string) => {
        return ADMIN_API_URL + `/projects/${projectId}/status`
      },
      update: (projectId: String) => {
        return ADMIN_API_URL + `/projects/${projectId}/updateStatus`
      },
    },
    create: ADMIN_API_URL + `/projects/create`,
    update: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/update`
    },
    byProjectId: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}`
    },
    all: () => {
      return ADMIN_API_URL + `/projects/`
    },
    delete: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/delete`
    },
  },
  //
  // ------------------------------------------------------
  // Protocols
  // ------------------------------------------------------
  //
  PROTOCOLS: {
    ACTIONS: {
      ACTIVATE: (projectId) => {
        return ADMIN_API_URL + `/projects/${projectId}/protocols/action/activate`
      },
      CLONE: (projectId) => {
        return ADMIN_API_URL + `/projects/${projectId}/protocols/action/clone`
      },
      CLONE_PHASE: (projectId: string) => {
        return ADMIN_API_URL + `/projects/${projectId}/protocols/action/clone-phase`
      },
      CLONE_QUESTIONNAIRE: (projectId: string) => {
        return ADMIN_API_URL + `/projects/${projectId}/protocols/action/clone-questionnaire`
      },
      UPDATE: (projectId) => {
        return ADMIN_API_URL + `/projects/${projectId}/protocols/action/update`
      },
    },

    // SAVE: BASE_URL + '/api/admin/protocol/save',
    // CREATE: BASE_URL + '/api/admin/protocol/create',
    // UPDATE: BASE_URL + '/api/admin/protocol/update',

    GET_ACTIVE_DRAFT: (projectId) => {
      return ADMIN_API_URL + `/projects/${projectId}/protocols/activeDraft`
    },

    // @Soren please look at this
    GET_ALL: (projectId) => {
      return ADMIN_API_URL + `/projects/${projectId}/protocols/`
    },
    GET_ARCHIVED: (projectId) => {
      return ADMIN_API_URL + `/projects/${projectId}/protocols/archived`
    },
    GET_ONE: (projectId, protocolId) => {
      return ADMIN_API_URL + `/projects/${projectId}/protocols/getById/${protocolId}`
    },
    //GET_ONE: BASE_URL + '/api/admin/protocol/getById/',
    QUESTIONNAIRE_BELONGS_TO: BASE_URL + '/api/admin/questionnaire/belongsto/',
  },
  //
  // ------------------------------------------------------
  // Questionnaires
  // ------------------------------------------------------
  //
  QUESTIONNAIRES: {
    ACTIVATION: (projectId: string, questionnaireId: String) => {
      return ADMIN_API_URL + `/projects/${projectId}/questionnaires/${questionnaireId}/activation`
    },
    byQuestionnaireId: (projectId: string, questionnaireId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/questionnaires/` + questionnaireId
    },
    byProjectId: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/questionnaires/`
    },
    assign: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/questionnaires/action/assign`
    },
    ACTION: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/questionnaires/action`
    },
    BELONGS_TO: (projectId: string, belongsToId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/questionnaires/belongsto/${belongsToId}`
    },
    ACTIONS: {
      delete: (projectId: string, questionnaireId: string) => {
        return ADMIN_API_URL + `/projects/${projectId}/questionnaires/action/${questionnaireId}/delete/`
      },
    },
    // clone: (projectId: string) => {
    //   return ADMIN_API_URL + `/projects/${projectId}/questionnaires/clone`
    // },
  },
  //
  // ------------------------------------------------------
  // Questions
  // ------------------------------------------------------
  //
  QUESTIONS: {
    delete: (projectId: string, questionId: String) => {
      return ADMIN_API_URL + `/projects/${projectId}/questions/${questionId}/delete`
    },
    create: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/questions/create`
    },
    update: (projectId: string, questionId: String) => {
      return ADMIN_API_URL + `/projects/${projectId}/questions/${questionId}/update`
    },
    order: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/questions/order`
    },
    byQuestionId: (projectId: string, questionId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/questions/` + questionId
    },
    byQuestionnaireId: (projectId: string, questionnaireId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/questionnaires/${questionnaireId}/questions`
    },
  },
  //
  // ------------------------------------------------------
  // Sites
  // ------------------------------------------------------
  //
  SITES: {
    byProjectId: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/sites/`
    },
    ACTION: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/sites/action`
    },
    delete: (projectId: string, siteId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/sites/${siteId}/delete`
    },
    byIdLocalTime: (projectId: string, siteId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/sites/${siteId}/localTime`
    },
    bySiteId: (projectId: string, siteId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/sites/` + siteId
    },
  },

  SUBJECT: {
    CREATE: BASE_URL + '/api/subject',
    ME: BASE_URL + '/api/me',
    GET_ONE: BASE_URL + '/api/subject/byUserId/',
    GET_ALL: BASE_URL + '/api/subject/',
    SEND_INVITE: BASE_URL + '/api/subject/sendInvite',
  },
  //
  // ------------------------------------------------------
  // Visits
  // ------------------------------------------------------
  //
  VISITS: {
    delete: (projectId: string, visitId: String) => {
      return ADMIN_API_URL + `/projects/${projectId}/visits/${visitId}/delete`
    },
    byVisitId: (projectId: string, visitId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/visits/` + visitId
    },
    byProjectId: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/visits/`
    },
    ACTION: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/visits/action`
    },
    reoder: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/visits/action/reorder`
    },
    byPhaseId: (projectId: string, phaseId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/visits/byphase/` + phaseId
    },
  },

  DEBUG: {
    updateProjectState: (projectId: string) => {
      return ADMIN_API_URL + `/projects/${projectId}/debug/update-state`
    },
  },
}
