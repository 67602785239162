/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 03-08-2020.
 */
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import './spinner.scss'
import { Spin } from 'antd'

const Spinner = ({ size = 6, style = {}, type = 'BUBBLE' }) => {
  //const { error } = props
  //const statusTxt = !error ? 'Getting data...' : error

  return type === 'BUBBLE' ? <BobleIcon size={size} style={style} /> : <SpinIcon />
}

const SpinIcon = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  return <Spin indicator={antIcon} />
}

const BobleIcon = ({ size, style }) => {
  return <div className="loader" style={{ fontSize: size, ...style }}></div>
}

export default Spinner
