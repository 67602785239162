import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'

export const useClinicianById = (projectId: string, clinicianId: string) => {
  const p = clinicianId ? ENDPOINTS.CLINICIANS.byClinicianId(projectId, clinicianId) : null

  const { data, error, mutate } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
