/**
 * Created by @author @Søren Tramm on 08/08/2023.
 * @Description Adjusted copy of FormTextField
 */

import { Input } from 'antd'
import { useEffect } from 'react'
import { FormLabel } from './FormLabel'
import { UseFormReturn } from 'react-hook-form'
import { TextAreaFormConfigObject } from '../../Types'

type Props = { formHook: UseFormReturn; data: TextAreaFormConfigObject }

const { TextArea } = Input

export const FormTextArea = ({ data, formHook }: Props) => {
  const {
    register,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = formHook

  const errorState = errors[data.id]
  const errorMessage = errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)

  useEffect(() => {
    register(data.id, data.validation)

    return () => {
      unregister(data.id)
    }
  }, [register, unregister, data.id, data.validation])

  useEffect(() => {
    if (data.value) {
      setValue(data.id, data.value)
    }
  }, [data.value, data.id, data.validation, register, setValue])

  const onChange = (value) => {
    setValue(data.id, value.target.value)
  }

  const currentValue = getValues(data.id) ? getValues(data.id) : data.value

  return (
    <div className="col-12 w-100 py-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>

      <TextArea
        rows={4}
        placeholder={data.placeholder}
        onChange={onChange}
        defaultValue={currentValue}
        status={errorState ? 'error' : ''}
      />
      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
