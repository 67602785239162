import { Button, Modal } from 'antd'
import { useState } from 'react'
import { useIdleTimeout } from '../../util/useIdleTimeout'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { authStore } from '../../store/auth/authStore'

export const TimeoutModal = () => {
  const [openModal, setOpenModal] = useState(false)
  const logout = authStore((state) => state.logout)

  const handleIdle = () => {
    setOpenModal(true)
  }

  const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 1800 })

  const handleLogout = () => {
    logout()
    setOpenModal(false)
  }

  const stay = () => {
    setOpenModal(false)
    idleTimer.reset()
  }

  const activityModal = (
    <Modal
      open={openModal}
      title={[
        <ExclamationCircleOutlined className="me-2" style={{ fontSize: '20px', color: 'orange' }} />,
        'Your session is about to expire. Do you want to stay signed in?',
      ]}
      width={550}
      onOk={stay}
      onCancel={handleLogout}
      destroyOnClose
      footer={[
        <Button key="cancelButton" onClick={handleLogout}>
          Log out
        </Button>,
        <Button type="primary" key="okButton" onClick={stay}>
          Stay signed in
        </Button>,
      ]}
    ></Modal>
  )

  return <> {activityModal}</>
}
