/**
 * Created by @author Søren Tramm on 08/08/2023.
 */
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Popconfirm, notification } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, SmileOutlined } from '@ant-design/icons/lib/icons'
import { ReactComponent as DragHandle } from '../../../assets/drag.svg'
import { getReq } from '../../../service/API'
import { ENDPOINTS } from '../../../service/ENDPOINTS'

type Props = { item: any; to?: string; provided: any; mutate: any; defaultLanguage: string }

export const QuestionListItem = ({ item, to = './', provided, mutate, defaultLanguage }: Props) => {
  const { projectId } = useParams() as { projectId: string }

  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const cancel = () => {
    setOpen(false)
    setConfirmLoading(false)
  }

  const showPopconfirm = () => {
    setOpen(true)
  }

  const deleteQuestionConfirm = (item) => {
    setConfirmLoading(true)

    return getReq(ENDPOINTS.QUESTIONS.delete(projectId, item._id))
      .then(() => {
        setTimeout(() => {
          mutate()
          setOpen(false)
          setConfirmLoading(false)
        }, 1500)
      })
      .catch((error) => {
        openNotification('Deletion failed', error.message, 'sad')
        setOpen(false)
        setConfirmLoading(false)
        console.log('error = ', error)
      })
  }

  const openNotification = (
    msg: string = 'Created',
    description: string = 'lorem ipsum...',
    smiley: 'happy' | 'sad' | '' = ''
  ) => {
    notification.open({
      duration: 3.5,
      message: msg,
      description: description,
      icon: smiley === 'happy' ? <SmileOutlined style={{ color: '#108ee9' }} /> : null,
    })
  }

  return (
    <div
      className="row pb-2 bg-white mt-2"
      style={{ border: '1px solid ', borderColor: '#CFD3D7 ', boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.08)' }}
    >
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between mt-2" {...provided.dragHandleProps}>
              <div className="w-25 d-flex align-items-center">
                <p className="mb-0 fw-bold text-primary text-capitalize text-center">
                  {item.next && item?.next.length > 0 ? (
                    <CheckCircleOutlined style={{ color: '#198754' }} />
                  ) : (
                    <CloseCircleOutlined style={{ color: 'red' }} />
                  )}
                </p>
                <p className="mb-0 p-mini alpha-80 ms-1 text-center">Has next</p>
              </div>

              <div className="w-50 d-flex justify-content-center ">
                <DragHandle className="" style={{ width: 28, height: 14 }}></DragHandle>
              </div>

              <p className="p-mini opacity-50 w-25">{item._id}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-5 pt-3 pb-2">
            <p className="mb-0 p-mini alpha-80 fw-bold">Title </p>
            <p className="me-2 fw-bold">{typeof item.title === 'object' ? item.title[defaultLanguage] : item.title}</p>
          </div>
          <div className="col-3 pt-3 pb-2">
            <p className="mb-0 p-mini alpha-80">Type</p>
            <p className="mb-0 fw-bold text-primary text-capitalize">{item.type ? item.type.toLowerCase() : '-'}</p>
          </div>

          <div className="col-4 justify-content-end align-items-center d-flex pt-3 pb-2">
            <Link className="me-2" to={to + 'update/' + item._id}>
              <Button>Edit</Button>
            </Link>
            <Popconfirm
              title={'Delete question: ' + item.title[defaultLanguage]}
              description="Are you sure you want to delete this question?"
              open={open}
              okButtonProps={{ loading: confirmLoading }}
              onConfirm={() => deleteQuestionConfirm(item)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button danger onClick={showPopconfirm}>
                Delete
              </Button>
            </Popconfirm>
          </div>
        </div>
      </div>
    </div>
  )
}
