import { Slider } from 'antd'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { FormLabel } from './FormLabel'
import { SliderFormConfigObject } from '../../Types'

type Props = { formHook: UseFormReturn; data: SliderFormConfigObject }

export const FormSlider = ({ data, formHook }: Props) => {
  const { control } = formHook

  const calculateDisplayValue = (v) => {
    if (data?.displayFactor) {
      return Math.round(v / 1000)
    }
    return v
  }

  return (
    <Controller
      name={data.id}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div className="mt-3 mb-5 ">
          <div className="d-flex">
            <FormLabel label={data.label}></FormLabel>
            <span className="alpha-80 ms-1 p-small">{calculateDisplayValue(value)}</span>
          </div>

          <Slider
            tipFormatter={(v) => {
              return calculateDisplayValue(v) + ' km'
            }}
            min={data.min}
            max={data.max}
            onChange={onChange}
            value={value ? value : Math.round(data.max / 2)}
          />
        </div>
      )}
    />
  )
}
