/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/03/2023.
 */
import React from 'react'
import { Link, useMatch } from 'react-router-dom'
import { Button } from 'antd'
import { LanguageISOCode } from '../../Types'

type Props = { item: any; to?: string; language: LanguageISOCode }

export const QuestionnaireListItem = ({ item, to = './', language }: Props) => {
  const isQuestionnairePath = useMatch('/project/:projectId/questionnaire/*') !== null
  const handleCreateClick = () => {}

  const languages = Object.keys(item?.name || {})

  return (
    <div className="col-12 px-2 mb-2">
      <div
        className="row  pb-2 bg-white"
        style={{ border: '1px solid ', borderColor: '#CFD3D7 ', boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.08)' }}
      >
        <div className="col-12">
          <div className="w-100 pt-3 pb-2 px-2 d-flex ">
            <div className=" " style={{ width: '25%' }}>
              <p className="mb-0 p-mini alpha-80 fw-bold">Internal Title</p>
              <p className="me-2">{item?.internalTitle}</p>
            </div>

            <div className="" style={{ width: '15%' }}>
              <p className="mb-0 p-mini alpha-80">Languages</p>
              <p className="mb-0 fw-bold text-primary text-capitalize">{languages.join(',')}</p>
            </div>

            <div className="" style={{ width: '20%' }}>
              <div className="">
                <p className="mb-0 p-mini alpha-80">Recurrence</p>
                <p className="mb-0 fw-bold text-primary ">{item?.recurrence?.type || 'Not set'}</p>
              </div>
            </div>

            <div className=" " style={{ width: '20%' }}>
              <p className="mb-0 p-mini alpha-80 fw-bold">Status</p>
              <p className="mb-0 fw-bold text-primary text-capitalize">{item?.status}</p>
            </div>

            <div className="d-flex justify-content-end align-items-center " style={{ width: '20%' }}>
              <Link to={to}>
                <Button type="default">View</Button>
              </Link>
              {isQuestionnairePath && (
                <div className="me-4">
                  <Link to={to + '/manage-questions'}>
                    <Button onClick={handleCreateClick}>Manage questions</Button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
