/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01/10/2021.
 */
import React, { Suspense } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { COLOR_PRIMARY, MANUAL_VERSION, VERSION } from '../../constants'
import { authStore } from '../../store/auth/authStore'
import { Button, Popover } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { HeaderProjectSelect } from './HeaderProjectSelect'
import { PROJECT_PAGES } from '../../pages'
import { TimeoutModal } from './TimeoutModal'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { post } from '../../service/API'

type Props = {
  showSub?: boolean
}
export const Header = ({ showSub = false }: Props) => {
  let navStyle = {
    color: 'rgba(0,0,0,.5)',
    fontSize: '.9rem',
    borderBottom: '2px solid white',
  }

  let activeStyle = {
    ...navStyle,
    color: 'black',
    borderBottom: '2px solid ' + COLOR_PRIMARY,
  }

  /*const dashBoardLink =
		REACT_APP_ENV === 'staging'
			? 'https://recruitment-dashboard-staging.studiesandme.com/'
			: 'https://recruitment-dashboard.studiesandme.com/'*/

  return (
    <div className="container-fluid bg-white" style={{ boxShadow: '0px -5px 10px 7px rgba(0, 0, 0, 0.05)' }}>
      <TimeoutModal />

      {/*{REACT_APP_ENV !== 'production' ? (
        <div className="row bg-primary" style={{}}>
          <div className="col-12 d-flex justify-content-center">
            <p className="py-2 p-small text-white">{REACT_APP_ENV} environment</p>
          </div>
        </div>
      ) : null}*/}
      <div className="row " style={{}}>
        <div className="col-12 col-md-3 pb-0 d-flex justify-content-md-start align-items-center">
          <Link to="/" className="mb-0 text-decoration-none">
            <h6 className="mb-0 fw-bold text-black">Epro Admin</h6>
          </Link>
          <Suspense fallback="..">
            <HeaderProjectSelect></HeaderProjectSelect>
          </Suspense>
        </div>

        <div
          className="col-12 col-md-6 d-flex justify-content-center align-items-center"
          style={{
            minHeight: 56,
          }}
        >
          {showSub &&
            PROJECT_PAGES.map((item, index) => {
              return (
                <NavLink
                  to={item.to}
                  key={index}
                  className="mx-4 text-decoration-none py-3"
                  style={({ isActive }) => {
                    return isActive ? activeStyle : navStyle
                  }}
                >
                  <p className="font-weight-bold" style={{ whiteSpace: 'nowrap' }}>
                    {item.title}
                  </p>
                </NavLink>
              )
            })}
        </div>

        <div className="col-12 col-md-3 d-flex justify-content-end align-items-center">
          <SettingsMenu></SettingsMenu>
        </div>
      </div>
    </div>
  )
}

const SettingsMenu = () => {
  const user = authStore((state) => state.user)
  const logoutFunc = authStore((state) => state.logout)
  console.log('Header > user?.attributes = ')
  const name = user?.attributes.email || ''
  const letter = user?.attributes.email ? user?.attributes.email[0] : ''

  const logout = () => {
    return post(ENDPOINTS.ADMINISTRATORS.LOG_OUT, {})
      .then((result) => {
        console.log('Header > LOG_OUT = ', result)
        logoutFunc()
      })
      .catch((error) => {
        alert(error.toString())
      })
  }

  const content = (
    <div className="px-2 ">
      <div className="mb-2" style={{ maxWidth: 300 }}>
        <p className="p-mini fw-bold text-black">EMAIL</p>
        <p className="text-truncate">{name}</p>

        <div className="bg-black mt-2" style={{ height: 1, opacity: 0.1 }}></div>
        <p className="mt-2 p-mini fw-bold text-black">Version</p>
        <p className="text-truncate">{MANUAL_VERSION}</p>
        <p className="mt-2 p-mini fw-bold text-black">Build</p>
        <p className="text-truncate">{VERSION}</p>
      </div>

      <Button onClick={logout} type="default" className="mt-2 w-100">
        <p className="fw-bold">Logout</p>
      </Button>
    </div>
  )

  return (
    <Popover placement="bottomRight" content={content}>
      <div className="d-flex pointer noselect">
        <div
          className="me-1 bg-primary rounded-circle p-2 d-flex justify-content-center align-items-center"
          style={{ width: 35, height: 35 }}
        >
          <p className="fw-bold text-white">{letter.toUpperCase()}</p>
        </div>
        <DownOutlined className="mt-1 opacity-50"></DownOutlined>
      </div>
    </Popover>
  )
}
