/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/12/2021.
 */
import { Suspense } from 'react'

import { Header } from './components/header/Header'
import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
import { PROJECT_PAGE, PROJECT_PAGE_CREATE, PROJECT_PAGE_VIEW } from './constants'

import { EproProjectView } from './pages/epro-project/EproProjectView'
import { EproProjectListPage } from './pages/epro-project/EproProjectListPage'
import { Navigate, Route, Routes } from 'react-router-dom'
import { EproCreateProject } from './pages/epro-project/EproCreateProject'

export const Main = () => {
  return (
    <>
      <Routes>
        <Route
          path={PROJECT_PAGE_CREATE.path}
          element={
            <>
              <Header></Header>
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <EproCreateProject></EproCreateProject>
              </Suspense>
            </>
          }
        />

        <Route
          path="/project"
          element={
            <>
              <Header></Header>
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <EproProjectListPage></EproProjectListPage>
              </Suspense>
            </>
          }
        />

        <Route
          path={PROJECT_PAGE_VIEW.path}
          element={
            <>
              <Header showSub={true}></Header>

              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <EproProjectView></EproProjectView>
              </Suspense>
            </>
          }
        />

        <Route path="*" element={<Navigate replace to={PROJECT_PAGE.path} />} />
      </Routes>
    </>
  )
}
