import notification from 'antd/lib/notification'
import { SmileOutlined } from '@ant-design/icons'
export const openNotification = (
  msg: string = 'headline',
  description: string = 'description...',
  smiley: 'happy' | 'sad' | '' = ''
) => {
  notification.open({
    duration: 4,
    message: <p className="fw-bold">{msg}</p>,
    description: description,
    icon: smiley === 'happy' ? <SmileOutlined style={{ color: '#108ee9' }} /> : null,
  })
}
