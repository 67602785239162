import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'
import { PERSONNEL_TYPE_ENUM } from '../Types'

export const usePersonnelById = (type: PERSONNEL_TYPE_ENUM, projectId: string, userId: string) => {
  let p

  if (type === PERSONNEL_TYPE_ENUM.TRIAL_STAFF) {
    p = ENDPOINTS.TRIALSTAFF.byStaffId(projectId, userId)
  } else {
    p = ENDPOINTS.CLINICIANS.byClinicianId(projectId, userId)
  }

  const { data, error, mutate } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
