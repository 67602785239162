import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'
// Stoyan
export const useSite = (projectId: string) => {
  const p = ENDPOINTS.SITES.byProjectId(projectId)
  const { data, mutate, error } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
