import { useParams } from 'react-router-dom'
import { VIEW_TYPE } from '../../Types'
import { useAudits } from '../../hooks/useAudits'
import React from 'react'
import { AuditListItem } from './AuditListItem'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

export const AuditViewList = ({ onClose, closeDrawer, viewType }: Props) => {
  const { projectId, authorId } = useParams() as { projectId: string; authorId: string }
  const { data } = useAudits(projectId, authorId)

  return (
    <>
      <div className="">
        {data && data.length > 0 ? (
          data.map((item, index) => {
            return <AuditListItem item={item} to="detail/" key={index} />
          })
        ) : (
          <p>No audit data exists</p>
        )}
      </div>
      <div className="col-12">
        <hr />
      </div>
    </>
  )
}
