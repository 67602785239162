import { Alert, Button } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { getReq } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'

type DeleteProjectProps = {
  onClose?: () => void
  closeDrawer?: null | ((bool: boolean) => void)
}

export const DeleteProject = ({ closeDrawer, onClose }: DeleteProjectProps) => {
  const { projectId } = useParams() as { projectId: string }

  const [errorState, setErrorState] = useState<any>()

  const deleteClick = () => {
    getReq(ENDPOINTS.PROJECTS.delete(projectId))
      .then((result) => {
        if (onClose) {
          onClose()
        }
        if (closeDrawer) {
          closeDrawer(false)
        }

        return result
      })
      .catch((error) => {
        setErrorState(error)
      })
  }

  return (
    <div className="container-fluid">
      <div className="row" style={{}}>
        <div className="col-12 mb-2">
          <h5>You are about to delete a project with all Phases, Visits, Trial staff, Sites and Questionnaires.</h5>
        </div>

        <Button type="primary" onClick={deleteClick} danger>
          Delete project
        </Button>

        {errorState ? (
          <Alert className="mt-4" showIcon message={errorState.type} description={errorState.message} type="error" />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
