import { DatePicker } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { FormLabel } from './FormLabel'
import { UseFormReturn } from 'react-hook-form'
import { DateFormConfigObject } from '../../Types'
import { z } from 'zod'
import { convert2Date, StoreDateRangeType, StoreDateTimeType } from '../../util/datetime'

type Props = { formHook: UseFormReturn; data: DateFormConfigObject }
const { RangePicker } = DatePicker

type StoreWindowType = StoreDateRangeType & {
  duration: number
}
const storageWindowTypeZodSchema = z.object({
  startAt: z.object({ y: z.number(), M: z.number(), D: z.number() }),
  endAt: z.object({ y: z.number(), M: z.number(), D: z.number() }),
  duration: z.number(),
})

export const FormDateRange = ({ data, formHook }: Props) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = formHook

  const currentValue = useMemo(() => {
    const value = getValues(data.id) ? getValues(data.id) : data.value
    const validationResult = storageWindowTypeZodSchema.safeParse(value)
    if (validationResult.success) {
      return value
    } else {
      console.warn('Validation of date format failed', validationResult)
      return null
    }
  }, [data.id, data.value, getValues])

  const format = data.format ? data.format : 'YYYY-MM-DD'

  const errorState = errors[data.id]
  const errorMessage: string =
    errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)

  useEffect(() => {
    register(data.id, data.validation)
    if (currentValue) {
      setValue(data.id, currentValue)
    }
  }, [setValue, register, data.id, data.validation, currentValue])

  const handleChange = (dateRange) => {
    const duration = dateRange[1].diff(dateRange[0], 'day')
    const window: StoreWindowType = { ...convert2Object(dateRange)!, duration }
    setValue(data.id, window)
  }

  const convert2Object = (datesArray: any): StoreDateRangeType | undefined => {
    if (datesArray === null) return

    const startAt: StoreDateTimeType = {
      y: datesArray[0].year(),
      M: datesArray[0].month() + 1,
      D: datesArray[0].date(),
    }
    const endAt: StoreDateTimeType = {
      y: datesArray[1].year(),
      M: datesArray[1].month() + 1,
      D: datesArray[1].date(),
    }
    return {
      startAt,
      endAt,
    }
  }

  return (
    <div className={` ${data.className} py-2`}>
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>
      <RangePicker
        className="w-100"
        status={errorState ? 'error' : undefined}
        defaultValue={currentValue && [convert2Date(currentValue.startAt), convert2Date(currentValue.endAt)]}
        format={format}
        onChange={(value) => handleChange(value)}
      />
      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
