/**
 * Created by Søren Tramm 2023.10.06
 */

import React, { Suspense } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { Button } from 'antd'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { ProjectDrawer } from '../epro-project/ProjectDrawer'
import { CRUD_VIEW_TYPE } from '../../Types'
import { useProtocolById } from '../../hooks/useProtocolById'
import { PhaseCRUD } from '../phase/PhaseCRUD'
import { ProtocolQuestionnaireClone } from './ProtocolQuestionnaireClone'
import { QuestionnaireCRUD } from '../questionnaire/QuestionnaireCRUD'
import { ProtocolHeader } from './ProtocolHeader'
import { ProtocolOverviewListItem } from './overview/ProtocolOverviewListItem'
import { ProtocolPhaseClone } from './ProtocolPhaseClone'
import { ProtocolActivate } from './ProtocolActivate'
import { ProtocolCRUD } from './ProtocolCRUD'
import { QuestionManageDrawer } from '../questionnaire/question/QuestionManageDrawer'
import { QuestionCRUD } from '../questionnaire/question/QuestionCRUD'
import { useProject } from '../../hooks/useProject'
import { DeleteQuestionnaire } from '../questionnaire/DeleteQuestionnaire'

export const ProtocolDraftPage = () => {
  const { projectId, protocolId } = useParams() as { projectId: string; protocolId: string }
  // const { mutate: globalMutate } = useSWRConfig()
  const { data: protocolData, mutate } = useProtocolById(projectId, protocolId)
  const { data: projectData } = useProject(projectId)
  const defaultLanguage = projectData.languages[0].isoCode

  const closeDrawer = (value) => {
    if (!value) {
      mutate()
    }
  }

  const deleteHeader = (
    <Link to="delete">
      <Button type="default" danger>
        Delete
      </Button>
    </Link>
  )

  return (
    <>
      <Routes>
        <Route
          path="/view-phase/:phaseId/*"
          element={
            <ProjectDrawer projectId={projectId} width="30%" to="../" title="View phase">
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <PhaseCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.VIEW}></PhaseCRUD>
                <Routes>
                  <Route
                    path="/edit"
                    element={
                      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                        <ProjectDrawer projectId={projectId} width="30%" to="../">
                          <PhaseCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.UPDATE}></PhaseCRUD>
                        </ProjectDrawer>
                      </Suspense>
                    }
                  />
                  {/* <Route
                    path="/delete"
                    element={
                      <ProjectDrawer projectId={projectId} width="30%" to="../" title="Delete phase">
                        <DeletePhase closeDrawer={closeDrawer}></DeletePhase>
                      </ProjectDrawer>
                    }
                  /> */}
                </Routes>
              </Suspense>
            </ProjectDrawer>
          }
        />
        <Route
          path="/:phaseId/questionnaire/:questionnaireId/*"
          element={
            <ProjectDrawer projectId={projectId} width="30%" to="../" title="View questionnaire" header={deleteHeader}>
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <QuestionnaireCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.VIEW}></QuestionnaireCRUD>
                <Routes>
                  <Route
                    path="/edit"
                    element={
                      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                        <ProjectDrawer projectId={projectId} width="30%" to="../" title="Edit questionnaire">
                          <QuestionnaireCRUD
                            closeDrawer={closeDrawer}
                            viewType={CRUD_VIEW_TYPE.UPDATE}
                          ></QuestionnaireCRUD>
                        </ProjectDrawer>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/delete"
                    element={
                      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                        <ProjectDrawer projectId={projectId} width="30%" to="../../">
                          <DeleteQuestionnaire closeDrawer={closeDrawer} />
                        </ProjectDrawer>
                      </Suspense>
                    }
                  />
                </Routes>
              </Suspense>
            </ProjectDrawer>
          }
        />
        <Route
          path="/create-phase"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer projectId={projectId} width="30%" to="../" title="Add phase to protocol">
                <PhaseCRUD closeDrawer={closeDrawer}></PhaseCRUD>
                {/*<ProtocolAddPhase closeDrawer={closeDrawer} viewType="UPDATE" />*/}
              </ProjectDrawer>
            </Suspense>
          }
        />
        <Route
          path="/clone-phase/:phaseId/*"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer projectId={projectId} setVisible={closeDrawer} to="../" title="Clone Phase">
                <ProtocolPhaseClone closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.VIEW} />
              </ProjectDrawer>
            </Suspense>
          }
        />
        <Route
          path="phase/:phaseId/create-questionnaire"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer projectId={projectId} width="30%" to={`../`} title="Add questionnaire">
                <QuestionnaireCRUD></QuestionnaireCRUD>
              </ProjectDrawer>
            </Suspense>
          }
        />

        <Route
          path="/:questionnaireId/clone"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer projectId={projectId} width="30%" to={`../${protocolId}`} title="Clone questionnaire">
                <ProtocolQuestionnaireClone closeDrawer={closeDrawer} />
              </ProjectDrawer>
            </Suspense>
          }
        />

        <Route
          path="phase/:phaseId/questionnaire/:questionnaireId/*"
          element={
            <ProjectDrawer projectId={projectId} setVisible={closeDrawer} to="../">
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <QuestionnaireCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.VIEW}></QuestionnaireCRUD>
                <Routes>
                  <Route
                    path="/edit"
                    element={
                      <ProjectDrawer projectId={projectId} width="30%" to="../">
                        <QuestionnaireCRUD
                          closeDrawer={closeDrawer}
                          viewType={CRUD_VIEW_TYPE.UPDATE}
                        ></QuestionnaireCRUD>
                      </ProjectDrawer>
                    }
                  />
                </Routes>
              </Suspense>
            </ProjectDrawer>
          }
        />
        <Route
          path="/activate"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              {/*
              The redirect after cloninig works - because in the ProtocolWrapper there is a Navigate to
              Which automaticlly redirects to the active protocol
               */}
              <ProjectDrawer projectId={projectId} width="30%" to={`../../`} title="Activate protocol">
                <ProtocolActivate closeDrawer={closeDrawer} />
              </ProjectDrawer>
            </Suspense>
          }
        />

        <Route
          path="/view/*"
          element={
            <ProjectDrawer projectId={projectId} width="30%" to="../" title="View protocol">
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <ProtocolCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.VIEW} />
                <Routes>
                  <Route
                    path="/edit"
                    element={
                      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                        <ProjectDrawer projectId={projectId} width="30%" to="../" title="Edit protocol">
                          <ProtocolCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.UPDATE} />
                        </ProjectDrawer>
                      </Suspense>
                    }
                  />
                </Routes>
              </Suspense>
            </ProjectDrawer>
          }
        />
        <Route
          path="/:phaseId/questionnaire/:questionnaireId/manage-questions/*"
          element={
            <ProjectDrawer projectId={projectId} setVisible={closeDrawer} width={600} to="../" title="Manage Questions">
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <QuestionManageDrawer closeDrawer={closeDrawer}></QuestionManageDrawer>
                <Routes>
                  <Route
                    path="/update/:questionId"
                    element={
                      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                        <ProjectDrawer projectId={projectId} width="30%" to="../" title="Edit question">
                          <QuestionCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.UPDATE} />
                        </ProjectDrawer>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/create"
                    element={
                      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                        <ProjectDrawer projectId={projectId} width="30%" to="../" title="Create question">
                          <QuestionCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.CREATE} />
                        </ProjectDrawer>
                      </Suspense>
                    }
                  />
                </Routes>
              </Suspense>
            </ProjectDrawer>
          }
        />
      </Routes>

      <div className="container-fluid mb-5 ">
        <ProtocolHeader
          protocolData={protocolData}
          rightComponent={
            <div className="col-6 d-flex justify-content-end align-items-center mt-5">
              <Link to="view" className="me-2 ">
                <Button type="default" className="pb-3">
                  Edit protocol
                </Button>
              </Link>
              {protocolData.phases?.length ? (
                <Link to="activate" className="text-decoration-none">
                  <Button type="primary">Activate protocol</Button>
                </Link>
              ) : null}
            </div>
          }
        ></ProtocolHeader>

        <div className="row" style={{}}>
          <div className="col-4 d-flex justify-content-between my-6 ">
            <div className="d-flex align-items-center">
              <h4>Phases </h4>
              <p className="ms-2 mb-0 pb-1 opacity-75">({protocolData ? protocolData.phases?.length : 0})</p>
            </div>
          </div>
          <div className="col-8 d-flex justify-content-end my-6 ">
            <Link to="create-phase">
              <Button type="default">Add phase</Button>
            </Link>
          </div>
        </div>

        {protocolData.phases?.map((phase, phaseIndex) => {
          return (
            <ProtocolOverviewListItem
              key={phaseIndex}
              phase={phase}
              protocol={protocolData}
              language={defaultLanguage}
            />
          )
        })}
      </div>
    </>
  )
}
