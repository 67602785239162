export const newProtocolConfig = [
  {
    id: 'title',
    type: 'TEXT',
    placeholder: 'Add title',
    label: 'Protocol ID',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Title is required',
  },
] as const
