import { LanguageISOCode } from '../../../Types'
import React, { useCallback, useState } from 'react'
import { FormLabel } from '../../../components/form/FormLabel'
import { Select } from 'antd'
import { LANGUAGE_LABELS } from '../../../constants'

export const LanguageDropdown = ({
  supportedLanguages,
  defaultLanguage,
  onChange,
}: {
  supportedLanguages: any[]
  defaultLanguage: any
  onChange?: (languageISOCode: LanguageISOCode) => void
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageISOCode>()

  const handleLanguageSelect = useCallback(
    (languageCode: LanguageISOCode) => {
      if (supportedLanguages && languageCode) {
        setSelectedLanguage(languageCode)
        onChange && onChange(languageCode)
      }
    },
    [supportedLanguages, onChange]
  )

  return (
    <>
      <FormLabel label={'Select Language'} />
      <Select
        defaultValue={defaultLanguage.isoCode}
        value={selectedLanguage}
        style={{ width: '100%' }}
        onChange={(val: LanguageISOCode) => handleLanguageSelect(val)}
        options={supportedLanguages?.map((language: any) => ({
          value: language.isoCode,
          label: LANGUAGE_LABELS[language.isoCode] ? LANGUAGE_LABELS[language.isoCode] : language.isoCode,
        }))}
      />
    </>
  )
}
