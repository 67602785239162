/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/10/2023.
 */
import React from 'react'
import { genericDataTimeformat } from '../../util/datetime'

type Props = {
  protocolData: {
    title: string
    status: string
    activatedAt: string
    retiredAt: string
  }
  rightComponent?: React.ReactNode
}

export const ProtocolHeader = ({ protocolData, rightComponent }: Props) => {
  return (
    <div className="row">
      <div className="col-6 mb-2 mt-4">
        <p className="p-small mt-1">Protocol ID</p>
        <h4>{protocolData.title}</h4>
      </div>
      {rightComponent}
      {protocolData.activatedAt ? (
        <p className="p-mini">Activated at: {genericDataTimeformat(protocolData.activatedAt)}</p>
      ) : (
        <p className="p-mini">&nbsp;</p>
      )}

      <div className="col-12 mt-3 " style={{ background: '#d3d3d3', height: 1 }}></div>
    </div>
  )
}
