/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 29/03/2023.
 */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { post } from '../../service/API'
import { Alert, Button } from 'antd'
import { getFormType } from '../../components/form/formFactory'
import { FormLabel } from '../../components/form/FormLabel'
import { populateConfig } from '../../util/populateConfig'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { CRUD_VIEW_TYPE, HTTPMethod, VIEW_TYPE } from '../../Types'
import { useByEndpoint } from '../../hooks/useByEndpoint'
import { useSWRConfig } from 'swr'
import { openNotification } from '../../components/notifications/openNotification'
import { getPhaseConfig } from '../phase/phaseConfig'
import { BODY_PARTS } from '../../constants'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

export const ProtocolPhaseClone = ({ closeDrawer, onClose, viewType = CRUD_VIEW_TYPE.CREATE }: Props) => {
  const { projectId, phaseId, protocolId } = useParams() as { projectId: string; phaseId: string; protocolId: string }
  const { mutate: globalMutate } = useSWRConfig()

  const p = phaseId ? ENDPOINTS.PHASES.byPhaseId(projectId, phaseId) : ''
  const { data, mutate } = useByEndpoint(p)

  console.log('ProtocolPhaseClone > viewType = ', viewType)

  //
  // build config object - to populate form
  //
  const configObj = viewType === 'UPDATE' ? populateConfig(getPhaseConfig(), data, viewType) : getPhaseConfig()

  const formHook = useForm()

  const { handleSubmit } = formHook
  const [errorState, setErrorState] = useState<any>()

  const onSubmit = (formData, e) => {
    e.preventDefault()

    // const createData = { ...formData, projectId: projectId, protocolId: protocolId }
    // const updatedData = { ...data, ...formData, protocolId: protocolId }
    const cloneData = { phaseId, protocolId }

    const method = viewType === CRUD_VIEW_TYPE.UPDATE ? HTTPMethod.PUT : HTTPMethod.POST
    // const sendData = viewType === 'UPDATE' ? updatedData : cloneData
    const sendData = cloneData
    console.log('ProtocolPhaseClone > sendData:', sendData)

    return post(ENDPOINTS.PROTOCOLS.ACTIONS.CLONE_PHASE(projectId), sendData, method)
      .then((result) => {
        const msg = viewType === 'UPDATE' ? 'Phase updated' : 'Phase created'

        console.log('ProtocolPhaseClone > result = ', result)

        openNotification(msg, '', 'happy')

        mutate()
        globalMutate(ENDPOINTS.PROTOCOLS.GET_ONE(projectId, protocolId))

        if (onClose) {
          onClose()
        }

        if (closeDrawer) {
          closeDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  const navigateBack = () => {
    console.log('navigateBack', onClose)
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      {viewType === 'CREATE' || viewType === 'UPDATE' ? (
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="col-12">
            {configObj.map((item, index) => {
              const comp = getFormType(item, index, formHook)
              return comp
            })}
          </div>

          <div className="col-12 d-flex justify-content-end mt-3">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
              {viewType === 'CREATE' ? 'Create new phase' : 'Save changes'}
            </Button>
          </div>
        </form>
      ) : (
        <div className="">
          <div className="col-12 mb-4">
            <FormLabel label="Phase title"></FormLabel>
            <h6 className="fw-bold">{data ? data.title : 'NaN'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Phase duration"></FormLabel>
            <h6 className="fw-bold">{data ? data.duration + ' days' : 'NaN'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Phase type"></FormLabel>
            <h6 className="fw-bold">{data ? data.type : 'NaN'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Body parts"></FormLabel>
            {data.bodyParts
              ? data.bodyParts.map((bodyPart, index) => <h6 key={index}>{BODY_PARTS[bodyPart]}</h6>)
              : null}
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Short compliance trigger (days)"></FormLabel>
            <h6 className="fw-bold">{data.shortCompliance ? data.shortCompliance : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Overall compliance trigger (%)"></FormLabel>
            <h6 className="fw-bold">{data.overallCompliance ? data.overallCompliance + '%' : '-'}</h6>
          </div>

          <div className="col-12 d-flex justify-content-end">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
              Clone
            </Button>
          </div>

          <div className="col-12">
            <hr />
          </div>

          <div className="col-12 mt-3">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  )
}
