import { useParams } from 'react-router-dom'
import { HTTPMethod, PERSONNEL_STATUS_ENUM, PERSONNEL_TYPE_ENUM } from '../../Types'
import { post } from '../../service/API'
import { Alert, Button } from 'antd'
import React, { useRef, useState } from 'react'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { FormLabel } from '../../components/form/FormLabel'
import TextArea from 'antd/es/input/TextArea'
import { openNotification } from '../../components/notifications/openNotification'
import { mutate as mutateGlobal } from 'swr'
import { usePersonnelById } from '../../hooks/usePersonnelById'

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 27/03/2023.
 */
type DeleteManagerProps = {
  onClose?: null | ((bool: boolean) => void)
  type: PERSONNEL_TYPE_ENUM
}

export const RetireStaff = ({ onClose = null, type }: DeleteManagerProps) => {
  const { projectId, userId } = useParams() as { projectId: string; userId: string }
  const justification = useRef<string>()

  const [justificationError, setJustificationError] = useState<any>()

  const [errorState, setErrorState] = useState<any>()

  const { data, mutate } = usePersonnelById(type, projectId, userId)

  const status =
    data.status === PERSONNEL_STATUS_ENUM.ACTIVE ? PERSONNEL_STATUS_ENUM.ACTIVE : PERSONNEL_STATUS_ENUM.RETIRED

  //
  // To get access to the userId this components must be renderes inside the drawer
  //
  const retireClick = () => {
    if (!justification.current) {
      setJustificationError(true)
      return
    }
    const p =
      type === PERSONNEL_TYPE_ENUM.TRIAL_STAFF
        ? ENDPOINTS.TRIALSTAFF.retire(projectId)
        : ENDPOINTS.CLINICIANS.retire(projectId)

    const sendData = { _id: userId, justification: justification.current }
    post(p, sendData, HTTPMethod.PUT)
      .then((result) => {
        openNotification('Retire Staff', 'User retired successfully', 'happy')
        if (onClose) {
          onClose(false)
        }
        if (type === PERSONNEL_TYPE_ENUM.TRIAL_STAFF) {
          mutateGlobal(ENDPOINTS.TRIALSTAFF.byProjectId(projectId))
        } else {
          mutateGlobal(ENDPOINTS.CLINICIANS.byProjectId(projectId))
        }
        mutate()
        return result
      })
      .catch((error) => {
        console.log('Project Trial Staff > error = ', error)
        setErrorState(error)
      })
  }

  const activateClick = () => {
    if (!justification.current) {
      setJustificationError(true)
      return
    }
    const p =
      type === PERSONNEL_TYPE_ENUM.TRIAL_STAFF
        ? ENDPOINTS.TRIALSTAFF.reactivate(projectId)
        : ENDPOINTS.CLINICIANS.reactivate(projectId)

    const sendData = { _id: userId, justification: justification.current }

    console.log('activateClick > ', p)

    post(p, sendData, HTTPMethod.PUT)
      .then((result) => {
        openNotification('Reactivate Staff', 'User was successfully reactivated', 'happy')
        if (onClose) {
          onClose(false)
        }
        if (type === PERSONNEL_TYPE_ENUM.TRIAL_STAFF) {
          mutateGlobal(ENDPOINTS.TRIALSTAFF.byProjectId(projectId))
        } else {
          mutateGlobal(ENDPOINTS.CLINICIANS.byProjectId(projectId))
        }
        mutate()
        return result
      })
      .catch((error) => {
        console.log('Project Trial Staff > error = ', error)
        setErrorState(error)
      })
  }

  const onJustificationHandler = (val: string) => {
    justification.current = val
    setJustificationError(false)
  }

  return (
    <div className="container-fluid">
      {status === PERSONNEL_STATUS_ENUM.ACTIVE ? (
        <div className="row" style={{}}>
          <div className="col-12 mb-2">
            <h4>
              {data?.type === PERSONNEL_TYPE_ENUM.CLINICIAN
                ? "Retire clinician's access"
                : "Retire trial staff's access"}
            </h4>
          </div>
          <div className="col-12 mb-6">
            <FormLabel label="Reason for the rewoke" />
            <TextArea
              rows={3}
              placeholder=""
              onChange={(e) => onJustificationHandler(e.target.value)}
              defaultValue=""
              showCount
              maxLength={100}
            />
            {justificationError ? (
              <div className="p-small text-danger">Please provide a reason for retiring the user</div>
            ) : null}
          </div>
          <div className="col-12 d-flex justify-content-end">
            <Button type="primary" onClick={retireClick} danger>
              Revoke access
            </Button>
          </div>
        </div>
      ) : (
        <div className="row" style={{}}>
          <div className="col-12 mb-2">
            <h4>
              {data?.type === PERSONNEL_TYPE_ENUM.CLINICIAN
                ? "Reactivate clinician's access"
                : "Reactivate trial staff's access"}
            </h4>
          </div>

          <div className="col-12 mb-6">
            <FormLabel label="Reason for the reactivation" />
            <TextArea
              rows={3}
              placeholder=""
              onChange={(e) => onJustificationHandler(e.target.value)}
              defaultValue=""
              showCount
              maxLength={100}
            />
            {justificationError ? (
              <div className="p-small text-danger">Please provide a reason for reactivating the user</div>
            ) : null}
          </div>
          <div className="col-12 d-flex justify-content-end">
            <Button type="primary" onClick={activateClick}>
              Reactivate access
            </Button>
          </div>
        </div>
      )}

      {errorState && errorState.message ? (
        <div className="mt-5">
          <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
