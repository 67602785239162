import { Tabs } from 'antd'
import React, { Suspense } from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { Clinicians } from '../clinician/Clinicians'
import { CLINICIAN_PAGE, TRIAL_STAFF_PAGE } from '../../pages'
import { TrialStaff } from './TrialStaff'
import { ExperimentOutlined, TeamOutlined } from '@ant-design/icons'

export const StaffWrapper = () => {
  return (
    <div className="mt-3">
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: (
              <>
                <Link to={TRIAL_STAFF_PAGE.to} style={{ padding: '10px', textDecoration: 'none', color: 'inherit' }}>
                  <TeamOutlined />
                  <span className="">Trial staff</span>
                </Link>
              </>
            ),
            key: '1',
          },
          {
            label: (
              <Link to={CLINICIAN_PAGE.to} style={{ padding: '10px', textDecoration: 'none', color: 'inherit' }}>
                <ExperimentOutlined />
                <span className="">Clinicians</span>
              </Link>
            ),
            key: '2',
          },
        ]}
      />
      <Routes>
        <Route
          path={TRIAL_STAFF_PAGE.path}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <TrialStaff></TrialStaff>
            </Suspense>
          }
        />
        <Route
          path={CLINICIAN_PAGE.path}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <Clinicians></Clinicians>
            </Suspense>
          }
        />
      </Routes>
    </div>
  )
}
