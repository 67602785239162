import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { genericDataTimeformat } from '../../util/datetime'

export const AuditListItem = ({ item, to = './' }: { item: any; to?: string }) => {
  return (
    <div
      className="row py-2 bg-white mt-2"
      style={{ border: '1px solid ', borderColor: '#CFD3D7 ', boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.08)' }}
    >
      <div className="col-12">
        <div className="row">
          <div className="col-6">
            <p className="p-mini">{genericDataTimeformat(item.createdAt)}</p>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <p className="p-mini">{item.targetModel}</p>
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-10">
            <div className="row">
              <div className="col-12 col-xxl-5">
                <p className="mb-0 p-mini alpha-80 fw-bold">Event</p>
                <p className="me-2 p-small">{item.auditType}</p>
              </div>
              <div className="col-12 col-xxl-5 mt-2 mt-xxl-0">
                <p className="mb-0 p-mini alpha-80 fw-bold">Action on</p>
                <p className="me-2 p-small">{item.targetUniqueId ? item.targetUniqueId : item._id}</p>
              </div>
            </div>
          </div>
          <div className="col-2 justify-content-end align-items-center d-flex pt-3 pb-2">
            <Link className="me-2" to={to + item._id}>
              <Button>View</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
