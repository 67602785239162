/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09/06/2023.
 */
import React, { useState } from 'react'
import { Button } from 'antd'
import { QuestionnaireListItem } from '../questionnaire/QuestionnaireListItem'
import { PhaseVisitList } from './PhaseVisitList'
import { PhaseContentLabel } from './PhaseContentLabel'
import { LanguageISOCode } from '../../Types'
import { Link } from 'react-router-dom'

type Props = {
  phase: any
  language: LanguageISOCode
}

export const PhaseListItem = ({ phase, language }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const phaseQuestionnaires = phase.questionnaires || []
  const phaseVisits = phase?.visits || []

  const toogle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="row mb-4 pb-4  " style={{ boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)', background: '#FAFAFA' }}>
      <div
        className="col-12  py-2  d-flex align-items-center"
        style={{
          borderTop: '6px solid #567163',
          boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)',
          background: '#6d897b',
        }}
      >
        <div className="ps-2 my-3 text-white" style={{ width: '25%' }}>
          <p className="p-mini opacity-50 ">{phase._id}</p>
          <h5 className="mb-1 fw-bold ">{phase.title}</h5>
        </div>

        <div className="ps-2 my-3 text-white " style={{ width: '15%' }}>
          <p className="p-mini opacity-50 ">duration</p>
          <p className="mb-1 fw-bold ">{phase.duration} days</p>
        </div>
        <div className="ps-1 my-3 text-white" style={{ width: '20%' }}>
          <p className="p-mini opacity-50 text-white">type</p>
          <p className="mb-1 fw-bold text-white">{phase.type}</p>
        </div>

        <div className="ps-2 my-3 text-white" style={{ width: '15%' }}>
          <p className="p-mini opacity-50 text-white">short compliance</p>
          <p className="mb-1 fw-bold text-white">{phase.shortCompliance ? phase.shortCompliance + ' days' : '-'} </p>
        </div>
        <div className="ps-2 my-3 text-white" style={{ width: '15%' }}>
          <p className="p-mini opacity-50 text-white">overall compliance</p>
          <p className="mb-1 fw-bold text-white">{phase.overallCompliance ? phase.overallCompliance + '%' : '-'}</p>
        </div>

        <div className=" my-3 text-white" style={{ width: '20%' }}>
          <p className="p-mini opacity-50 text-white">Status</p>
          <p className="mb-1 fw-bold text-white">{phase.status}</p>
        </div>
        <div className="my-3 d-flex justify-content-end" style={{ width: '20%' }}>
          <Link to={`phase/${phase._id}`}>
            <Button type="default">View</Button>
          </Link>
        </div>
      </div>

      {isOpen ? (
        <>
          <PhaseContentLabel label="QUESTIONNAIRES"></PhaseContentLabel>

          <div className="col-12 px-4" style={{}}>
            <div className="row" style={{}}>
              {phaseQuestionnaires.length !== 0 ? (
                phaseQuestionnaires.map((ques, qIndex) => {
                  return (
                    <QuestionnaireListItem
                      key={qIndex}
                      item={ques}
                      to={`phase/${phase._id}/questionnaire/${ques._id}`}
                      language={language}
                    ></QuestionnaireListItem>
                  )
                })
              ) : (
                <NoQuestionnaire to={''}></NoQuestionnaire>
              )}
            </div>
          </div>

          <PhaseContentLabel label="VISITS"></PhaseContentLabel>

          {phaseVisits.length !== 0 ? <PhaseVisitList visitArr={phaseVisits} /> : <NoVisit />}

          <div className="col-12 ">
            <div
              className="py-5 d-flex flex-column justify-content-center align-items-center "
              style={{ background: '#c4c4c4' }}
            >
              <h5 className="text-white mt-2 mb-3 fw-bold ">Eligibility check</h5>
              <Button type="default" style={{ minWidth: 100 }}>
                View
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="col-12 d-flex justify-content-center">
          <Button onClick={toogle} className="mt-4" type="primary">
            Show phase
          </Button>
        </div>
      )}
    </div>
  )
}

const NoVisit = () => {
  return (
    <div className="col-12 px-4 mb-3">
      <div className="row pb-2 border rounded">
        <div className="col-12">
          <div className="w-100 pt-3 pb-2 px-2 d-flex justify-content-center align-items-center">
            <p className="me-2 opacity-75">No visits in this fase</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const NoQuestionnaire = ({ to }) => {
  return (
    <div className="col-12 px-4 mb-3">
      <div className="row pb-2 border rounded">
        <div className="col-12">
          <div className="w-100 pt-3 pb-2 px-2 d-flex justify-content-center align-items-center">
            <p className="me-2 opacity-75">No questionnaires in this phase</p>
            {/*<Link to={to}>
              <Button>Add a visit</Button>
            </Link>*/}
          </div>
        </div>
      </div>
    </div>
  )
}
