/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/03/2023.
 */
import React, { useState } from 'react'
import { Select } from 'antd'
import { useProjects } from '../../hooks/useProjects'
import { useParams } from 'react-router-dom'

export const HeaderProjectSelect = () => {
  const { data } = useProjects()
  const [state] = useState()

  const params = useParams()

  const handleChange = (e, k) => {
    //const to = TRIAL_PAGE.goto(e.key)
    const href = window.location.origin
    const newLocation = href + '/project/' + e.toString() + '/protocol'
    // const newLocation = href + '/project/' + e.toString() + '/overview'

    window.location.replace(newLocation)

    //navigate('./' + e.toString())
    //navigate('project/' + e.toString() + '/')
    //navigate('/project/' + e.toString() + '/')
    //navigate('/project/' + e.toString() + '/')
    // navigate('../project/' + e.toString())
  }

  //
  const items = data
    ? data.map((project) => {
        return { value: project._id, label: project.title }
      })
    : []

  return (
    <div className="w-75" style={{ minWidth: 150, maxWidth: 200 }}>
      {params.projectId ? (
        <Select
          popupMatchSelectWidth={false}
          className="w-auto"
          defaultValue={params.projectId}
          value={state}
          bordered={false}
          onChange={handleChange}
          placeholder={'--'}
          options={items}
        ></Select>
      ) : null}
    </div>
  )
}
