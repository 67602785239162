/**
 * Created by @author Søren Tramm, 2023.10.27
 */
import React, { Suspense } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { ProtocolListItem } from './ProtocolListItem'
import { useArchivedProtocols } from '../../hooks/useArchivedProtocols'
import { ProjectDrawer } from '../epro-project/ProjectDrawer'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { ProtocolCRUD } from './ProtocolCRUD'
import { CRUD_VIEW_TYPE } from '../../Types'
import { AuditViewListTargetProtocol } from '../audit/AuditViewListTargetProtocol'

export const ProtocolArchivePage = () => {
  const { projectId } = useParams() as { projectId: string }
  const { data, mutate } = useArchivedProtocols(projectId)

  const closeDrawer = (value) => {
    if (!value) {
      mutate()
    }
  }

  return (
    <>
      <Routes>
        <Route
          path="/:protocolId/view/*"
          element={
            <ProjectDrawer projectId={projectId} width="30%" to="../" title="View protocol">
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <ProtocolCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.VIEW} />
              </Suspense>
            </ProjectDrawer>
          }
        />
        <Route
          path="/:protocolId/audit-trail/*"
          element={
            <ProjectDrawer projectId={projectId} width="40%" to="../" title="View audit trail">
              <AuditViewListTargetProtocol closeDrawer={closeDrawer} />
            </ProjectDrawer>
          }
        />
      </Routes>
      <div className="row" style={{}}>
        <div className="col-4 d-flex justify-content-start mt-4">
          <p className="fw-bold mb-0">Retired Protocols</p>
        </div>

        <div className="col-12 mt-3">
          {data && data.length > 0 ? (
            data.map((item, index) => {
              return <ProtocolListItem key={index} item={item} to={'./' + item._id + '/view'} />
            })
          ) : (
            <h4>No archived protocols</h4>
          )}
        </div>
      </div>
    </>
  )
}
