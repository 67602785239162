import { useParams } from 'react-router-dom'
import { AuthEventResponseType, VIEW_TYPE } from '../../Types'
import React from 'react'
import { Button, Table } from 'antd'
import { useAdminLogins } from '../../hooks/useAdminLogins'
import { genericDataTimeformat } from '../../util/datetime'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

export const LoginViewListAdmin = ({ onClose, closeDrawer, viewType }: Props) => {
  const { projectId, adminId } = useParams() as { projectId: string; adminId: string }

  const { data } = useAdminLogins(projectId, adminId)
  const { authEvents, email } = data

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  const dataSource = authEvents
    ? authEvents.map((event: AuthEventResponseType, index: number) => {
        return {
          key: index.toString(),
          event: event.EventType,
          response: event.EventResponse,
          date: genericDataTimeformat(event.CreationDate.toString()),
        }
      })
    : []

  const columns = [
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
    },
    {
      title: 'Response',
      dataIndex: 'response',
      key: 'response',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
  ]

  return (
    <>
      <div className="col-12 mb-3">
        <p className="mb-0 p-mini alpha-80 fw-bold">Authentication audit trail for</p>
        <p className="me-2 p-small">{email}</p>
      </div>
      <div className="col-12">
        <Table dataSource={dataSource} columns={columns} size="middle" />
      </div>

      <div className="col-12 d-flex justify-content-end mt-2">
        <Button type="primary" onClick={navigateBack}>
          Close
        </Button>
      </div>
    </>
  )
}
